import * as actions from './actions'
import * as mutations from './mutations'
import * as getters from './getters'


export default {
  scope: true,
  state: {
    forCitizensPage: null,
    forCitizenPage: null,
    forEmployeesPage: null
  },
  actions,
  mutations,
  getters
}