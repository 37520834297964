import {mapActions, mapGetters} from 'vuex'
import IndexMixin from '../../mixins/IndexMixin'
import checkIpAddress from '../../mixins/checkIpAddress'
import filterArea from '../../mixins/FilterArea'

import UIkit from 'uikit'

export default {
  name: 'LogPage',
  mixins: [IndexMixin, checkIpAddress, filterArea],
  data() {
    return {
      filters: {
        search: '',
        page: 1,
        perPage: 10,
        filterArea: {
          typeObject: {name: 'Все типы объектов', id: 'all'},
          typeMessage: {name: 'Все типы сообщений', id: 'all'},
          date_start: '',
          date_end: ''
        },
      }
    }
  },
  computed: {
    typeLogs() {
      return [
        {name: 'Все типы сообщений', id: 'all'},
        {name: 'Информация', id: 'info'},
        {name: 'Ошибки', id: 'error'}
      ]
    },
    logs() {
      return this.$store.state.admin.forLogsPage?.logs
    },
    total() {
      return this.$store.state.admin.forLogsPage?.total
    }
  },
  methods: {
    async getDataForPage(filter) {
      this.changeLoaders(filter, true)
      try {
        await this.$store.dispatch('requestToLogs', {
          params: {
            query: this.filters.search,
            page: this.filters.page,
            per_page: this.filters.perPage,
            date_start: this.filters.filterArea.date_start,
            date_end: this.filters.filterArea.date_end,
            resource_type: this.filters.filterArea.typeObject.id,
            type_log: this.filters.filterArea.typeMessage.id
          }
        })
      } catch(e) {
        console.log(e);
      } finally {
        if (filter === 'page') this.$refs.scrollTarget.scrollIntoView({behavior: "smooth"})
        this.changeLoaders(filter)
      }
    },
  },
  created() {
    this.setLocation()
    this.getDataForPage()
  }
}
