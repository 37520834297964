<template>
  <a
    class="card-wrapper"
    :href="'/schedule/' + reception.id"
  >
    <div class="card-date" v-if="reception.state !== 'over'">
      <span>
        {{reception.date_start | date('detailed-calendar')}}
      </span>
    </div>

    <div class="card-date" v-else>
      <span>
        Прошёл {{reception.date_end | date('day')}}
      </span>
    </div>

    <h4 class="card-title">
      {{reception.title | trimString(150)}}
    </h4>

    <div class="card-main">

      <template v-if="reception.claims.length > 0">
        <ul class="card-information-claims-admin">
          <li v-for="claim in receptionClaims" :key="claim.id">
            <span uk-icon="guber-check-mark"></span>
            <p class="title">{{ claim.title }}</p>
          </li>
        </ul>
      </template>

      <button v-if="reception.claims.length > 3" class="button-change-count-claim" :class="{'button-change-count-claim-open': claimsShow}" @click.prevent="claimsShow = !claimsShow">
        <span uk-icon="icon: arrow-menu-button"></span>
        <span v-if="claimsShow">Скрыть</span>
        <span v-else>Показать все</span>
      </button>

      <div class="card-links" v-if="$store.state.user.currentUser.employee">
        <a :href="'/receptions/' + reception.id + '/start'" class="btn primary" v-if="reception.state == 'scheduled' && reception.moderator.id == $store.state.user.currentUser.id">Начать приём</a>
        <a :href="'/receptions/' + reception.id + '/stop'" class="btn outline-transparent-dark" v-if="reception.state == 'in_process' && reception.moderator.id == $store.state.user.currentUser.id">Закончить приём</a>
        <a :href="'/schedule/' + reception.id + '/connect'" class="btn primary" v-if="reception.state == 'in_process' && reception.can_connect">Присоединиться к онлайн-приёму</a>
        <a :href="'/schedule/' + reception.id + '/test'" class="btn outline-transparent-dark" v-if="reception.state == 'scheduled'">Пройти тестовый приём</a>
      </div>

      <template v-else>
        <div class="card-links" v-if="reception.state != 'over'">
          <a :href="'/schedule/' + reception.id + '/connect'" class="btn primary" v-if="reception.state !== 'scheduled' && reception.can_connect">Присоединиться к онлайн-приёму</a>
          <a :href="'/schedule/' + reception.id + '/test'" class="btn primary" v-else-if="reception.state != 'over' && reception.state != 'in_process'">Пройти тестовый приём</a>
        </div>
      </template>

    </div>

  </a>
</template>

<script>
export default {
  name: 'ReceptionCard',
  props: {
    reception: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      claimsShow: false
    }
  },
  computed: {
    receptionClaims() {
      if (this.reception.claims.length > 3) {
        return this.claimsShow ? this.reception.claims : this.reception.claims.slice(0, 3)
      } else if (this.reception.claims.length <= 3) {
        return this.reception.claims
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/variables';

  .card-wrapper {
    @extend %fz-14;

    display: block;
    width: 100%;
    padding: 24px 24px 28px 24px;

    background: $color-dark-blue-transparent;
    box-shadow: $card-box-shadow;
    border-radius: 6px;

    &:not(:first-child) {
      margin-top: 20px;
    }

    .card-title {
      color: $color-main-text;
    }

    &:hover {
      .card-title {
        color: $color-main-text-hover;
      }
    }

    .card-date {
      display: inline-block;
      @extend %fz-12;
      font-weight: 500;
      color: $color-text-white;

      padding: 4px 8px 2px;
      border-radius: 4px;
      background: $color-more-dark-blue-60;
    }

    h4 {
      transition: $transition-buttons;
      margin: 20px 0 0;
    }

    .card-main {
      margin-top: 16px;
    }

    .card-information {
      margin: 8px 0 0;
      color: $color-dark-blue;
      @extend %fz-14;
      &-claims {
        margin-top: 2px;
        max-width: 800px;
        p {
          width: 100%;
          margin: 0;
        }

        &-admin {
          position: relative;
          color: $color-main-text;
          margin: 4px 0 0;
          list-style: none;
          padding: 0;
          li {
            margin-top: 8px;
            display: flex;
            align-items: center;
            span {
              color: $color-grey-description;
            }
            p {
              max-width: 851px;
              overflow: hidden;
              text-overflow: ellipsis;
              margin: 0 0 0 6px;
              @extend %fz-14;
            }
          }
        }
      }
    }
    .card-links {
      margin-top: 24px;
      a {
        display: inline-block;
        margin-right: 16px;
      }
    }
    .button-change-count-claim {
      @extend %fz-12;
      font-weight: 700;

      margin-top: 12px;
      padding: 0;
      display: flex;
      align-items: center;
      background: none;
      cursor: pointer;
      color: $color-more-dark-blue-80;

      transition: .1s ease;

      &:hover {
        color: $color-main-text-hover;
      }

      span:first-child {
        width: 16px;
        height: 16px;
        margin-right: 4px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
      }

      &-open {
        color: $color-main-text;
        span:first-child {
          transform: rotate(180deg);
        }
      }
    }
  }
</style>
