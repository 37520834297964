import changeLocationFilter from '../mixins/changeLocationFilter'

export default {
  mixins: [changeLocationFilter],
  data() {
    return {
      searchLoading: false,
      loading: false,
    }
  },
  methods: {
    changeFilters(filterName, value) {
      if (filterName !== 'page' && this.filters.page) this.filters.page = 1
      if (filterName === 'filterArea') {
        this.filters[filterName] = Object.assign({}, value)
      } else {
        this.filters[filterName] = value
      }
      this.getDataForPage(filterName)
      this.changeLocation()
    },
    changeLoaders(filter, load = false) {
      if (filter === 'search') {
        this.searchLoading = load 
      } else {
        this.loading = load
      }
    }
  }
}
