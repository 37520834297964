<template>
  <div class="progress-background grey">
    <div class="loading-bar grey" :style="{width: progress + '%'}"></div>
  </div>
</template>

<script>
export default {
  name: 'CustomProgress',
  props: {
    progress: {
      type: Number,
      default: 0
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/variables';

  .progress-background {
    width: 100%;
    border-radius: 2px;
    z-index: 1;
    height: 4px;
    overflow: hidden;

    .loading-bar {
      border-radius: 2px;
      z-index: 2;
      height: 100%;
    }
  } 

  .grey {
    background: $color-more-dark-blue-20;
    .loading-bar {
      background: $color-primary;
    }
  }
</style>
