<template>
  <div :id="modalId" uk-modal>
    <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical modal-cancel-claim">
      <div class="uk-modal-close-default modal-close-default" type="button" uk-icon="guber-close"></div>

      <h3>
        <slot name="title"></slot>
      </h3>
      <search-field :update-timeout="false" :placeholder="searchPlaceholder" @update="updateSearchField"/>
      <div class="items-select">
        <div 
          class="item-select-wrapper" 
          v-for="item in filteredItems" 
          :key="item.id"
        >
          <label :for="'check' + item.id" class="checkbox-text">
            {{item[iterableField]}}
          </label>
          <input 
            class="uk-checkbox checkbox" 
            type="checkbox" 
            :id="'check' + item.id"
            :value="item.id"
            v-model="itemsIds"
          >
        </div>

        <div class="items-is-null" v-if="filteredItems.length === 0">
          <p class="title">Ничего не найдено</p>
          <p class="subtitle">Попробуйте другие параметры поиска</p>
        </div>
      </div>

      <button 
        class="btn-added-item btn primary button-submit-main" 
        :class="{'disabled-btn': disabledButton, 'button-submit-main-loading': loaded}"
        @click="addItem"
      >
        <div class="loader">
          <loaders icon-view="button" v-if="loaded" />
        </div>
        <span class="text">
          <slot name="button"></slot>
        </span>
      </button>
    </div>
  </div>
</template>

<script>
import Loaders from '../Loaders'
import SearchField from '../SearchField'
import xor from 'lodash/xor'


export default {
  name: 'CustomModalSelect',
  components: {
    SearchField,
    Loaders
  },
  props: {
    modalId: {
      type: String,
      default: "modal-select-item"
    },
    items: {
      type: Array,
      required: true
    },
    iterableField: {
      type: String,
      required: true
    },
    searchPlaceholder: {
      type: String,
      required: true
    },
    loaded: {
      type: Boolean,
      default: false
    },
    selectedItems: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      searchField: '',
      itemsIds: []
    }
  },
  computed: {
    filteredItems() {
      return this.searchField === '' ? this.items : this.items.filter(item => item[this.iterableField].toLowerCase().includes(this.searchField.toLowerCase()))
    },
    disabledButton() {
      return xor(this.itemsIds, this.selectedItems).length == 0
    }
  },
  methods: {
    updateSearchField(str) {
      this.searchField = str
    },
    addItem() {
      this.$emit('input', {ids: this.itemsIds})
      this.itemsIds = []
    }
  },
  watch: {
    selectedItems() {
      this.itemsIds = this.selectedItems
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/styles/variables';

  .uk-modal-dialog {
    width: 794px !important;
    padding: 40px 16px 40px 40px !important;

    h3 {
      margin-bottom: 32px;
    }
  }

  .items-select {
    margin-top: 28px;
    height: 436px;
    overflow-y: auto;
    padding-right: 19px;
    position: relative;

    .items-is-null {
      position: absolute;
      top: 40%;
      left: 30%;
      text-align: center;
      p {
        margin: 0;
      }
      .title {
        @extend %fz-16;
      }
      .subtitle {
        @extend %fz-14;
        margin-top: 8px;
        color: #738095;
      }
    }

    &::-webkit-scrollbar {
      width: 5px;
      background: $color-background-grey;
      border-radius: 4px;
    }
    &::-webkit-scrollbar-button {
      display: none;
    }
    &::-webkit-scrollbar-thumb {
      background: $color-more-dark-blue-80;
      border-radius: 4px;
    }
  }

  // checkbox
  .item-select-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    &:first-child {
      margin-top: 5px;
    }
    &:not(:first-child) {
      margin-top: 24px;
    }
    .checkbox {
      min-width: 20px;
      min-height: 20px;
      background: $color-body;
      border: 1px solid $color-more-dark-blue;
      box-sizing: border-box;
      border-radius: 4px;

      &:checked {
        background-image: url('../../../assets/images/checkbox-checked.png');
        background-repeat: no-repeat;
        background-position: center;
        background-color: $color-main-text;
        border-color: $color-main-text;
      }
      &-text {
        @extend %fz-14;
        max-width: 520px;
        cursor: pointer;
      }
    }
  }
</style>
