import {mapGetters, mapActions} from 'vuex';
import UIkit from 'uikit'
import {isNull} from "lodash/lang";

export default {
  data() {
    return {
      loadСonfirm: false,
      answer: {
        template: null,
        text: '',
        originator: '',
        loaded: false
      },
      officialComment: {
        message: '',
        kind: 'official',
        loaded: false,
        update: 0,
      },
      publicComment: {
        message: '',
        kind: 'public',
        loaded: false,
        update: 0
      },
      loadedPage: false,
      viewFakeDescription: false,
      commentUpdate: 0,
      htmlEditorUpdate: 0,
      lengthAnswerOfOriginator: 300,
      claimAnswerIsEdit: false
    }
  },
  computed: {
    documentsResponse() {
      const documents = this.claim.attachments.filter(item => item.kind === "response")
      return documents.length > 0 ? documents : []
    },
    mediaFiles() {
      const media = [...this.claim.images, ...this.claim.videos]
      return media.length > 0 ? media : []
    },
    claim() {
      return this.$store.getters.getClaim
    },
    departments() {
      const departments = this.$store.getters.getDepartments
      return departments ? departments : []
    },
    templates() {
      const templates = window.gon.templates
      return templates && templates.length > 0 ? templates : []
    }
  },
  methods: {
    ...mapActions(['sendComments', 'giveAnswerToClaim', 'editAnswerToClaim', 'directCalimToWork', 'directClaimToRecommend', 'addDepartmentForClaim']),
    async confirmParticipation() {
      this.loadСonfirm = true
      try {
        await this.$store.dispatch('claimConfirmParticipation', {id: this.claim.id, forClaimsPage: false})
      } catch(e) {
        console.log(e);
      } finally {
        this.loadСonfirm = false
      }
    },
    async sendComment(comment) {
      comment.loaded = true
      try {
        await this.sendComments({claimId: this.claim.id, text: comment.message, kind: comment.kind})
      } catch(e) {
        console.log(e);
      } finally {
        comment.loaded = false
        comment.message = ''
        comment.update += 1
      }
    },
    handleSubmitAnswer() {
      if (this.claim?.response_text) {
        this.editAnswer();
      } else {
        this.giveAnswer();
      }
    },
    async giveAnswer() {
      this.loadedPage = true
      try {
        await this.giveAnswerToClaim({claimId: this.claim.id, params: {claim: {response_text: this.answer.text,
              template_id: this.answer.template.id,
              originator_of_answer: this.answer.originator}}})
        Turbolinks.reload()
      } catch (e) {
        console.log(e);
      } finally {
        this.loadedPage = false
      }
    },
    async editAnswer() {
      this.loadedPage = true
      try {
        await this.editAnswerToClaim({claimId: this.claim.id, params: {claim: {response_text: this.answer.text,
              originator_of_answer: this.answer.originator}}})
        Turbolinks.reload()
      } catch (e) {
        console.log(e);
      } finally {
        this.loadedPage = false
      }
    },
    async departmentForClaim(data) {
      this.loadСonfirm = true
      try {
        await this.addDepartmentForClaim({claimId: this.claim.id, departmentIds: data.ids})
      } catch(e) {
        console.log(e);
      } finally {
        this.loadСonfirm = false
        UIkit.modal('#modal-select-item').hide()
      }
    },
    toggleEditAnswer(){
      this.claimAnswerIsEdit = !this.claimAnswerIsEdit
      this.answer.text = this.claim.response_text || ''
      this.answer.originator = this.claim.originator_of_answer || ''
    }
  },
  watch: {
    'answer.template': function(value) {
      if (value) {
        let response = value.response
        let username = this.claim.user.fullname
        response = response.replace('--FIO--', username)
        username = username.split(' ')
        username.shift()
        response = response.replace('--IO--', username.join(' '))
        this.answer.text = response
      } else {
        this.answer.text = ''
        this.htmlEditorUpdate += 1
      }
    }
  },
  created() {
    this.$store.dispatch('claimVisit', window.gon.claim)
    if (window.gon.departments) {
      this.$store.dispatch('claimVisitDepartments', window.gon.departments)
    }
  },
  mounted() {
    // Пока это костыль, потом придется придумать что-то лучше
    const hideElement = this.$refs.workWithClaim
    if (hideElement && hideElement.childElementCount <= 1) hideElement.style.display = 'none'
    this.claimAnswerIsEdit = isNull(this.claim.response_text)
    this.answer.text = this.claim.response_text || ''
    this.answer.originator = this.claim.originator_of_answer || ''
  }
}
