<template>
  <div 
    :class="'modal-form-' + classForm + '-for-admin' + ' ' + 'modal-form'" 
    :id="'modal-form-' + classForm + '-for-admin'" 
    uk-modal
  >
    <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical modal-cancel-claim">
      <div class="uk-modal-close-default modal-close-default" type="button" uk-icon="guber-close"></div>
      <h4 class="modal-title modal-title-blank-profile">
        <slot name="title"/>
      </h4>

      <div class="modal-input">
        <slot name="subtitle"/>
        <guber-input-text
          input-type="text" 
          :placeholder="placeholderInput"
          v-model="name"
        />
      </div>

      <div class="uk-flex">
        <div class="modal-buttons-box">
          <button 
            class="btn btn-middle primary" 
            :class="{'disabled-btn': loading || name === '' || (objectEdit && name === objectEdit.name)}" 
            @click="$emit('submit', { id: objectEdit ? objectEdit.id : null, name: name })"
          >
            {{objectEdit ? 'Сохранить' : 'Добавить'}}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GuberInputText from '../../form/GuberInputText.vue'
import UIkit from "uikit";


export default {
  name: 'ModalFormForAdmin',
  components: {
    GuberInputText
  },
  props: {
    objectEdit: {
      type: Object,
      default: () => null
    },
    loading: {
      type: Boolean
    },
    classForm: {
      type: String,
      required: true
    },
    placeholderInput: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      name: ''
    }
  },
  watch: {
   objectEdit() {
     this.name = this.objectEdit.name
   } 
  },
  mounted() {
    UIkit.util.on(`#modal-form-${this.classForm}-for-admin`, 'hidden', () => {
      this.name = ''
    })
  }
}
</script>

<style lang="scss" scoped>

.modal-form {
  .uk-modal-dialog {
    width: 568px !important;
    padding: 40px !important;
  }
  .modal-title {
    font-size: 30px;
    line-height: 38px;
  }
  .modal-input {
    margin-top: 32px;

    p {
      margin: 0 0 12px 0;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.5px;
      font-weight: 700;
    }
  }
  .uk-flex {
    .modal-buttons-box {
      margin-top: 24px;
      button {
        margin: 0;
      }
    }
  }
}
</style>
