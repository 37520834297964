import {mapActions, mapGetters} from 'vuex'
import qs from 'qs'


export default {
  name: 'MessagesPage',
  data() {
    return {
      page: 1,
      loaded: false
    }
  },
  computed: {
    ...mapGetters(['getMessages']),
    messages() {
      return this.getMessages?.messages
    },
    totalMessages() {
      return this.getMessages?.total
    }
  },
  methods: {
    ...mapActions(['requestToMessagesPage']),
    async receiveMessages(page = false) {
      this.loaded = true
      try {
        await this.requestToMessagesPage(this.page)
        if (page) this.$refs.scrollTarget.scrollIntoView({behavior: "smooth"})
      } catch(e) {
        console.log(e);
      } finally {
        this.loaded = false
      }
    },
    changePages(page) {
      this.page = page
      this.receiveMessages(true)

      const queryString = qs.stringify({page})
      if (window.location.href.split('?')[1] !== queryString ) {
        const newUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}?${queryString}`
        window.history.replaceState({ turbolinks: true, url: newUrl }, '', newUrl)
      }
    }
  },
  mounted() {
    if (window.location.search) {
      const page = qs.parse(window.location.href.split('?')[1]).page
      this.page = page
    }
    this.receiveMessages()
  }
}
