import qs from 'qs'

export default {
  methods: {
    changeLocation() {
      if (window.location.href.split('?')[1] !== qs.stringify({filter: this.filters})) {
        let queryString = qs.stringify({filter: this.filters})
        const newUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}?${queryString}`
        window.history.replaceState({ turbolinks: true, url: newUrl }, '', newUrl)
      }
    },
    setLocation() {
      if (window.location.search) {
        const filters = qs.parse(window.location.search.split('?')[1]).filter
        for(const key in this.filters) {
          if(filters[key]) this.filters[key] = filters[key]
        }
      }
    }
  }
}
