import I18n from 'i18n-js/index.js.erb'

export default {
  pluralize (number, declensions) {
    const cases = [2, 0, 1, 1, 1, 2];
    number = Math.abs(number)
    number = Math.round(number)
    return declensions[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]];
  },
  t (path) {
    return I18n.t(path)
  }
}
