export default {
  scope: true,
  state: {
    text: null
  },
  actions: {
    createNewNotification({commit}, params) {
      commit('SET_NEW_NOTIFICATION', params)
    }
  },
  mutations: {
    SET_NEW_NOTIFICATION(state, notification){
      state.text = notification
    }
  },
  getters: {
    getNotification(state){
      return state.text
    }
  }
}