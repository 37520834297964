import axios from 'axios'

const requestToLogs = async ({commit, getters}, params) => {
  const {data} = await axios.get(`${getters.getRoutes.admin_action_logs_path()}.json`, params)
  commit('setLogs', data)
}

const requestToBusinessDates = async ({commit, getters}, params) => {
  const {data} = await axios.get(`${getters.getRoutes.admin_business_dates_path()}.json`)
  commit('setBusinessDates', data)
}

const requestToClaimsCategories = async ({commit, getters}, params) => {
  const {data} = await axios.get(`${getters.getRoutes.admin_claim_categories_path()}.json`, params)
  commit('setClaimsCategories', data)
}

const requestToResponseTemplates = async ({commit, getters}, params) => {
  const {data} = await axios.get(`${getters.getRoutes.admin_response_templates_path()}.json`, params)
  commit('setResponseTemplates', data)
}

const requestToMessageTemplates = async ({commit, getters}, params) => {
  const {data} = await axios.get(`${getters.getRoutes.admin_message_templates_path()}.json`, params)
  commit('setMessageTemplates', data)
}

const writeBusinessDates = async ({commit, getters}, params) => {
  const {data} = await axios.post(`${getters.getRoutes.admin_business_dates_path()}.json`, {business_date: params})
  if (data.success) {
    commit('setBusinessDate', data.business_date)
  }
}
const deleteBusinessDates = async ({commit, getters}, id) => {
  const {data} = await axios.delete(`${getters.getRoutes.admin_business_date_path(id)}.json`)
  if (data.success) {
    commit('clearBusinessDate', id)
  }
}

const requestToDepartments = async ({commit, getters}, params) => {
  const {data} = await axios.get(`${getters.getRoutes.admin_departments_path()}.json`, params)
  commit('setDepartments', data)
}

const requestToRegions = async ({commit, getters}, params) => {
  const {data} = await axios.get(`${getters.getRoutes.admin_regions_path()}.json`, params)
  commit('setRegions', data)
}

const submitDepartment = async ({getters}, {department, edit}) => {
  if (edit) {
    const { data } = await axios.patch(`${getters.getRoutes.admin_department_path(department.id)}.json`, {
      department: {
        name: department.name
      }
    })
    return data
  } else {
    const { data } = await axios.post(`${getters.getRoutes.admin_departments_path()}.json`, {
      department: {
        name: department.name
      }
    })
    return data
  }
}

const submitRegion = async ({getters}, {region, edit}) => {
  if (edit) {
    const { data } = await axios.patch(`${getters.getRoutes.admin_region_path(region.id)}.json`, {
      region: {
        name: region.name
      }
    })
    return data
  } else {
    const { data } = await axios.post(`${getters.getRoutes.admin_regions_path()}.json`, {
      region: {
        name: region.name
      }
    })
    return data
  }
}

export {
  requestToLogs,
  requestToBusinessDates,
  requestToClaimsCategories,
  requestToResponseTemplates,
  requestToMessageTemplates,
  writeBusinessDates,
  deleteBusinessDates,
  requestToDepartments,
  submitDepartment,
  requestToRegions,
  submitRegion
}
