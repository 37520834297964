<template>
  <div class="modal-delete" id="modal-delete" uk-modal>
    <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical modal-cancel-claim">
      <div class="uk-modal-close-default modal-close-default" type="button" uk-icon="guber-close"></div>
      <h3 class="modal-title modal-title-blank-profile">
        <slot name="title"/>
      </h3>
      <p v-if="deletedObjectId">
        <slot name="text"/>
      </p>
      <div class="uk-flex">
        <div class="uk-flex uk-flex-middle uk-flex-between modal-buttons-box">
          <button 
            class="btn primary" 
            :class="{'disabled-btn': loading}" 
            @click="$emit('delete', deletedObjectId)"
          >
            Удалить
          </button>
          <div class="btn outline-transparent-dark uk-modal-close">Отмена</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalDelete',
  props: {
    deletedObjectId: {
      type: [String, Number],
      default: () => null
    },
    loading: {
      type: Boolean
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/styles/variables';

.modal-delete {
  .uk-modal-dialog {
    width: 386px !important;
    padding: 40px 16px 40px 40px !important;
  }
  .modal-title {
    max-width: 306px;
  }
  p {
    @extend %fz-16;
    max-width: 306px;
  }
  .uk-flex {
    .modal-buttons-box {
      margin-top: 24px;
      button:first-child {
        margin: 0 16px 0 0;
      }
    }
  }
}
</style>
