import {mapActions, mapGetters} from 'vuex'
import indexMixin from '../../mixins/IndexMixin'

export default {
  name: 'ClaimsForCitizenPage',
  mixins: [indexMixin],
  data() {
    return {
      filters: {
        tab: 'all',
        search: '',
        page: 1
      },
      filterTabsData: [
        {name: 'Все заявки', id: 'all'},
        {name: 'К приёму', id: 'actual'},
        {name: 'Результат рассмотрения заявки', id: 'result'},
      ],
      claimNullMessage: {
        all: {
          title: 'Пока нет заявок',
          message: 'Здесь будут отображаться все ваши заявки'
        },
        actual: {
          title: 'Пока нет актуальных заявок',
          message: 'Здесь будут отображаться заявки перед приглашением на онлайн-приём к губернатору '
        },
        result: {
          title: 'Пока нет решённых заявок',
          message: 'Они обязательно появятся, после согласования вашего вопроса органами власти'
        }
      }
    }
  },
  computed: {
    ...mapGetters(['getClaims']),
    claims() {
      let claims = this.getClaims?.claims
      if (this.filters.tab === 'actual' && claims) {
        claims = claims.filter(item => ['invited', 'invite_confirm', 'on_reception'].indexOf(item.state) !== -1)
        return claims.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at))
      }
      return claims
    },
    computedFilters() {
      return Object.assign({}, this.filters);
    }
  },
  methods: {
    ...mapActions(['requestToClaimsPage']),
    async getDataForPage(filter) {
      this.changeLoaders(filter, true)
      try {
        await this.requestToClaimsPage({filter: this.filters.tab, query: this.filters.search, page: this.filters.page, per_page: 10})
      } catch (e) {
        console.log(e);
      } finally {
        if (filter === 'page') this.$refs.scrollTarget.scrollIntoView({behavior: "smooth"})
        this.changeLoaders(filter)
      }
    },
  },
  created() {
    this.setLocation()
    this.getDataForPage()
  }
}
