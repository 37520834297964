import {mapActions, mapGetters} from 'vuex'
import IndexMixin from '../../mixins/IndexMixin'
import checkIpAddress from '../../mixins/checkIpAddress'
import filterArea from '../../mixins/FilterArea'

import UIkit from 'uikit'

export default {
  name: 'EmployeesForAdminPage',
  mixins: [IndexMixin, checkIpAddress, filterArea],
  data() {
    return {
      filters: {
        search: '',
        page: 1,
        perPage: 10,
        filterArea: {
          role: [''],
          date_start: '',
          date_end: ''
        },
      },
      deletedUser: null,
      loadedBtnDelete: false
    }
  },
  computed: {
    ...mapGetters(['getEmployeesData'])
  },
  methods: {
    ...mapActions(['requestToEmployees']),
    async getDataForPage(filter) {
      this.changeLoaders(filter, true)
      try {
        await this.requestToEmployees({
          params: {
            query: this.filters.search,
            role: this.filters.filterArea.role.map(item => item.id),
            date_start: this.filters.filterArea.date_start,
            date_end: this.filters.filterArea.date_end,
            page: this.filters.page,
            per_page: this.filters.perPage,
          }
        })
      } catch(e) {
        console.log(e);
      } finally {
        if (filter === 'page') this.$refs.scrollTarget.scrollIntoView({behavior: "smooth"})
        this.changeLoaders(filter)
      }
    },
    openModalDeleted(user) {
      if (this.deletedUser) this.deletedUser = null
      this.deletedUser = user
      UIkit.modal('#modal-delete').show()
    },
    async deleteEmployee(id) {
      this.loadedBtnDelete = true
      try {
        const { data } = await this.$axios.delete(`${this.$routes.admin_employee_path(id)}.json`)

        if (data.success) {
          this.$store.commit('deleteEmployees', id)
          this.deletedUser = null
          
          UIkit.notification.closeAll()
          UIkit.notification('Пользователь удален', {
            pos: 'top-right',
            status:'success'
          })

          if (this.getEmployeesData?.employees.length === 0) {
            this.changeFilters('page', this.filters.page > 1 ? this.filters.page - 1 : 1)
          } else {
            this.getDataForPage()
          }
        }
        
      } catch(e) {
        console.log(e);
      } finally {
        UIkit.modal('#modal-delete').hide()
        this.loadedBtnDelete = false
      }
    }
  },
  created() {
    this.setLocation()
    this.getDataForPage()
  }
}
