import Turbolinks from 'turbolinks'
import { validationMixin } from 'vuelidate'
import { required, sameAs } from 'vuelidate/lib/validators'

export default {
  name: "claimNewForm",
  mixins: [validationMixin],
  validations: {
    claimDataNew: {
      category: {required},
      region: {required},
      address: {required},
      title: {required},
      description: {required},
      agreement: {sameAs: sameAs( () => true )}
    }
  },
  data() {
    return {
      claimDataNew: {
        category: null,
        region: null,
        address: '',
        title: '',
        description: '',
        agreement: false
      },
      load: false,
      uploadingFiles: false,
      lengthAddress: 300,
      lengthTitle: 300,
      lengthDescription: 2000
    }
  },
  computed: {
    dataForWizard() {
      return [
        {title: 'Тема', condition: !this.$v.claimDataNew.category.$invalid},
        {title: 'Ваш город или муниципальный район', condition: !this.$v.claimDataNew.region.$invalid},
        {title: 'Адрес', condition: !this.$v.claimDataNew.address.$invalid},
        {title: 'Заголовок', condition: !this.$v.claimDataNew.title.$invalid},
        {title: 'Описание', condition: !this.$v.claimDataNew.description.$invalid},
        {title: 'Согласие на обработку персональных данных', condition: !this.$v.claimDataNew.agreement.$invalid}
      ]
    }
  },
  methods: {
    submitHandler() {
      if (this.$v.$invalid) {
        this.$v.$touch()
        this.$nextTick().then(() => {
          const element = document.querySelectorAll('.form-section.invalid')[0]
          if (element) element.scrollIntoView({behavior: "smooth"})
        })
      } else {
        this.onSubmit()
      }
    },
    async onSubmit() {
      this.load = true
      try {
        const data = await this.$store.dispatch('createNewClaim', {
          category_id: this.claimDataNew.category.id,
          region_id: this.claimDataNew.region.id,
          address: this.claimDataNew.address,
          title: this.claimDataNew.title,
          description: this.claimDataNew.description,
          agreement: this.claimDataNew.agreement,
          attachment_ids: this.$store.state.claims.attachmentIds
        })
        if (data.redirect) {
          Turbolinks.visit(data.redirect, {claim_is_new: true})
          this.$store.dispatch('createNewNotification', data.notice);
        }
      } catch(e) {
        console.log(e);
      } finally {
        this.load = false
      }
    },
    dirtyValidation(name) {
      return this.$v.claimDataNew[name].$dirty
    },
    requiredValidation(name) {
      return !this.$v.claimDataNew[name].required
    },
    checkboxValidation(name) {
      return !this.$v.claimDataNew[name].sameAs
    }
  },
  mounted() {
    // Чистка стора аттачей
    this.$store.commit('deleteAttachments')
  }
}
