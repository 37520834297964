import {mapActions, mapGetters} from 'vuex'
import IndexMixin from '../../mixins/IndexMixin'

export default {
  name: 'CitizenShowForAdminPage',
  mixins: [IndexMixin],
  data() {
    return {
      filters: {
        page: 1,
        perPage: 10
      },
      citizenId: null
    }
  },
  computed: {
    ...mapGetters(['getCitizenData'])
  },
  methods: {
    ...mapActions(['requestToCitizen']),
    async getDataForPage(filter) {
      this.changeLoaders(filter, true)
      try {
        await this.requestToCitizen({
          params: {
            page: this.filters.page,
            per_page: this.filters.perPage,
          },
          citizen_id: this.citizenId
        })
      } catch(e) {
        console.log(e);
      } finally {
        if (filter === 'page') this.$refs.scrollTarget.scrollIntoView({behavior: "smooth"})
        this.changeLoaders(filter)
      }
    }
  },
  created() {
    const path = window.location.pathname.split('/')
    this.citizenId = path[path.length - 1]

    this.setLocation()
    this.getDataForPage()
  }
}
