import UIkit from 'uikit'

export default {
  name: 'BusinessDates',
  data() {
    return {
      deletedDate: null,
      loadedBtnDelete: false,
      loading: false
    }
  },
  computed: {
    disabledDates() {
      const businessDates = this.$store.state.admin.forBusinessDatesPage
      if (businessDates) {
        const dates = [...businessDates.holidays, ...businessDates.workdays]
        return dates.map(item => item.date)
      }
      return []
    }
  },
  methods: {
    async getBusinessDates() {
      this.loading = true
      try {
        await this.$store.dispatch('requestToBusinessDates')
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false
      }
    },
    async setBusinessDate(data) {
      this.loading = true
      try {
        await this.$store.dispatch('writeBusinessDates', data)
        UIkit.modal('#modal-business-date').hide()
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false
      }
    },
    openModalDeleted(data) {
      this.deletedDate = data
      UIkit.modal('#modal-delete').show()
    },
    async deleteDate(id) {
      this.loadedBtnDelete = false
      try {
        await this.$store.dispatch('deleteBusinessDates', id)
        UIkit.modal('#modal-delete').hide()
        this.deletedDate = null
      } catch(e) {
        console.log(e);
      } finally {
        this.loadedBtnDelete = false
      }
    }
  },
  created() {
    this.getBusinessDates()
  }
}
