<template>
  <div class="guber-checkbox-multiple">
    <div class="uk-flex uk-flex-middle">
      <div
          class="guber-checkbox-wrapper"
          v-for="option in options"
          :key="option.id"
      >
        <guber-checkbox
            :check-id="option.id.toString()"
            @input="selectOption(option.id, $event)"
            :value="(selectedData.indexOf(option.id) !== -1)"
        >
          {{ option.name }}
        </guber-checkbox>

        <div
            class="disabled-overlay-checkbox"
            v-if="disabledOptionIds.length > 0 && disabledOptionIds.includes(option.id)"
        >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GuberCheckbox from './GuberCheckbox.vue'

export default {
  name: 'GuberCheckboxMultiple',
  props: {
    value: {
      type: Array,
      default: () => []
    },
    options: {
      type: Array,
      required: true
    },
    disabledOptionIds: {
      type: Array,
      default: () => []
    },
    singleValue: {
      type: Boolean,
      default: () => false
    }
  },
  components: {
    GuberCheckbox
  },
  data() {
    return {
      selectedData: []
    }
  },
  methods: {
    selectOption(id, val) {
      if (this.singleValue) {
        if (val) {
          this.selectedData = [id]
        } else {
          this.selectedData = []
        }
      } else {
        if (val) {
          this.selectedData.push(id)
        } else {
          this.selectedData = this.selectedData.filter(item => item !== id)
        }
      }

      this.$emit('set-select-all-citizen', (id === 'all'))
      this.$emit('input', this.selectedData)
    },
    deselectOption(value) {
      value.forEach(disabledId => {
        this.selectedData = this.selectedData.filter(id => id !== disabledId)
      })
      this.$emit('input', this.selectedData)
    },
  },
  mounted() {
    this.selectedData = [...this.value]
    this.deselectOption(this.disabledOptionIds)
  },
  watch: {
    disabledOptionIds() {
      this.deselectOption(this.disabledOptionIds)
    }
  }
}
</script>

<style lang="scss">
@import '../../assets/styles/variables';

.guber-checkbox-multiple {
  margin-top: 12px;

  > .uk-flex {
    flex-wrap: wrap;
  }
}

.guber-checkbox-wrapper {
  margin: 12px 12px 0 0;
  position: relative;

  .guber-checkbox {
    display: inline-flex;
    padding: 8px 12px 4px;
    background: $color-dark-blue-transparent;
    border-radius: 3px;
    align-items: center;

    label {
      user-select: none;
      cursor: pointer;
      margin: 0 0 4px 8px;
    }
  }

  .disabled-overlay-checkbox {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background: rgba(243, 244, 246, .5);
    border-radius: 3px;
    cursor: not-allowed;
  }
}
</style>
