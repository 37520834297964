<template>
  <div class="uploader-files-info">
    <div class="uk-flex uk-flex-start">

      <input 
        class="uk-checkbox checkbox uploader-file-checkbox" 
        type="checkbox" 
        :id="'check' + preview.id"
        :value="preview.id"
        v-model="checkboxChecked"
        :disabled="preview.loaded || deleted || preview.fail"
        v-if="updated"
      >
      <div class="uk-flex uk-flex-column">

        <div class="uk-flex uk-flex-middle">
          
          <a
            class="uploader-file-link uk-flex uk-flex-middle"
            :class="{'uploader-file-link-disabled': !preview.url}"
            :href="preview.url || ''"
            :download="preview.name || preview.title"
          >
            <div class="uploader-file-link-icon uk-flex uk-flex-middle uk-flex-center">
              <div uk-icon="guber-document"></div>
            </div>

            <div class="uploader-file-link-name">
              {{preview.name || preview.title}}
            </div>

          </a>

          <div class="uploader-file-weight">
            {{preview.size}} {{ $utils.t('upload_files.mb') }}
          </div>

          <div 
            class="uploader-file-delete"
            uk-icon="icon: guber-clean; ratio: 0.71"
            @click="deleteAttachRefactoring"
          >
          </div>

          <div 
            class="uploader-file-delete-loaded"
            v-if="deleted"
          >
            <loaders icon-view="button" primary="" />
          </div>

        </div>

        <small 
          class="fail"
          v-if="preview.fail"
        >
          Произошла ошибка, попробуйте снова
        </small>

        <div 
          class="progress" 
          v-if="preview.loaded"
        >
          <custom-progress :progress="preview.percent" />
        </div>

        <div class="uploader-file-info" v-if="updated && !preview.loaded && !preview.fail">

          <p class="uploader-file-info-text">
            {{preview.created_at | date('history')}}, {{preview.user.fullname || ''}}, {{preview.user.department || ''}}
          </p>

        </div>

      </div>

    </div>

  </div>
</template>

<script>
import CustomProgress from './CustomProgress.vue'
import Loaders from './Loaders.vue'

export default {
  name: 'DocumentForUploader',
  components: {
    CustomProgress,
    Loaders
  },
  props: {
    preview: {
      type: Object,
      required: true
    },
    updated: {
      type: Boolean,
      required: true
    },
    links: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      deleted: false,
      checkboxChecked: this.preview.kind === 'working' ? false : true
    }
  },
  methods: {
    async deleteAttachRefactoring() {
      if (this.deleted) return
      if (this.preview.loaded) {
        this.$emit('cancelUpload', this.preview.id)
      } else {
        this.deleted = true
        try {
          if (!this.preview.fail) {
            await this.$store.dispatch(this.links.deleteAction, this.preview)
          }
          this.$emit('deleteAttach', this.preview.id)
        } catch (e) {
          console.error(e);
        } finally {
          this.deleted = false
        }
      }
    },
    async updateAttachment(kind) {
      try {
        await this.$store.dispatch(this.links.updateAction, {preview: this.preview, kind: kind})
     } catch (e) {
        console.error(e)
      }
    }
  },
  watch: {
    checkboxChecked(val) {
      if (val) {
        this.updateAttachment('response')
      } else {
        this.updateAttachment('working')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/variables';

.uploader-files-info {
  @extend %fz-12;
  
  &:not(:first-child) {
    margin-top: 12px;
  }

  .uploader-file {
    &:not(:first-child) {
      margin-top: 8px;
    }
    &-checkbox {
      margin: 4px 8px 0 0;
    }

    &-link {
      &-icon {
        width: 28px;
        height: 28px;
        background: $color-background-input;
        border-radius: 4px;

        > div {
          color: $color-more-dark-blue;
          transition: all .1s ease;
        }
      }
      &-name {
        color: $color-more-dark-blue-60;
        font-weight: 500;
        margin: 3px 0 0 4px;

        max-width: 425px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        transition: all .1s ease;
      }

      &:hover {
        .uploader-file-link-icon {
          > div {
            color: $color-main-text-hover; 
          }
        }
        .uploader-file-link-name {
          color: $color-main-text-hover;
        }
      }
    }

    &-link-disabled {
      pointer-events: none;
    }

    &-weight {
      @extend %fz-10;
      margin: 4px 0 0 8px;
      color: $color-grey-description;
    }
    &-delete {
      margin-left: 17px;
      color: $color-more-dark-blue-60;
      cursor: pointer;

      transition: all .1s ease;

      &:hover {
        color: $color-main-text-hover;
      }
      &-loaded {
        margin-left: 10px;
        max-width: 20px;
        div {
          display: flex;
        }
      }
    }
    &-info {
      margin-top: 4px;
      p {
        @extend %fz-10;
        color: $color-grey-description;
      }
    }
  }
  .progress {
    margin-top: 4px;
    max-width: 52px;
  }
  .fail {
    @extend %fz-10;
    color: $color-invalid;
    margin-top: 4px;
  } 
}

.upload-files {
  &:hover {
    background: $color-dark-blue-transparent;
  }
}

.checkbox {
  min-width: 20px;
  min-height: 20px;
  background: $color-body;
  border: 1px solid $color-more-dark-blue;
  box-sizing: border-box;
  border-radius: 4px;

  &:checked {
    background-image: url('../../assets/images/checkbox-checked.png');
    background-repeat: no-repeat;
    background-position: center;
    background-color: $color-main-text;
    border-color: $color-main-text;
  }
  &-text {
    max-width: 520px;
    font-size: 16px;
    line-height: 20px;
    cursor: pointer;
  }
}

.checkbox[disabled="disabled"] {
  background: #EFF0F2;
  border: 1px solid #CCD2DD;
}
</style>
