import { mapActions, mapGetters } from 'vuex'
import indexMixin from '../../mixins/IndexMixin'
import filterArea from '../../mixins/FilterArea'

export default {
  name: 'ScheduleIndexForEmployeePage',
  mixins: [indexMixin, filterArea],
  data() {
    return {
      filters: {
        search: '',
        page: 1,
        perPage: 10,
        filterArea: {
          typeReception: {id: 'all', name: 'Все'},
          date_start: '',
          date_end: ''
        },
        perPage: 10,
        page: 1
      },
      typeReception: [
        {id: 'all', name: 'Все'},
        {id: 'past', name: 'Прошедшие'},
        {id: 'inFuture', name: 'Будущие'},
        {id: 'thisMonth', name: 'В этом месяце'}
      ]
    }
  },
  computed: {
    ...mapGetters(['getRecepitons']),
    defaultScheduleSearchData() {
      return this.filters.search === '' &&
             this.filters.filterArea.typeReception.id ==='all' &&
             this.filters.filterArea.date_end === '' &&
             this.filters.filterArea.date_start === ''
    }
  },
  methods: {
    ...mapActions(['getReceptionsForSchedule']),
    async getDataForPage(filter) {
      this.changeLoaders(filter, true)
      try {
        await this.getReceptionsForSchedule({
          query: this.filters.search,
          filter: this.filters.filterArea.typeReception.id,
          date_start: this.filters.filterArea.date_start,
          date_end: this.filters.filterArea.date_end,
          per_page: this.filters.perPage,
          page: this.filters.page
        })
      } catch (e) {
        console.log(e);
      } finally {
        if (filter === 'page') this.$refs.scrollTarget.scrollIntoView({behavior: "smooth"})
        this.changeLoaders(filter)
      }
    }
  },
  created() {
    this.setLocation()
    this.getDataForPage()
  }
}
