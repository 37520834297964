<template>
  <div class="guber-select" :class="{'guber-select-multiple': multiple}">
    <multiselect
      ref="multiselect"
      v-bind="$attrs"
      v-on="$listeners"
      :options="cmpOptions"
      :searchable="search"
      :placeholder="multiple ? '' : placeholder"
      :label="label"
      track-by="id"
      selectLabel=""
      :allowEmpty="allowEmpty"
      :multiple="multiple"
      :hide-selected="multiple"
      :disabled="disabled"
    >

      <template #singleLabel="{option}">
        <div class="value">{{option.name || option.id}}</div>
      </template>


      <template v-slot:caret>
        <div class="multiselect__select">
          <div uk-icon="guber-pagination-right"></div>
        </div>
      </template>

      <template v-slot:noOptions>
        Список пуст
      </template>
      
      <template v-slot:noResult>
        Элементы не найдены
      </template>

    </multiselect>

    <div class="placeholder-for-multiple" v-if="multiple" @click="$refs.multiselect.toggle()">
      {{placeholder}}
    </div>

  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
  name: 'GuberSelect',
  components: { Multiselect },
  props: {
    options: {
      type: [String, Array],
      default: () => []
    },
    placeholder: String,
    search: {
      type: Boolean,
      default: false
    },
    json: {
      type: Boolean,
      default: true
    },
    allowEmpty: {
      type: Boolean,
      default: true
    },
    label: {
      type: String,
      default: () => 'name'
    },
    multiple: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      value: ''
    }
  },
  computed: {
    cmpOptions() {
      if (this.json) {
        if (this.label === 'name') return JSON.parse(this.options).map((item) => ({id: item.id, name: item.name}))
        return JSON.parse(this.options).map((item) => ({id: item.id}))
      } else {
        return this.options
      }
    }
  }
}
</script>


<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss">
@import '../../assets/styles/variables';
  .guber-select {
    .multiselect {
      &__select {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        right: 6px !important;
        background-color: rgba(0, 0, 0, 0);
        > div {
          color: $color-text-black;
          transform: rotate(90deg);
        }
        &::before {
          display: none;
        }
      }
      &__tags {
        padding-left: 20px;
        display: flex;
        align-items: center;
        min-height: 44px;
        border: none;
        box-shadow: inset 1px 1px 3px rgba(99, 112, 136, 0.24);
        background: $color-background-input !important;

        @extend %fz-14;

        > * {
          width: 100%;
        }
        >.multiselect__tags-wrap .multiselect__tag {
          max-width: 200px;
        }
      }
      &__placeholder {
        margin-bottom: 10px !important;
        color: $color-grey-description;
      }
      &__single {
        padding: 0 !important;
        .value {
          background: $color-background-input !important;
          color: $color-text-black;
          @extend %fz-14;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      >.multiselect__content-wrapper {
        background: $color-background-hover-input;
        border-radius: 0px 0px 4px 4px;

        box-shadow: 0px 4px 4px rgba(99, 112, 136, 0.12) !important;
        border: none !important;
        max-height: 400px !important;
        // scrollbar 
        &::-webkit-scrollbar {
          width: 3px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 8px;
          background-color: #637088;
        }
        &::-webkit-scrollbar-track{
          border-width:0;
          background-color: #EFF0F2;
        }

        ul {
          max-width: 100%;

          > li > span {
            @extend %fz-14;
            color: $color-text-dark-black;
            text-overflow: ellipsis;
            overflow-x: hidden;
            white-space: normal;
            padding: 16px 20px 12px;

            &::after {
              display: none;
            }
          }
          > li > .multiselect__option--highlight, .multiselect__option--selected {
            font-weight: 400;
            background: $color-background-input;
            color: $color-primary;
          }
        }
      }
    }

    .multiselect--active {
      .multiselect__tags {
        background: $color-background-hover-input !important;
        .multiselect__input {
          @extend %fz-14;
          margin-bottom: 9px !important;
          padding: 0;
          caret-color: $color-primary;

          &::placeholder {
            @extend %fz-14;
            color: $color-grey-description !important;
          }
        }
        .multiselect__placeholder {
          display: inline !important;
        }
      }
      .multiselect__single {
        .value {
          background: $color-body !important;
        }
      }
    }
  }

  .guber-select-multiple {
    position: relative;
    .multiselect {
      .multiselect__tags {
        .multiselect__tags-wrap {
          .multiselect__tag {
            display: none;
          }
        }
      }
    }
    .placeholder-for-multiple {
      @extend %fz-14;
      position: absolute;
      top: 12px;
      left: 20px;
      color: $color-grey-description;
    }
  }
</style>
