<template>
  <div class="html-editor" :class="{'html-editor-focusing': focus}">
    <vue-editor
      v-model="content"
      :editor-toolbar="customToolbar"
      :placeholder="placeholder"
      @focus="focus = true"
      @blur="focus = false"
    >
    </vue-editor>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
export default {
  name: 'GuberHtmlEditor',
  components: {
    VueEditor
  },
  props: {
    value: {
      type: String
    },
    placeholder: {
      type: String
    }
  },
  data() {
    return {
      content: '',
      customToolbar: [
        [{header: [false, 1, 2, 3, 4, 5, 6]}],
        ["bold", "italic", "underline", "strike"],
        [{align: ""}, { align: "center"}, { align: "right"}, {align: "justify"}],
        ["blockquote"],
        [{list: "ordered"}, {list: "bullet"}], [{indent: "-1"}, {indent: "+1"}],
        ["link", "clean"]
      ],
      focus: false,
    }
  },
  methods: {
    changeContent() {
      if (this.value !== '') this.content = this.value
    }
  },
  watch: {
    value() {
      if (this.value !== this.content) this.changeContent()
    },
    content(value) {
      this.$emit('input', value)
    }
  },
  mounted() {
    this.changeContent()
  }
}
</script>

<style lang="scss" scoped>
  @import '../../assets/styles/variables';
  .html-editor {
    padding: 14px;
    background: $color-background-input;
    box-shadow: inset 1px 1px 3px rgba(99, 112, 136, 0.24);
    border-radius: 4px;
    box-sizing: border-box;

    &-focusing {
      background: $color-background-hover-input;
    }
  }
  ::v-deep .quillWrapper {
    > .ql-toolbar {
      padding: 0;
      border: none;
      border-bottom: 1px solid $color-more-dark-blue-20;
      > .ql-formats {
        margin-right: 12px;
      }
    }
    > .ql-container {
      border: none;

      > .ql-editor {
        @extend %fz-14;
        padding: 16px 0 0;
        caret-color: rgba(233, 79, 79, 1);

        em {
          color: $color-text-black;
        }

        a {
          color: $color-text-link;
        }
      }
      > .ql-blank::before {
        font-style: normal;
        color: $color-grey-description;
        left: 0;
      }
    }
  }
</style>
