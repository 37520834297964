<template>
  <div id="modal-update-email" uk-modal>
    <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical modal-login-employee">
      <div class="uk-modal-close-default modal-close-default" type="button" uk-icon="guber-close"></div>
      <div class="modal-title modal-title-login">Добавьте электронный адрес</div>
      <form @submit.prevent="checkField">
        <input-auth-employee
          :invalid="$v.email.$dirty && $v.email.$invalid"
          v-model="email"
          @clear-input="email = ''"
          type="text"
          placeholder="Почта"
        />
        <transition name="fade">
          <p class="small-invalid" v-if="$v.email.$dirty && $v.email.$invalid">Введите корректный адрес</p>
        </transition>

        <div class="modal-form-submit">
          <button class="btn primary uk-width-1-1" :class="{'submit-loading': loaded, 'disabled-btn': !email}" type="submit">
            <loaders v-if="loaded" icon-view="button" />
            <span v-else>Сохранить</span>      
          </button>
        </div>
      </form>

    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required, email } from "vuelidate/lib/validators";
import InputAuthEmployee from "../../form/InputAuthEmployee"
import Loaders from "../Loaders"

export default {
  name: "ModalUpdateEmail",
  components: {
    InputAuthEmployee,
    Loaders
  },
  props: {
    userId: {
      type: Number,
      required: true
    },
    username: {
      type: String,
      required: true
    }
  },
  mixins: [validationMixin],
  validations: {
    email: {required, email}
  },
  data() {
    return {
      email: '',
      loaded: false
    }
  },
  methods: {
    checkField () {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      this.submit()
    },
    async submit() {
      this.loaded = true
      try {
        const {data} = await this.$axios.patch(`${this.$routes.user_path(this.userId)}.json`, {user: {email: this.email, username: this.username}})
        if (data.success) {
          Turbolinks.visit(this.$routes.not_confirmed_path({user_id: this.userId}))
          this.$store.dispatch('createNewNotification', 'Адрес электронной почты успешно изменен')
        }
      } catch(e) {
        console.log(e);
      } finally {
        this.loaded = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/styles/variables';

  button {
    margin: 0;
  }
  .modal-form-submit {
    margin-top: 20px;
  }

  .small-invalid {
    margin: 2px 0 0 0;
    font-size: 12px;
    line-height: 18px;
    color: $color-invalid;
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity .1s;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
</style>
