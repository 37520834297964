import * as actions from './actions'
import * as mutations from './mutations'
import * as getters from './getters'


export default {
  scope: true,
  state: {
    attachmentIds: [],
    claim: null,
    departments: null,
    forClaimsPage: null,
    forClaimsAdminPage: null,
    forStatisticPage: null,
    allRegions: null
  },
  actions,
  mutations,
  getters
}
