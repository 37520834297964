export default function(str) {
  let phone = str
  if (str.length === 11) {
    phone = str.slice(1)
  } else if (str.length === 12) {
    phone = str.slice(2)
  }

  if (phone.length === 10) {
  return phone.replace(/[^0-9]/g, '')
          .replace(/(\d{3})(\d{3})(\d{2})(\d{2})/, '+7($1)$2$3$4');
  } else {
    return phone 
  }

}
