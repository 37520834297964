<template>
  <div class="guber-input-text" @click="focus">
    <input 
      :type="password ? typeForPassword : inputType" 
      :placeholder="placeholder"
      :value="value" 
      @input="$emit('input', $event.target.value)"
      @focus="$emit('focus')"
      @blur="$emit('blur')"
      :maxlength="length"
      ref="input"
    />
    <div class="guber-input-text-counter" v-if="length">
      {{length - value.length}}
    </div>
    <div class="guber-input-text-counter guber-input-text-password" v-if="password">
      <div 
        uk-icon="guber-icon-not-view" 
        @click.stop="typeForPassword = 'text'" 
        v-if="typeForPassword === 'password'"
      >
      </div>
      <div 
        uk-icon="guber-icon-view" 
        @click.stop="typeForPassword = 'password'" 
        v-else
      >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GuberInputText',
  props: {
    inputType: String,
    value: String,
    placeholder: String,
    length: {
      type: Number,
      default: () => null
    },
    password: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      typeForPassword: 'password'
    }
  },
  methods: {
    focus() {
      this.$refs.input.focus()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/variables';

  .guber-input-text {
    height: 44px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    box-shadow: inset 1px 1px 3px rgba(99, 112, 136, 0.24);
    box-sizing: border-box;
    background: $color-background-input;

    border-radius: 4px;
    
    cursor: text;

    &:focus-within {
     background: $color-background-hover-input;
    }

    input {
      @extend %fz-14;
      width: 100%;
      outline: none;
      border: none;
      border-radius: 4px;
      background: transparent;

      caret-color: $color-primary;
      color: $color-text-black;
      transition: $transition-buttons;


      &::placeholder {
        @extend %fz-14;
        color: $color-grey-description;
        opacity: 1;
      }
    }
    input[type="number"]::-webkit-outer-spin-button,
    input[type="number"]::-webkit-inner-spin-button {
      -webkit-appearance: none;
      -moz-appearance: textfield;
      margin: 0;
    }
    &-counter {
      color: #A1AAB8;
      font-size: 14px;
      line-height: 20px;
    }
    &-password {
      color: $color-main-text;
      div {
        cursor: pointer;
      }
    }
  }
</style>
