import { validationMixin } from 'vuelidate'
import { required, sameAs, maxValue, minLength } from 'vuelidate/lib/validators'

import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
dayjs.extend(isSameOrAfter)


export default {
  name: 'ReceptionFormPage',
  mixins: [validationMixin],
  validations: {
    reception: {
      title: {required},
      date: {
        required,
        sameAs: value => dayjs(value).isSameOrAfter(dayjs(), 'day')
      },
      time: {
        required,
        minLength: minLength(5),
        maxValue: value => value.split(':')[0] < 24 && value.split(':')[1] < 60
      },
      official: {required},
      moderator: {required}
    }
  },
  data() {
    return {
      reception: {
        title: '',
        date: '',
        time: '',
        official: '',
        moderator: ''
      },
      load: false,
      isEdit: false,
      reloadDatePicker: 1,
      lengthTitle: 240
    }
  },
  computed: {
    dataForVizard() {
      return [
        {title: 'Тема', condition: !this.$v.reception.title.$invalid},
        {title: 'Дата', condition: !this.$v.reception.date.$invalid},
        {title: 'Время', condition: !this.$v.reception.time.$invalid},
        {title: 'Ведущий', condition: !this.$v.reception.official.$invalid},
        {title: 'Модератор', condition: !this.$v.reception.moderator.$invalid}
      ]
    }
  },
  methods: {

    submitHandler() {
      if (this.$v.$invalid) {
        this.$v.$touch()
        this.$nextTick().then(() => {
          const element = document.querySelectorAll('.form-section.invalid')[0]
          const offset = element.getBoundingClientRect()
          if (element && offset.y < 0) element.scrollIntoView({behavior: "smooth"})
        })
      } else {
        if (this.isEdit) {
          this.updateReception()
        } else {
          this.createReception()
        }
      }
    },

    async createReception() {
      this.load = true
      try {
        const {data} = await this.$axios.post(`/receptions.json`, {
          title: this.reception.title,
          official_id: this.reception.official.id,
          moderator_id: this.reception.moderator.id,
          date: dayjs(this.reception.date).format('DD.MM.YYYY'),
          time_start: this.reception.time,
        })
        if (data.success) {
          Turbolinks.visit(this.$routes.schedule_index_path())
        }
      } catch(e) {
        console.log(e);
      } finally {
        this.load = false
      }
    },

    async updateReception() {
      this.load = true
      try {
        const {data} = await this.$axios.put(`/receptions/${window.gon.reception.id}.json`, {
          title: this.reception.title,
          official_id: this.reception.official.id,
          moderator_id: this.reception.moderator.id,
          date: dayjs(this.reception.date).format('DD.MM.YYYY'),
          time_start: this.reception.time
        })
        if (data.success) {
          Turbolinks.visit(this.$routes.schedule_path(data.resource.id))
        }
      } catch(e) {
        console.log(e);
      } finally {
        this.load = false
      }
    },

    dirtyValidation(name) {
      return this.$v.reception[name].$dirty
    },

    requiredValidation(name) {
      return !this.$v.reception[name].required
    },

    sameAsValidation(name) {
      return !this.$v.reception[name].sameAs
    },

    showValidationErrors(name) {
      this.$v.reception[name].$touch()
    },

    hideValidationErrors(name) {
      this.$v.reception[name].$reset()
    }

  },
  beforeMount() {
    this.isEdit = window.location.pathname.includes('edit')
    if (this.isEdit) {
      const reception = window.gon.reception
      this.reception =  {
        title: reception.title,
        date: dayjs(reception.date_start).$d.toString(),
        time: dayjs(reception.date_start).format('HH:mm'),
        official: reception.official,
        moderator: reception.moderator
      }

      if (reception.state !== 'scheduled') {
        this.$options.validations.reception.date.sameAs = (value) => {
          return true
        }
      }
    }
  }
}
