const getRecepitons = state => {
  return state.forScheduleIndexPage
}

const dataForReception = state => {
  return state.dataForReception
}
const dataForSheduler = state => {
  return state.dataForSheduler
}

export {
  getRecepitons,
  dataForReception,
  dataForSheduler
}