<template>
  <div class="search uk-flex uk-flex-middle uk-flex-between">
    <input v-model="value" type="text" :placeholder="placeholder">

    <div class="search-icon" uk-icon="icon: guber-icon-search" v-if="!loaded && !value"></div>
    <div class="clean-icon" uk-icon="icon: guber-clean; ratio: 0.8" @click="clearValue" v-if="!loaded && value"></div>
    <loaders icon-view="button" primary v-if="loaded && updateTimeout" />
  </div>
</template>

<script>
import Loaders from './Loaders.vue'

export default {
  name: 'SearchField',
  components: {
    Loaders
  },
  props: {
    placeholder: {
      type: String,
      required: true
    },
    cashValue: String,
    loaded: {
      type: Boolean,
      default: false
    },
    updateTimeout: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      value: '',
      timeout: null,
      update: false
    }
  },
  methods: {
    clearValue() {
      this.value = ''
      this.update = true
    }
  },
  mounted() {
    if (this.cashValue !== this.value && this.updateTimeout) this.value = this.cashValue
  },
  watch: {
    value() {
      if (this.updateTimeout) {
        if (this.timeout !== null) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          if (!this.update) this.update = true
        }, 1000)
      } else {
        this.$emit('update', this.value)
      }
    },
    update() {
      if (this.updateTimeout) {
        if (this.update === true && this.cashValue !== this.value) this.$emit('update', this.value)
        this.update = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/variables';

  .search {
    width: 100%;
    height: 44px;
    background: $color-background-input;
    box-shadow: inset 1px 1px 3px rgba(99, 112, 136, 0.24);
    border-radius: 4px;
    box-sizing: border-box;
    padding: 0 20px 0 16px;

    &-icon {
      color: $color-main-text;
    }

    input {
      @extend %fz-14;
      line-height: 37px;
      background: $color-background-input;
      width: 100%;
      outline: none;
      caret-color: $color-primary;
      border: none;


      &::placeholder {
        @extend %fz-14;
        line-height: 37px;
        color: $color-grey-description;
      }
    }
    .clean-icon {
      color: $color-grey-description;
      cursor: pointer;
    }

    &:focus-within {
      background: $color-background-hover-input;

      input {
        background: $color-background-hover-input;
      }
      .search-icon {
        visibility: hidden;
      }
    }
  }
</style>
