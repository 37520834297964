<template>
  <a class="card" :class="{ 'card-not-link': !messageLink, unread: !message.read }" :href="messageLink">
    <div class="card-info">
      <p class="card-info-date">
        от {{message.created_at | date('day')}}
      </p>
    </div>

    <h4 class="card-title">
      {{message.title}}
    </h4>


    <p class="card-description">
      {{message.text}}
    </p>

    <a class="btn primary" v-if="messageLink" :href="messageLink">
      {{`Перейти к ${message.reception ? 'приёму' : message.claim_id ? 'заявке' : message.target_user_id ? 'профилю' : ''}`}}
    </a>
  </a>
</template>

<script>
export default {
  name: 'MessageCard',
  props: {
    message: {
      type: Object,
      required: true
    }
  },
  computed: {
    messageLink() {
      if (this.message.reception) {
        return `/schedule/${this.message.reception.id}`
      } else if (this.message.claim_id) {
        return `/claims/${this.message.claim_id}`
      } else if (this.message.target_user_id) {
        return `/admin/citizens/${this.message.target_user_id}`
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/variables';

.card {
  @extend %fz-14;

  display: block;
  position: relative;
  width: 100%;
  padding: 24px;
  background: $color-background-hover-input;
  box-shadow: $card-box-shadow;
  border: 1px solid #BBC7DE;
  border-radius: 6px;
  box-sizing: border-box;

  &:not(:first-child) {
    margin-top: 24px;
  }

  .card-title {
    color: $color-main-text;
  }

  &:hover {
    .card-title {
      color: $color-main-text-hover;
    }
  }

  a {
    margin-top: 20px;
    display: inline-block;
  }

  &-info {
    &-date{
      margin: 0;
      color: $color-grey-description;
    }
  }

  &-title {
    max-width: 871px;
    margin: 20px 0 0;
    transition: $transition-buttons;
  }
  &-description {
    color: $color-text-black;
    user-select: text;
    margin: 8px 0 0;
    max-width: 871px;
  }
}

.card-not-link {
  cursor: default;
  pointer-events: none;
  &:hover {
    .card-title {
      color: $color-main-text;
    }
  }
}

.card.unread {
  &::after {
    content: "";
    position: absolute;
    top: 28px;
    right: 24px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #41B96C;
  }
}
</style>
