import UIkit from 'uikit'
import qs from 'qs'
import {mapActions} from 'vuex'


export default {
  name: 'MembersReceptionPage',
  data() {
    return {
      reception_id: window.gon.reception_id,
      search: '',
      load: false,
      activeTab: 'claims',
      filterTabsData: [
        {name: 'Авторы заявок', id: 'claims'},
        {name: 'Дополнительные участники', id: 'extra_members'},
      ],
      dataForMessage: {
        users: [],
        user: null,
        idForRequest: null
      },
      loadedBtnDelete: false,
      deletedMember: null,
      claimForMember: null
    }
  },
  computed: {
    dataForReception() {
      return this.$store.state.schedule.dataForReception
    },
    claims() {
      let claims = this.$store.state.schedule.dataForReception?.claims
      if (this.search) {
        return claims.filter(this.filterClaims)
      } else {
        return claims
      }
    },
    extra_members() {
      let extra_members = this.$store.state.schedule.dataForReception?.extra_members
      if (this.search) {
        return extra_members.filter(item => item.fullname.toLowerCase().includes(this.search.toLowerCase()))
      } else {
        return extra_members
      }
    }
  },
  methods: {
    ...mapActions(['receptionAdditionalMembers']),
    changeTab(value) {
      if (value !== this.activeTab) {
        this.activeTab = value
        const newUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}?${qs.stringify({activeTab: this.activeTab})}`
        window.history.replaceState({ turbolinks: true, url: newUrl }, '', newUrl)
      }
    },
    async getReception() {
      this.load = false
      try {
        await this.$store.dispatch('getDataForReception', {
          reception_id: this.reception_id
        })
      } catch (e) {
        console.log(e);
      } finally {
        this.load = false
      }
    },
    updateSearchField(str) {
      this.search = str.trim()
    },
    filterClaims(value) {
      return value.user.fullname.toLowerCase().includes(this.search.toLowerCase()) || value.title.toLowerCase().includes(this.search.toLowerCase())
    },
    sendMessage(claims, user) {
      if (claims) {
        this.dataForMessage.users = this.claims.map(item => item.user)
        this.dataForMessage.user = user
        this.dataForMessage.idForRequest = this.reception_id
      } else {
        this.dataForMessage.users = this.extra_members
        this.dataForMessage.user = user
        this.dataForMessage.idForRequest = this.reception_id
      }
      UIkit.modal('#modal-messages-form').show()
    },
    clearDataForMessage() {
      this.dataForMessage.users = []
      this.dataForMessage.user = null
      this.dataForMessage.idForRequest = null
    },
    openModalDeleted(member) {
      if (this.deletedMember) this.deletedMember = null
      this.deletedMember = member
      UIkit.modal('#modal-delete').show()
    },
    async deleteMember() {
      this.loadedBtnDelete = true
      try {
        const url = this.deletedMember?.extra_member ? 
        `/receptions/${this.reception_id}/additional_members/${this.deletedMember.id}.json` :
        `/receptions/${this.reception_id}/claims/${this.deletedMember.id}.json`

        const { data } = await this.$axios.delete(url)

        if (data.success) {
          if (this.deletedMember?.extra_member) {
            this.$store.commit('deleteExtraMember', this.deletedMember)
          }
          if (this.deletedMember?.extra_member === false) {
            this.$store.commit('deleteClaim', this.deletedMember.id)
          }
          UIkit.modal('#modal-delete').hide()
          this.deletedMember = null
        }
        
      } catch(e) {
        console.log(e);
      } finally {
        this.loadedBtnDelete = false
      }
    },
    async additionalMembers(data) {
      this.load = true
      try {
        await this.receptionAdditionalMembers({reception_id: this.reception_id, additional_members: {user_ids: data.ids}})
      } catch(e) {
        console.log(e);
      } finally {
        this.load = false
        UIkit.modal('#modal-select-item').hide()
      }
    },
    openModalClaimMember(claim) {
      if (claim.reception_state == 'over') {
        return this.callNotify('Редактирование приёма запрещено', 'danger')
      }

      if (this.claimForMember) this.claimForMember = null
      this.claimForMember = claim
      UIkit.modal('#modal-select-claim-members').show()
    },
    async additionalMembersClaim({ids}) {
      this.load = true
      try {
        await this.$store.dispatch('addAdditionalMembersToClaim', {
          claim: {
            additional_member_ids: ids,
          },
          claim_id: this.claimForMember.id
        })
      } catch(e) {
        console.error(e);
      } finally {
        this.load = false
        UIkit.modal('#modal-select-claim-members').hide()
        this.claimForMember = null
      }
    },
    callNotify(msg, type) {
      UIkit.notification.closeAll()
      UIkit.notification({
        message: msg,
        status: type,
        pos: 'top-right',
        timeout: 5000
      });
    }
  },
  beforeMount() {
    this.getReception()
    if (window.location.search) {
      this.activeTab = qs.parse(window.location.search.split('?')[1]).activeTab
    }
  }
}
