import indexMixin from '../../mixins/IndexMixin'
import filterArea from '../../mixins/FilterArea'

export default {
  name: 'SchedulerPage',
  mixins: [indexMixin, filterArea],
  data() {
    return {
      filters: {
        search: '',
        filterArea: {
          category: {name: 'Все темы', id: 'all'},
          date_start: '',
          date_end: ''
        }
      },
      reception_id: window.gon.reception_id,
      selectedIds: []
    }
  },
  computed: {
    claims() {
      return this.$store.state.schedule.dataForSheduler?.claims
    }
  },
  methods: {
    async getDataForPage(filter) {
      this.changeLoaders(filter, true)
      try {
        await this.$store.dispatch('requestToSchedulerPage', {
          params: {
            query: this.filters.search,
            category: this.filters.filterArea.category.id === 0 ? 'all' : this.filters.filterArea.category.id,
            date_start: this.filters.filterArea.date_start,
            date_end: this.filters.filterArea.date_end,
          },
          id: this.reception_id
        })
      } catch (e) {
        console.log(e);
      } finally {
        this.changeLoaders(filter)
      }
    },
    checkboxChanges(checked, id) {
      if (checked) {
        this.selectedIds.push(id)
      } else {
        this.selectedIds = this.selectedIds.filter(itemId => itemId !== id)
      }
    },
    async addedClaimsForReception() {
      this.changeLoaders('filter', true)
      try {
        const {data} = await this.$axios.post(`${this.$routes.reception_claims_path(this.reception_id)}`, {
          ids: this.selectedIds
        })

        if (data.success) {
          Turbolinks.visit(`/receptions/${this.reception_id}`)
        }
      } catch(e) {
        console.log(e);
      } finally {
        this.changeLoaders('filter')
      }
    }
  },
  created() {
    this.setLocation()
    this.getDataForPage()
  }
}
