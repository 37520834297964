<template>
  <div class="textarea">
    <textarea
      :name="name"
      :value="value"
      @input="$emit('input', $event.target.value)"
      :placeholder="placeholder"
      :maxlength="length"
      v-autoresize="height"
      ref="textarea"
    ></textarea>

    <div v-show="counter" class="textarea-counter">
      {{ length - value.length }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'GuberTextarea',
  props: {
    value: String,
    name: String,
    height: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: true
    },
    length: {
      type: Number,
      required: true
    },
    counter: {
      type: Boolean,
      default: false
    }
  },
  beforeMount() {
    setTimeout(() => {
      this.$refs.textarea.dispatchEvent(new InputEvent('input'));
    }, 50)
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/variables';

  .textarea {
    width: 100%;
    background: $color-background-input;
    box-sizing: border-box;
    border: none;
    box-shadow: inset 1px 1px 3px rgba(99, 112, 136, 0.24);
    border-radius: 4px;
    padding: 16px 14px 14px;
    transition: $transition-buttons;

    &:focus-within {
      background: $color-background-hover-input;
      textarea {
        background: $color-background-hover-input;
        overflow: hidden;
      }
    }

    textarea {
      @extend %fz-14;
      transition: $transition-buttons;
      min-height: 41px;
      width: 100%;
      background: $color-background-input;
      outline: none;
      border: none;
      resize: none;
      padding: 0;
      caret-color: $color-primary;
      
      &::placeholder {
        color: $color-grey-description;
        opacity: 1;
      }
    }

    &-counter {
      @extend %fz-14;
      color: $color-grey-description;
      text-align: right;  
    }
  }
</style>
