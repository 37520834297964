<template>
  <div class="custom-calendar custom-calendar-background uk-flex uk-flex-middle uk-flex-between" >
    <input
      :id="datepickerId" 
      class="custom-calendar-input" 
      type="date" 
      data-input="true"
      v-mask="'##.##.####'"
      :placeholder="placeholder"
      autocomplete="off"
      @focus="$emit('focus')"
      @blur="$emit('blur')"
    >
  </div>
</template>

<script>
import flatpickr from "flatpickr"
import {Russian} from "flatpickr/dist/l10n/ru.js"
import 'flatpickr/dist/flatpickr.min.css'
import dayjs from "dayjs";


export default {
  name: 'CustomCalendar',
  props: {
    value: String,
    datepickerId: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: true
    },
    forForm: {
      type: Boolean,
      default: false
    },
    datepickerClose: {
      type: Number,
      default: () => 0
    }
  },
  data() {
    return {
      flatpickr: null
    }
  },
  mounted() {
    const vm = this
    this.flatpickr = flatpickr('#' + this.datepickerId, {
      locale: Russian,
      allowInput: true,
      dateFormat: "d.m.Y",
      onChange: function(selectedDates, dateStr) {
        if (vm.forForm) {
          vm.$emit('input', selectedDates[0]?.toString() || '')
        } else {
          vm.$emit('input', dateStr)
        }
        
      }
    })
    if (this.value !== '') {
      if (this.forForm) {
        this.flatpickr.setDate(dayjs(this.value).format('DD.MM.YYYY'))
      } else {
        this.flatpickr.setDate(this.value)
      }
    }
  },
  watch: {
    value() {
      if (this.value === '') this.flatpickr.setDate('')
    },
    datepickerClose() {
      this.flatpickr.close()
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../../assets/styles/variables';

  .custom-calendar {
    width: 100%;
    
    &-input {
      padding: 0 20px;
      border: none;
      height: 44px;
      box-sizing: border-box;

      background: $color-background-input;
      caret-color: $color-primary;
      box-shadow: inset 1px 1px 3px rgba(99, 112, 136, 0.24);
      border-radius: 4px;
      width: 100%;

      @extend %fz-14;
      line-height: 44px;

      outline: none;

      &::placeholder {
        @extend %fz-14;
        line-height: 100%;
        color: $color-grey-description;
      }
      &:focus {
        background: $color-background-hover-input;
      }
    }
  }
</style>
