<template>
  <div class="uploader">
    <div class="uk-flex" v-if="hiddenUploaderCondition">
      <div class="uploader-custom-input">
        <div 
          class="btn small-transparent" 
          @click="$refs.attachments.click()"
        >
          <span class="button-icon" uk-icon="icon: guber-clip; ratio: 0.9"></span>
          <span>
            <slot name="button"></slot>
          </span>
        </div>
        <input 
          type="file" 
          @change="workingAttachments"
          ref="attachments"
          :accept="accept"
        >
      </div>
      <div class="uploader-files-icon">
        <slot name="icon"></slot>
      </div>
    </div>

    <div class="uploading-files" v-if="preiewFiles.length > 0">
      <documents-for-uploader 
        v-for="file in preiewFiles" 
        :key="file.id"
        :preview="file"
        :updated="updated"
        :links="links"
        @deleteAttach="deleteAttachment"
        @cancelUpload="cancelUpload"
      />
    </div>
  </div>
</template>

<script>
import UIkit from 'uikit'
import uniqueId from 'lodash/uniqueId'
import DocumentsForUploader from '../custom/DocumentsForUploader'

export default {
  name: 'UploaderFiles',
  components: {
    DocumentsForUploader
  },
  props: {
    accept: {
      type: String,
      required: true
    },
    links: {
      type: Object,
      required: true
    },
    attachments: {
      type: Array,
      default: () => []
    },
    updated: {
      type: Boolean,
      default: () => false 
    },
    hiddenUploader: {
      type: Boolean,
      default: () => false 
    },
    validSize: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      preiewFiles: [],
      filesInQueue: [],
      forUploadFile: null,
      cancelUploadFile: null
    }
  },
  computed: {
    loaded() {
      return this.filesInQueue.length === 0 && !!this.forUploadFile
    },
    hiddenUploaderCondition() {
      return this.hiddenUploader ? this.preiewFiles.length === 0 : true
    }
  },
  methods: {

    workingAttachments(event) {
      // Валидация загрузи нескольких файлов
      if (event.target.files.length > 1) {
        this.notification('Вы пытались загрузить более 1 файла')
        event.target.value = ''
        return
      }

      for(const file of event.target.files) {
        // Валидация размера
        if (this.validSize) {
          if (this.converter(file.size) > 50) {
            this.notification('Размер выбранного файла превышает 50Mб')
            event.target.value = ''
            return
          }
        }
        // Валидация форматов
        if (!this.accept.includes(this.fileType(file.name.toLowerCase()))) {
          this.notification('Формат выбранного файла не поддерживается')
          event.target.value = ''
          return
        }

        // Валидация длины имени файла
        if (file.name.split('.').slice(0, -1).join('.').length > 125) {
          this.notification('Имя файла слишком длинное')
          event.target.value = ''
          return
        }

        const preview = this.createPreview(file)
        this.preiewFiles.push(preview)
        this.filesInQueue.push({file, preview})
      }
      event.target.value = ''
    },

    async uploadFile(file, preview) {
      try {
        const formData = new FormData()
        formData.append('attachment[file]', file)

        const CancelToken = this.$axios.CancelToken
        const vm = this
        const { data } = await this.$axios.post(this.links.upload, formData, {
          onUploadProgress(e) {
            preview.percent = Math.round((e.loaded * 100) / e.total)
          },
          cancelToken: new CancelToken(function executor(c) {
            vm.cancelUploadFile = c;
          }),
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        preview.id = data.attachment.id
        preview.owner_id = data.attachment.owner_id
        if (this.updated) {
          preview.url = data.attachment.url
          preview.created_at = data.attachment.created_at
          preview.user = data.attachment.user
        }
        if (this.links.saveIdsAction) {
          this.$store.commit(this.links.saveIdsAction, data.attachment.id)
        }
      } catch(e) {
        preview.id = uniqueId('file_')
        preview.fail = true
        preview.percent = 100
        console.error(e);
      } finally {
        preview.loaded = false
        this.forUploadFile = null
        this.cancelUploadFile = null
      }
    },

    createPreview(file) {
      let preview = {
        name: file.name,
        size: this.converter(file.size),
        percent: 0,
        loaded: true,
        fail: false,
        id: uniqueId('file_')
      }
      this.updated ? preview.kind = 'working' : false
      return preview
    },

    converter(size) {
      const convertSize = Math.round((size / (1024 * 1024)) * 100) / 100
      return convertSize === 0 ? 0.01 : convertSize 
    },

    fileType(filename) {
      const array = filename.split('.')
      return array[array.length - 1]
    },

    notification(text) {
      UIkit.notification.closeAll()
      UIkit.notification({
        message: text,
        status: 'danger',
        pos: 'top-right',
        timeout: 5000
      });
    },

    deleteAttachment(id) {
      this.preiewFiles = this.preiewFiles.filter(item => item.id !== id)
    },
    cancelUpload(id) {
      let indexCancel = this.filesInQueue.findIndex(item => item.preview.id == id)
      if (indexCancel !== -1) {
        this.filesInQueue.splice(indexCancel, 1)
      } else {
        this.cancelUploadFile()
        this.cancelUploadFile = null
      }
      this.preiewFiles = this.preiewFiles.filter(item => item.id !== id)
    }
  },

  watch: {
    filesInQueue(value) {
      if (!this.forUploadFile) {
        this.forUploadFile = this.uploadFile(value[0].file, value[0].preview)
        this.filesInQueue.splice(0, 1)
      }
    },
    forUploadFile(value) {
      if (!value && this.filesInQueue.length > 0) {
        this.forUploadFile = this.uploadFile(this.filesInQueue[0].file, this.filesInQueue[0].preview)
        this.filesInQueue.splice(0, 1)
      }
    },
    loaded() {
      if (!!this.forUploadFile) {
        this.$emit('loaded', true)
      } else {
        this.$emit('loaded', false)
      }
    }
  },
  mounted() {
    // Если в заявке уже присутствуют аттачменты
    if (this.attachments && this.attachments.length > 0) {
      Array.prototype.forEach.call(this.attachments, file => {
        let filePreview = file
        filePreview.size = this.converter(file.file_file_size)
        this.preiewFiles.push(filePreview)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/variables';
.uploader-files-icon {
  cursor: pointer;
  margin-left: 8px;
  color: $color-more-dark-blue-60;
}

.uploader {
  .uploader-custom-input {
    input {
      display: none;
    }
  }

  .uploading-files {
    margin-top: 20px;
  }
}
</style>
