/**
 * File generated by js-routes 2.0.7
 * Based on Rails 6.1.3.2 routes of VirtualReception::Application
 */
const __jsr = ((that) => {
    const hasProp = (value, key) => Object.prototype.hasOwnProperty.call(value, key);
    let NodeTypes;
    (function (NodeTypes) {
        NodeTypes[NodeTypes["GROUP"] = 1] = "GROUP";
        NodeTypes[NodeTypes["CAT"] = 2] = "CAT";
        NodeTypes[NodeTypes["SYMBOL"] = 3] = "SYMBOL";
        NodeTypes[NodeTypes["OR"] = 4] = "OR";
        NodeTypes[NodeTypes["STAR"] = 5] = "STAR";
        NodeTypes[NodeTypes["LITERAL"] = 6] = "LITERAL";
        NodeTypes[NodeTypes["SLASH"] = 7] = "SLASH";
        NodeTypes[NodeTypes["DOT"] = 8] = "DOT";
    })(NodeTypes || (NodeTypes = {}));
    const Root = that;
    const ModuleReferences = {
        CJS: {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            define: (routes) => (module.exports = routes),
            support: () => typeof module === "object",
        },
        AMD: {
            define: (routes) => 
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            define([], function () {
                return routes;
            }),
            support: () => typeof define === "function" && !!define.amd,
        },
        UMD: {
            define: (routes) => {
                if (ModuleReferences.AMD.support()) {
                    ModuleReferences.AMD.define(routes);
                }
                else {
                    if (ModuleReferences.CJS.support()) {
                        try {
                            ModuleReferences.CJS.define(routes);
                        }
                        catch (error) {
                            if (error.name !== "TypeError")
                                throw error;
                        }
                    }
                }
            },
            support: () => ModuleReferences.AMD.support() || ModuleReferences.CJS.support(),
        },
        ESM: {
            define: () => null,
            support: () => true,
        },
    };
    class ParametersMissing extends Error {
        constructor(...keys) {
            super(`Route missing required keys: ${keys.join(", ")}`);
            this.keys = keys;
            Object.setPrototypeOf(this, Object.getPrototypeOf(this));
            this.name = ParametersMissing.name;
        }
    }
    const DeprecatedGlobbingBehavior = false;
    const UriEncoderSegmentRegex = /[^a-zA-Z0-9\-._~!$&'()*+,;=:@]/g;
    const ReservedOptions = [
        "anchor",
        "trailing_slash",
        "subdomain",
        "host",
        "port",
        "protocol",
    ];
    class UtilsClass {
        constructor() {
            this.configuration = {
                prefix: "",
                default_url_options: {},
                special_options_key: "_options",
                serializer: null || this.default_serializer.bind(this),
            };
        }
        default_serializer(value, prefix) {
            if (this.is_nullable(value)) {
                return "";
            }
            if (!prefix && !this.is_object(value)) {
                throw new Error("Url parameters should be a javascript hash");
            }
            prefix = prefix || "";
            const result = [];
            if (this.is_array(value)) {
                for (const element of value) {
                    result.push(this.default_serializer(element, prefix + "[]"));
                }
            }
            else if (this.is_object(value)) {
                for (let key in value) {
                    if (!hasProp(value, key))
                        continue;
                    let prop = value[key];
                    if (this.is_nullable(prop) && prefix) {
                        prop = "";
                    }
                    if (this.is_not_nullable(prop)) {
                        if (prefix) {
                            key = prefix + "[" + key + "]";
                        }
                        result.push(this.default_serializer(prop, key));
                    }
                }
            }
            else {
                if (this.is_not_nullable(value)) {
                    result.push(encodeURIComponent(prefix) + "=" + encodeURIComponent("" + value));
                }
            }
            return result.join("&");
        }
        serialize(object) {
            return this.configuration.serializer(object);
        }
        extract_options(number_of_params, args) {
            const last_el = args[args.length - 1];
            if ((args.length > number_of_params && last_el === 0) ||
                (this.is_object(last_el) &&
                    !this.looks_like_serialized_model(last_el))) {
                if (this.is_object(last_el)) {
                    delete last_el[this.configuration.special_options_key];
                }
                return {
                    args: args.slice(0, args.length - 1),
                    options: last_el,
                };
            }
            else {
                return { args, options: {} };
            }
        }
        looks_like_serialized_model(object) {
            return (this.is_object(object) &&
                !object[this.configuration.special_options_key] &&
                ("id" in object || "to_param" in object || "toParam" in object));
        }
        path_identifier(object) {
            const result = this.unwrap_path_identifier(object);
            return this.is_nullable(result) || result === false ? "" : "" + result;
        }
        unwrap_path_identifier(object) {
            let result = object;
            if (!this.is_object(object)) {
                return object;
            }
            if ("to_param" in object) {
                result = object.to_param;
            }
            else if ("toParam" in object) {
                result = object.toParam;
            }
            else if ("id" in object) {
                result = object.id;
            }
            else {
                result = object;
            }
            return this.is_callable(result) ? result.call(object) : result;
        }
        partition_parameters(parts, required_params, default_options, call_arguments) {
            // eslint-disable-next-line prefer-const
            let { args, options } = this.extract_options(parts.length, call_arguments);
            if (args.length > parts.length) {
                throw new Error("Too many parameters provided for path");
            }
            let use_all_parts = args.length > required_params.length;
            const parts_options = {};
            for (const key in options) {
                const value = options[key];
                if (!hasProp(options, key))
                    continue;
                use_all_parts = true;
                if (parts.includes(key)) {
                    parts_options[key] = value;
                }
            }
            options = {
                ...this.configuration.default_url_options,
                ...default_options,
                ...options,
            };
            const keyword_parameters = {};
            const query_parameters = {};
            for (const key in options) {
                if (!hasProp(options, key))
                    continue;
                const value = options[key];
                if (this.is_reserved_option(key)) {
                    keyword_parameters[key] = value;
                }
                else {
                    if (!this.is_nullable(value) &&
                        (value !== default_options[key] || required_params.includes(key))) {
                        query_parameters[key] = value;
                    }
                }
            }
            const route_parts = use_all_parts ? parts : required_params;
            let i = 0;
            for (const part of route_parts) {
                if (i < args.length) {
                    const value = args[i];
                    if (!hasProp(parts_options, part)) {
                        query_parameters[part] = value;
                        ++i;
                    }
                }
            }
            return { keyword_parameters, query_parameters };
        }
        build_route(parts, required_params, default_options, route, absolute, args) {
            const { keyword_parameters, query_parameters, } = this.partition_parameters(parts, required_params, default_options, args);
            const missing_params = required_params.filter((param) => !hasProp(query_parameters, param) ||
                this.is_nullable(query_parameters[param]));
            if (missing_params.length) {
                throw new ParametersMissing(...missing_params);
            }
            let result = this.get_prefix() + this.visit(route, query_parameters);
            if (keyword_parameters.trailing_slash) {
                result = result.replace(/(.*?)[/]?$/, "$1/");
            }
            const url_params = this.serialize(query_parameters);
            if (url_params.length) {
                result += "?" + url_params;
            }
            result += keyword_parameters.anchor
                ? "#" + keyword_parameters.anchor
                : "";
            if (absolute) {
                result = this.route_url(keyword_parameters) + result;
            }
            return result;
        }
        visit(route, parameters, optional = false) {
            switch (route[0]) {
                case NodeTypes.GROUP:
                    return this.visit(route[1], parameters, true);
                case NodeTypes.CAT:
                    return this.visit_cat(route, parameters, optional);
                case NodeTypes.SYMBOL:
                    return this.visit_symbol(route, parameters, optional);
                case NodeTypes.STAR:
                    return this.visit_globbing(route[1], parameters, true);
                case NodeTypes.LITERAL:
                case NodeTypes.SLASH:
                case NodeTypes.DOT:
                    return route[1];
                default:
                    throw new Error("Unknown Rails node type");
            }
        }
        is_not_nullable(object) {
            return !this.is_nullable(object);
        }
        is_nullable(object) {
            return object === undefined || object === null;
        }
        visit_cat(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [_type, left, right], parameters, optional) {
            const left_part = this.visit(left, parameters, optional);
            let right_part = this.visit(right, parameters, optional);
            if (optional &&
                ((this.is_optional_node(left[0]) && !left_part) ||
                    (this.is_optional_node(right[0]) && !right_part))) {
                return "";
            }
            // if left_part ends on '/' and right_part starts on '/'
            if (left_part[left_part.length - 1] === "/" && right_part[0] === "/") {
                // strip slash from right_part
                // to prevent double slash
                right_part = right_part.substring(1);
            }
            return left_part + right_part;
        }
        visit_symbol(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [_type, key], parameters, optional) {
            const value = this.path_identifier(parameters[key]);
            delete parameters[key];
            if (value.length) {
                return this.encode_segment(value);
            }
            if (optional) {
                return "";
            }
            else {
                throw new ParametersMissing(key);
            }
        }
        encode_segment(segment) {
            return segment.replace(UriEncoderSegmentRegex, function (str) {
                return encodeURIComponent(str);
            });
        }
        is_optional_node(node) {
            return [NodeTypes.STAR, NodeTypes.SYMBOL, NodeTypes.CAT].includes(node);
        }
        build_path_spec(route, wildcard = false) {
            let key;
            switch (route[0]) {
                case NodeTypes.GROUP:
                    return "(" + this.build_path_spec(route[1]) + ")";
                case NodeTypes.CAT:
                    return (this.build_path_spec(route[1]) + this.build_path_spec(route[2]));
                case NodeTypes.STAR:
                    return this.build_path_spec(route[1], true);
                case NodeTypes.SYMBOL:
                    key = route[1];
                    if (wildcard) {
                        return (key.startsWith("*") ? "" : "*") + key;
                    }
                    else {
                        return ":" + key;
                    }
                    break;
                case NodeTypes.SLASH:
                case NodeTypes.DOT:
                case NodeTypes.LITERAL:
                    return route[1];
                default:
                    throw new Error("Unknown Rails node type");
            }
        }
        visit_globbing(route, parameters, optional) {
            const key = route[1];
            let value = parameters[key];
            delete parameters[key];
            if (this.is_nullable(value)) {
                return this.visit(route, parameters, optional);
            }
            if (this.is_array(value)) {
                value = value.join("/");
            }
            const result = this.path_identifier(value);
            return DeprecatedGlobbingBehavior ? result : encodeURI(result);
        }
        get_prefix() {
            const prefix = this.configuration.prefix;
            return prefix.match("/$")
                ? prefix.substring(0, prefix.length - 1)
                : prefix;
        }
        route(parts_table, route_spec, absolute = false) {
            const required_params = [];
            const parts = [];
            const default_options = {};
            for (const [part, { r: required, d: value }] of Object.entries(parts_table)) {
                parts.push(part);
                if (required) {
                    required_params.push(part);
                }
                if (this.is_not_nullable(value)) {
                    default_options[part] = value;
                }
            }
            const result = (...args) => {
                return this.build_route(parts, required_params, default_options, route_spec, absolute, args);
            };
            result.requiredParams = () => required_params;
            result.toString = () => {
                return this.build_path_spec(route_spec);
            };
            return result;
        }
        route_url(route_defaults) {
            const hostname = route_defaults.host || this.current_host();
            if (!hostname) {
                return "";
            }
            const subdomain = route_defaults.subdomain
                ? route_defaults.subdomain + "."
                : "";
            const protocol = route_defaults.protocol || this.current_protocol();
            let port = route_defaults.port ||
                (!route_defaults.host ? this.current_port() : undefined);
            port = port ? ":" + port : "";
            return protocol + "://" + subdomain + hostname + port;
        }
        has_location() {
            return this.is_not_nullable(window) && !!window.location;
        }
        current_host() {
            if (this.has_location()) {
                return window.location.hostname;
            }
            else {
                return null;
            }
        }
        current_protocol() {
            if (this.has_location() && window.location.protocol !== "") {
                return window.location.protocol.replace(/:$/, "");
            }
            else {
                return "http";
            }
        }
        current_port() {
            if (this.has_location() && window.location.port !== "") {
                return window.location.port;
            }
            else {
                return "";
            }
        }
        is_object(value) {
            return (typeof value === "object" &&
                Object.prototype.toString.call(value) === "[object Object]");
        }
        is_array(object) {
            return object instanceof Array;
        }
        is_callable(object) {
            return typeof object === "function" && !!object.call;
        }
        is_reserved_option(key) {
            return ReservedOptions.includes(key);
        }
        namespace(object, namespace, routes) {
            const parts = (namespace === null || namespace === void 0 ? void 0 : namespace.split(".")) || [];
            if (parts.length === 0) {
                return routes;
            }
            for (let index = 0; index < parts.length; index++) {
                const part = parts[index];
                if (index < parts.length - 1) {
                    object = object[part] || (object[part] = {});
                }
                else {
                    return (object[part] = routes);
                }
            }
        }
        configure(new_config) {
            this.configuration = { ...this.configuration, ...new_config };
            return this.configuration;
        }
        config() {
            return { ...this.configuration };
        }
        is_module_supported(name) {
            return ModuleReferences[name].support();
        }
        ensure_module_supported(name) {
            if (!this.is_module_supported(name)) {
                throw new Error(`${name} is not supported by runtime`);
            }
        }
        define_module(name, module) {
            if (!name) {
                return;
            }
            this.ensure_module_supported(name);
            ModuleReferences[name].define(module);
        }
    }
    const Utils = new UtilsClass();
    // We want this helper name to be short
    const __jsr = {
        r(parts_table, route_spec, absolute) {
            return Utils.route(parts_table, route_spec, absolute);
        },
    };
    const result = {
        ...__jsr,
        configure: (config) => {
            return Utils.configure(config);
        },
        config: () => {
            return Utils.config();
        },
        serialize: (object) => {
            return Utils.serialize(object);
        },
        ...{},
    };
    Utils.namespace(Root, null, result);
    if ("ESM") {
        Utils.define_module("ESM", result);
    }
    return result;
})(this);
export const configure = __jsr.configure;

export const config = __jsr.config;

export const serialize = __jsr.serialize;

/**
 * Generates rails route to
 * /about(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const about_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"about"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /admin/action_logs(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_action_logs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"action_logs"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/business_dates/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_business_date_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"business_dates"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/business_dates(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_business_dates_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"business_dates"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/citizens/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_citizen_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"citizens"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/citizens(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_citizens_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"citizens"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/claim_categories(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_claim_categories_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"claim_categories"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/claim_categories/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_claim_category_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"claim_categories"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/delayed_job
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_delayed_job_web_path = __jsr.r({}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[6,"delayed_job"]]]]);

/**
 * Generates rails route to
 * /admin/departments/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_department_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"departments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/departments(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_departments_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"departments"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/employees/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_employee_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"employees"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/employees(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_employees_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"employees"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/message_templates/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_message_template_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"message_templates"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/message_templates(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_message_templates_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"message_templates"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/regions/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_region_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"regions"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/regions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_regions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"regions"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/response_templates/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_response_template_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"response_templates"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/response_templates(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_response_templates_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"response_templates"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/settings(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_settings_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"settings"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/sidekiq
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_sidekiq_web_path = __jsr.r({}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[6,"sidekiq"]]]]);

/**
 * Generates rails route to
 * /admin/settings(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_update_settings_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"settings"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/categories(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_categories_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"categories"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/claims(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_claims_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"claims"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/api_info(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_info_users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"api_info"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/regions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_regions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"regions"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /attachments/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const attachment_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"attachments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /attachments(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const attachments_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"attachments"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /statistics/categories(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const categories_statistics_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"statistics"],[2,[7,"/"],[2,[6,"categories"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /citizen/claims/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const citizen_claim_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"citizen"],[2,[7,"/"],[2,[6,"claims"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /citizen/claim_categories(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const citizen_claim_categories_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"citizen"],[2,[7,"/"],[2,[6,"claim_categories"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /citizen/claims(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const citizen_claims_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"citizen"],[2,[7,"/"],[2,[6,"claims"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /citizen/regions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const citizen_regions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"citizen"],[2,[7,"/"],[2,[6,"regions"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /claims/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const claim_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"claims"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /claims/:claim_id/attachments/:id(.:format)
 * @param {any} claim_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const claim_attachment_path = __jsr.r({"claim_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"claims"],[2,[7,"/"],[2,[3,"claim_id"],[2,[7,"/"],[2,[6,"attachments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /claims/:claim_id/attachments(.:format)
 * @param {any} claim_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const claim_attachments_path = __jsr.r({"claim_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"claims"],[2,[7,"/"],[2,[3,"claim_id"],[2,[7,"/"],[2,[6,"attachments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /claims/:claim_id/comments/:id(.:format)
 * @param {any} claim_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const claim_comment_path = __jsr.r({"claim_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"claims"],[2,[7,"/"],[2,[3,"claim_id"],[2,[7,"/"],[2,[6,"comments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /claims/:claim_id/comments(.:format)
 * @param {any} claim_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const claim_comments_path = __jsr.r({"claim_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"claims"],[2,[7,"/"],[2,[3,"claim_id"],[2,[7,"/"],[2,[6,"comments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /claims/:claim_id/departments/:id(.:format)
 * @param {any} claim_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const claim_department_path = __jsr.r({"claim_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"claims"],[2,[7,"/"],[2,[3,"claim_id"],[2,[7,"/"],[2,[6,"departments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /claims/:claim_id/departments(.:format)
 * @param {any} claim_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const claim_departments_path = __jsr.r({"claim_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"claims"],[2,[7,"/"],[2,[3,"claim_id"],[2,[7,"/"],[2,[6,"departments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /claims/:claim_id/execute_departments/:id(.:format)
 * @param {any} claim_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const claim_execute_department_path = __jsr.r({"claim_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"claims"],[2,[7,"/"],[2,[3,"claim_id"],[2,[7,"/"],[2,[6,"execute_departments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /claims/:claim_id/execute_departments(.:format)
 * @param {any} claim_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const claim_execute_departments_path = __jsr.r({"claim_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"claims"],[2,[7,"/"],[2,[3,"claim_id"],[2,[7,"/"],[2,[6,"execute_departments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /claims(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const claims_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"claims"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /citizen/claims/:id/confirm(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const confirm_citizen_claim_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"citizen"],[2,[7,"/"],[2,[6,"claims"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"confirm"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /schedule/:id/connect(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const connect_schedule_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"schedule"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"connect"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /statistics/count(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const count_statistics_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"statistics"],[2,[7,"/"],[2,[6,"count"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/sign_out(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const destroy_user_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sign_out"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /receptions/:id/download_video/:filename(.:format)
 * @param {any} id
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_video_reception_path = __jsr.r({"id":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"receptions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"download_video"],[2,[7,"/"],[2,[3,"filename"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/business_dates/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_business_date_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"business_dates"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/citizens/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_citizen_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"citizens"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/claim_categories/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_claim_category_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"claim_categories"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/employees/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_employee_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"employees"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/message_templates/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_message_template_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"message_templates"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/response_templates/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_response_template_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"response_templates"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /claims/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_claim_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"claims"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_rails_conductor_inbound_email_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /receptions/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_reception_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"receptions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /employees(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const employees_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"employees"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /help(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const help_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"help"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /receptions/:reception_id/claims/:id/invite(.:format)
 * @param {any} reception_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const invite_reception_claim_path = __jsr.r({"reception_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"receptions"],[2,[7,"/"],[2,[3,"reception_id"],[2,[7,"/"],[2,[6,"claims"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"invite"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /receptions/:reception_id/claims/invite(.:format)
 * @param {any} reception_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const invite_reception_claims_path = __jsr.r({"reception_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"receptions"],[2,[7,"/"],[2,[3,"reception_id"],[2,[7,"/"],[2,[6,"claims"],[2,[7,"/"],[2,[6,"invite"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /main/header(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const main_header_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"main"],[2,[7,"/"],[2,[6,"header"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /claims/:id/make_response(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const make_response_claim_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"claims"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"make_response"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /messages(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const messages_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"messages"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /claims/:claim_id/attachments/move_to_response(.:format)
 * @param {any} claim_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const move_to_response_claim_attachments_path = __jsr.r({"claim_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"claims"],[2,[7,"/"],[2,[3,"claim_id"],[2,[7,"/"],[2,[6,"attachments"],[2,[7,"/"],[2,[6,"move_to_response"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/business_dates/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_business_date_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"business_dates"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/claim_categories/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_claim_category_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"claim_categories"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/employees/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_employee_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"employees"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/message_templates/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_message_template_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"message_templates"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/response_templates/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_response_template_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"response_templates"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /citizen/claims/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_citizen_claim_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"citizen"],[2,[7,"/"],[2,[6,"claims"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /employees/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_employee_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"employees"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_rails_conductor_inbound_email_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/sources/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_rails_conductor_inbound_email_source_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"sources"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /receptions/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_reception_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"receptions"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/confirmation/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_confirmation_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"confirmation"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/sign_in(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sign_in"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /news(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const news_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"news"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /not_confirmed(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const not_confirmed_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"not_confirmed"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /claims/:id/not_solve(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const not_solve_claim_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"claims"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"not_solve"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /statistics/overdue(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const overdue_statistics_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"statistics"],[2,[7,"/"],[2,[6,"overdue"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /profile(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const profile_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"profile"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /profile/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const profile_edit_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"profile"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/representations/redirect/:signed_blob_id/:variation_key/*filename(.:format)
 * @param {any} signed_blob_id
 * @param {any} variation_key
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_blob_representation_path = __jsr.r({"signed_blob_id":{"r":true},"variation_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"representations"],[2,[7,"/"],[2,[6,"redirect"],[2,[7,"/"],[2,[3,"signed_blob_id"],[2,[7,"/"],[2,[3,"variation_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/representations/proxy/:signed_blob_id/:variation_key/*filename(.:format)
 * @param {any} signed_blob_id
 * @param {any} variation_key
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_blob_representation_proxy_path = __jsr.r({"signed_blob_id":{"r":true},"variation_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"representations"],[2,[7,"/"],[2,[6,"proxy"],[2,[7,"/"],[2,[3,"signed_blob_id"],[2,[7,"/"],[2,[3,"variation_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_email_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/:inbound_email_id/reroute(.:format)
 * @param {any} inbound_email_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_email_reroute_path = __jsr.r({"inbound_email_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[3,"inbound_email_id"],[2,[7,"/"],[2,[6,"reroute"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/sources(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_email_sources_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"sources"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/direct_uploads(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_direct_uploads_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"direct_uploads"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/disk/:encoded_key/*filename(.:format)
 * @param {any} encoded_key
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_disk_service_path = __jsr.r({"encoded_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"disk"],[2,[7,"/"],[2,[3,"encoded_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/mailgun/inbound_emails/mime(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mailgun_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"mailgun"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"mime"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/mandrill/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mandrill_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"mandrill"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/mandrill/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mandrill_inbound_health_check_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"mandrill"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/postmark/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_postmark_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"postmark"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/relay/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_relay_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"relay"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/sendgrid/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_sendgrid_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"sendgrid"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/blobs/redirect/:signed_id/*filename(.:format)
 * @param {any} signed_id
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_service_blob_path = __jsr.r({"signed_id":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"blobs"],[2,[7,"/"],[2,[6,"redirect"],[2,[7,"/"],[2,[3,"signed_id"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/blobs/proxy/:signed_id/*filename(.:format)
 * @param {any} signed_id
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_service_blob_proxy_path = __jsr.r({"signed_id":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"blobs"],[2,[7,"/"],[2,[6,"proxy"],[2,[7,"/"],[2,[3,"signed_id"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /receptions/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const reception_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"receptions"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /receptions/:reception_id/additional_members/:id(.:format)
 * @param {any} reception_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const reception_additional_member_path = __jsr.r({"reception_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"receptions"],[2,[7,"/"],[2,[3,"reception_id"],[2,[7,"/"],[2,[6,"additional_members"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /receptions/:reception_id/additional_members(.:format)
 * @param {any} reception_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const reception_additional_members_path = __jsr.r({"reception_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"receptions"],[2,[7,"/"],[2,[3,"reception_id"],[2,[7,"/"],[2,[6,"additional_members"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /receptions/:reception_id/attachments/:id(.:format)
 * @param {any} reception_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const reception_attachment_path = __jsr.r({"reception_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"receptions"],[2,[7,"/"],[2,[3,"reception_id"],[2,[7,"/"],[2,[6,"attachments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /receptions/:reception_id/attachments(.:format)
 * @param {any} reception_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const reception_attachments_path = __jsr.r({"reception_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"receptions"],[2,[7,"/"],[2,[3,"reception_id"],[2,[7,"/"],[2,[6,"attachments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /citizen/claims/:id/reception_cancel(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const reception_cancel_citizen_claim_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"citizen"],[2,[7,"/"],[2,[6,"claims"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"reception_cancel"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /receptions/:reception_id/claims/:id(.:format)
 * @param {any} reception_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const reception_claim_path = __jsr.r({"reception_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"receptions"],[2,[7,"/"],[2,[3,"reception_id"],[2,[7,"/"],[2,[6,"claims"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /receptions/:reception_id/claims(.:format)
 * @param {any} reception_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const reception_claims_path = __jsr.r({"reception_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"receptions"],[2,[7,"/"],[2,[3,"reception_id"],[2,[7,"/"],[2,[6,"claims"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /receptions/:reception_id/members/:id(.:format)
 * @param {any} reception_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const reception_member_path = __jsr.r({"reception_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"receptions"],[2,[7,"/"],[2,[3,"reception_id"],[2,[7,"/"],[2,[6,"members"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /receptions/:reception_id/members(.:format)
 * @param {any} reception_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const reception_members_path = __jsr.r({"reception_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"receptions"],[2,[7,"/"],[2,[3,"reception_id"],[2,[7,"/"],[2,[6,"members"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /receptions/:reception_id/messages/:id(.:format)
 * @param {any} reception_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const reception_message_path = __jsr.r({"reception_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"receptions"],[2,[7,"/"],[2,[3,"reception_id"],[2,[7,"/"],[2,[6,"messages"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /receptions/:reception_id/messages(.:format)
 * @param {any} reception_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const reception_messages_path = __jsr.r({"reception_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"receptions"],[2,[7,"/"],[2,[3,"reception_id"],[2,[7,"/"],[2,[6,"messages"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /receptions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const receptions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"receptions"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /claims/:id/recommend(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const recommend_claim_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"claims"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"recommend"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /receptions/:id/records(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const records_reception_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"receptions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"records"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /statistics/regions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const regions_statistics_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"statistics"],[2,[7,"/"],[2,[6,"regions"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /resend_confirmation(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const resend_confirmation_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"resend_confirmation"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /claims/:id/response_as_pdf(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const response_as_pdf_claim_path = __jsr.r({"id":{"r":true},"format":{"d":"pdf"}}, [2,[7,"/"],[2,[6,"claims"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"response_as_pdf"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /response_templates/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const response_template_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"response_templates"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /claims/:id/review(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const review_claim_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"claims"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"review"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const root_path = __jsr.r({}, [7,"/"]);

/**
 * Generates rails route to
 * /schedule/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const schedule_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"schedule"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /schedule(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const schedule_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"schedule"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /scheduler/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const scheduler_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"scheduler"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /claims/:id/solve(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const solve_claim_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"claims"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"solve"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /receptions/:id/start(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const start_reception_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"receptions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"start"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /statistics(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const statistics_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"statistics"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /statistics/statuses(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const statuses_statistics_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"statistics"],[2,[7,"/"],[2,[6,"statuses"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /receptions/:id/stop(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const stop_reception_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"receptions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"stop"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /templates/*template_path(.:format)
 * @param {any} template_path
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const template_path = __jsr.r({"template_path":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"templates"],[2,[7,"/"],[2,[5,[3,"template_path"]],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /schedule/:id/test(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const test_schedule_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"schedule"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"test"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /claims/:id/to_control(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const to_control_claim_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"claims"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"to_control"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /claims/:id/to_not_relevant(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const to_not_relevant_claim_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"claims"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"to_not_relevant"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /claims/:id/to_reception(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const to_reception_claim_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"claims"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"to_reception"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /claims/:id/to_work(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const to_work_claim_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"claims"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"to_work"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/disk/:encoded_token(.:format)
 * @param {any} encoded_token
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_rails_disk_service_path = __jsr.r({"encoded_token":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"disk"],[2,[7,"/"],[2,[3,"encoded_token"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /claims/:id/update_response(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_response_claim_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"claims"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_response"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /receptions/:id/upload_video(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const upload_video_reception_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"receptions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"upload_video"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /schedule/:id/user_claims(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_claims_schedule_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"schedule"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"user_claims"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/confirmation(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_confirmation_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"confirmation"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/auth/esia_oauth(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_esia_oauth_omniauth_authorize_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"auth"],[2,[7,"/"],[2,[6,"esia_oauth"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/auth/esia_oauth/callback(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_esia_oauth_omniauth_callback_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"auth"],[2,[7,"/"],[2,[6,"esia_oauth"],[2,[7,"/"],[2,[6,"callback"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/auth/esia_oauth_test(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_esia_oauth_test_omniauth_authorize_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"auth"],[2,[7,"/"],[2,[6,"esia_oauth_test"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/auth/esia_oauth_test/callback(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_esia_oauth_test_omniauth_callback_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"auth"],[2,[7,"/"],[2,[6,"esia_oauth_test"],[2,[7,"/"],[2,[6,"callback"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/sign_in(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sign_in"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /whois_info(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const whois_info_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"whois_info"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /citizen/claims/:id/withdraw(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const withdraw_citizen_claim_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"citizen"],[2,[7,"/"],[2,[6,"claims"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"withdraw"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);
