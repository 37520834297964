import axios from 'axios'

const authEmployee = async ({commit, getters}, {login, password}) => {
  const {data} = await axios.post(`${getters.getRoutes.user_session_path()}.json`,{ user: { login, password }})
  commit('setCurrentUser', data)
}

const getCurrentUser = ({commit}) => {
  const currentUser = window.gon.current_user
  if (currentUser) commit('setCurrentUser', currentUser)
}

export {
  authEmployee,
  getCurrentUser
}