import axios from 'axios'

const getReceptionsForSchedule = async ({commit, getters}, params) => {
  const {data} = await axios.get(`${getters.getRoutes.schedule_index_path()}.json`, {params})
  commit('setReceptionsForShedule', data)
}

const getDataForReception = async ({commit, getters}, {reception_id}) => {
  const {data} = await axios.get(`${getters.getRoutes.reception_path(reception_id)}.json`)
  commit('setDataForReception', data)
}

const requestToSchedulerPage = async ({commit, getters}, {id, params}) => {
  const {data} = await axios.get(`${getters.getRoutes.scheduler_path(id)}.json`, {params})
  commit('setDataForSheduler', data)
}

const receptionAdditionalMembers = async ({commit, getters}, {reception_id, additional_members}) => {
  const {data} = await axios.post(`${getters.getRoutes.reception_additional_members_path(reception_id)}`, {additional_members})
  if (data.success) {
    commit('setExtraMembers', data.additional_members)
  }
}

const addAdditionalMembersToClaim = async ({commit, getters}, params) => {
  const {data} = await axios.put(`${getters.getRoutes.claim_path(params.claim_id)}.json`, {claim: params.claim})
  if (data.success) {
    commit('setAdditionalMembersToClaim', data.claim)
  }
}


const getReceptionMessages = async ({commit, getters}, {reception_id, params}) => {
  const {data} = await axios.get(`${getters.getRoutes.reception_messages_path(reception_id)}.json`, {params})
  commit('setDataForReceptionMessages', data)
}

const receptionVideoVisit = async ({commit}, reception) => {
  await commit('setReceptionVideo', reception)
}

const deleteReceptionAttach = async ({commit, getters}, preview) => {
  const {data} = await axios.delete(`/receptions/${preview.owner_id}/attachments/${preview.id}.json`)
  if (data.success) {
    if (preview.owner_id) {
      commit('deleteReceptionAttach', {receptionId: preview.owner_id, attachmentId: preview.id})
    }
  }
}

export {
  getReceptionsForSchedule,
  getDataForReception,
  requestToSchedulerPage,
  receptionAdditionalMembers,
  addAdditionalMembersToClaim,
  getReceptionMessages,
  receptionVideoVisit,
  deleteReceptionAttach
}
