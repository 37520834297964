<template>
  <div :id="modalId" ref="modal" uk-modal>
    <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical modal-cancel-claim">
      <div class="uk-modal-close-default modal-close-default" type="button" uk-icon="guber-close"></div>

      <h3 class="modal-title-blank-profile">
        {{modalView === 'cancel' ? 'Вы уверены, что хотите отозвать заявку?' : 'Вы уверены, что хотите отказаться от приёма?'}}
      </h3>

      <p class="modal-description modal-description-blank-profile">
        {{modalView === 'cancel' ? 'Отзывая заявку, вы не сможете попасть на приём к губернатору' : 'Отказываясь от участия, вы не сможете попасть на приём к губернатору'}}
      </p>

      <div class="uk-flex uk-flex-left">
        <div class="uk-flex uk-flex-middle uk-flex-between modal-buttons-box">

          <button class="btn primary" :class="{'button-refuze': modalView === 'refuze','submit-loading': load}" @click="cancelClaim">
            <loaders v-if="load" icon-view="button" />
            <span v-else>
              {{modalView === 'cancel' ? 'Отозвать' : 'Отказаться'}}
            </span>
          </button>

          <div class="btn outline-transparent-dark uk-modal-close">Отмена</div>
          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loaders from "../Loaders"
import UIkit from 'uikit'

export default {
  name: "ModalCancelClaim",
  props: {
    modalView: String,
    claim: {
      type: Object,
      required: true
    },
    forClaimsPage: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Loaders
  },
  data() {
    return {
      load: false
    }
  },
  computed: {
    modalId() {
      if (this.modalView === 'cancel') {
        return 'modal-cancel-claim'
      } else {
        return this.forClaimsPage ? `modal-refuze-claim-${this.claim.id}` : 'modal-refuze-claim'
      }
    }
  },
  methods: {
    async cancelClaim() {
      this.load = true
      try {
        if (this.modalView === 'refuze') {
          await this.$store.dispatch('cancelClaim', {id: this.claim.id, forClaimsPage: this.forClaimsPage})
        } else if (this.modalView === 'cancel') {
          await this.$store.dispatch('toWithdrawnClaim', this.claim.id )
        }
      } catch (e) {
        console.log(e);
      } finally {
        UIkit.modal(this.$refs.modal).hide()
        this.load = false
      }
    }
  },
  beforeDestroy() {
    UIkit.modal(this.$refs.modal).hide()
  }
}
</script>

<style lang="scss" scoped>
  .modal-buttons-box {
    > button {
      > div {
        width: 63.45px;
      }
    }
    > .button-refuze {
      > div {
        width: 77.45px;
      }
    }
  }
</style>
