export default {
  name: 'ReceptionRecordsPage',
  computed: {
    reception() {
      return this.$store.state.schedule.receptionVideo
    }
  },
  created() {
    this.$store.dispatch('receptionVideoVisit', window.gon.reception)
  }
}
