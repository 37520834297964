import {mapActions, mapGetters} from 'vuex'
import IndexMixin from '../../mixins/IndexMixin'
import checkIpAddress from '../../mixins/checkIpAddress'
import UIkit from 'uikit'


export default {
  name: 'CitizensForAdminPage',
  mixins: [IndexMixin, checkIpAddress],
  data() {
    return {
      filters: {
        search: '',
        page: 1,
        perPage: 10
      }
    }
  },
  computed: {
    ...mapGetters(['getCitizensData'])
  },
  methods: {
    ...mapActions(['requestToCitizens']),
    async getDataForPage(filter) {
      this.changeLoaders(filter, true)
      try {
        await this.requestToCitizens({
          params: {
            query: this.filters.search,
            page: this.filters.page,
            per_page: this.filters.perPage,
          }
        })
      } catch(e) {
        console.log(e);
      } finally {
        if (filter === 'page') this.$refs.scrollTarget.scrollIntoView({behavior: "smooth"})
        this.changeLoaders(filter)
      }
    }
  },
  created() {
    this.setLocation()
    this.getDataForPage()
  }
}
