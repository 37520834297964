import dayjs from "dayjs";
import "dayjs/locale/ru";
import calendar from "dayjs/plugin/calendar";
import updateLocale from "dayjs/plugin/updateLocale";

dayjs.extend(calendar);
dayjs.locale("ru");

dayjs.extend(updateLocale)


export default function(str, type) {

  if (type == 'calendar') {
    return dayjs(str)
    .calendar(null, {
      sameDay: '[Сегодня]',
      lastDay: 'D MMMM',
      lastWeek: 'D MMMM',
      sameElse: 'D MMMM',
      nextDay: 'D MMMM',
      nextWeek: 'D MMMM',
    });

  } else if (type == "historyWithComma") {
    return dayjs(str).format('D MMMM YYYY, [в] HH:mm')
  } else if (type == 'history') {
    return dayjs(str).format('D MMMM YYYY [в] HH:mm')

  } else if (type === 'day') {
    return dayjs(str).format('D MMMM YYYY')

  } else if (type === 'detailed-calendar') {
    return dayjs(str).calendar(null, {
      nextDay: '[Завтра], D MMMM [в] HH:mm',
      sameDay: '[Сегодня], D MMMM [в] HH:mm',
      lastDay: 'dddd, D MMMM [в] HH:mm',
      lastWeek: 'dddd, D MMMM [в] HH:mm',
      sameElse: 'dddd, D MMMM [в] HH:mm',
      nextWeek: 'dddd, D MMMM [в] HH:mm',
    });
  } else if (type === 'forTable') {
    return dayjs(str).format('D.MM.YYYY HH:mm')
  } else if (type === 'weekday') {
    return dayjs(str).format('D.MM.YYYY, dddd')
  }

}
