import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'ResponseTemplateForm',
  mixins: [validationMixin],
  data() {
    return {
      form: {
        name: '',
        response: '',
        type: '',
        states: []
      },
      load: false,
      templateTypes: [],
      statesOptions: [
        {id: 'new', name: 'Новая'},
        {id: 'in_work', name: 'В работе'},
        {id: 'recommended', name: 'Рекомендована к участию'},
        {id: 'after_reception', name: 'Приём прошел'}
      ]
    }
  },
  validations: {
    form: {
      name: {required},
      response: {required},
      type: {required},
      states: {required}
    }
  },
  computed: {
    isEdit() {
      return window.location.pathname.includes('edit')
    },
    disabledStates() {
      const type = this.form.type?.id || ''
      switch (type) {
        case "bad":
          return ['recommended', 'after_reception']
        case "reject":
          return ['recommended', 'after_reception']
        case "reply":
          return ['new', 'in_work']
        default:
          return ['new', 'in_work', 'recommended', 'after_reception']
      }
    }
  },
  methods: {
    submitHandler() {
      if (this.$v.$invalid) {
        this.$v.$touch()
        this.$nextTick().then(() => {
          const element = document.querySelectorAll('.form-section.invalid')[0]
          const offset = element.getBoundingClientRect()
          if (element && offset.y < 0) element.scrollIntoView({behavior: "smooth"})
        })
      } else {
        if (this.isEdit) {
          this.onSubmitEdit()
        } else {
          this.onSubmitNew()
        }
      }
    },
    async onSubmitNew() {
      this.load = true
      try {
        const {data} = await this.$axios.post(`${this.$routes.admin_response_templates_path()}.json`, {response_template: {
          name: this.form.name,
          response: this.form.response,
          next_state: this.form.type.id,
          states: this.form.states
        }})
        if (data.success) {
          Turbolinks.visit(this.$routes.admin_response_templates_path())
        }
      } catch(e) {
        console.log(e);
      } finally {
        this.load = false
      }
    },
    async onSubmitEdit() {
      this.load = true
      try {
        const {data} = await this.$axios.put(`${this.$routes.admin_response_template_path(window.gon.response_template.id)}.json`, {response_template: {
          name: this.form.name,
          response: this.form.response,
          next_state: this.form.type.id,
          states: this.form.states
        }})
        if (data.success) {
          Turbolinks.visit(this.$routes.admin_response_templates_path())
        }
      } catch(e) {
        console.log(e);
      } finally {
        this.load = false
      }
    },
    requiredValidation(name) {
      return !this.$v.form[name].required
    },
    dirtyValidation(name) {
      return this.$v.form[name].$dirty
    }
  },
  beforeMount() {
    this.templateTypes = window.gon.template_types

    if (this.isEdit) {
      const template = window.gon.response_template
      this.form = {
        name: template.name,
        response: template.response || '',
        type: this.templateTypes.filter(item => item.id === template.next_state)[0],
        states: template.states
      }
    }
  }
}
