<template>
  <div
  class="modal-input"
  :class="{'modal-input-invalid': invalid}"
  @click="focus"
  >
      <input
        ref="input"
        :placeholder="placeholder"
        :type="type"
        :value="value"
        @input="$emit('input', $event.target.value)"
      >
    <div class="modal-input-icon" uk-icon="icon: guber-clean; ratio: 0.8" v-if="value.length > 0" @click.stop="$emit('clear-input', '')"></div>
  </div>
</template>

<script>
export default {
  name: 'InputAuthEmployee',
  props: {
    invalid: {
      type: Boolean
    },
    value: {
      type: String
    },
    type: {
      type: String
    },
    placeholder:{
      type: String
    }
  },
  methods: {
    focus() {
      this.$refs.input.focus()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/variables';

  .modal-input {
    height: 44px;
    width: 100%;
    background: $color-background-input;
    display: flex;
    align-items: center;
    padding: 0 20px;
    cursor: text;

    box-shadow: inset 1px 1px 3px rgba(99, 112, 136, 0.24);
    border-radius: 4px;

    &:focus-within {
      background: $color-background-hover-input;
      input {
        background: $color-background-hover-input;
      }
    }
    input {
      @extend %fz-14;
      color: $color-text-black;
      caret-color: $color-primary;

      border: none;
      outline: none;
      width: 100%;
      background: $color-background-input;
      &::placeholder{
        color: $color-grey-description;
        @extend %fz-14;
        padding-left: 4px;
        color: #A1AAB8;
      }
    }
    .modal-input-icon {
      width: 12px;
      height: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $color-grey-description;
      cursor: pointer;
      margin-left: 6px;
    }
  }

  .modal-input-invalid {
    border: $color-invalid 1px solid;
  }
</style>
