import axios from 'axios'

const createNewClaim = async ({commit, getters}, params) => {
  const {data} = await axios.post(`${getters.getRoutes.citizen_claims_path()}.json`,{
    claim: {
      category_id: params.category_id,
      region_id: params.region_id,
      address: params.address,
      title: params.title,
      description: params.description,
      attachment_ids: params.attachment_ids
    }
  })
  return data
}

const updateClaim = async ({commit, getters}, params) => {
  const {data} = await axios.put(`${getters.getRoutes.claim_path(params.claim_id)}.json`, {claim: params.claim})
  return data
}

const claimVisit = async ({commit}, claim) => {
  await commit('setClaim', claim)
}
const claimVisitDepartments = async ({commit}, departments) => {
  await commit('setDepartments', departments)
}

const cancelClaim = async ({commit, getters}, {id, forClaimsPage}) => {
  const {data} = await axios.get(`${getters.getRoutes.reception_cancel_citizen_claim_path(id)}.json`)
  if (forClaimsPage) {
    commit('setClaimForClaimsPage', data)
  } else {
    commit('setClaim', data)
  }
}

const toWithdrawnClaim = async ({commit, getters}, id) => {
  const {data} = await axios.get(`${getters.getRoutes.withdraw_citizen_claim_path(id)}.json`)
  commit('setClaim', data)
}

const claimConfirmParticipation = async ({commit, getters}, {id, forClaimsPage}) => {
  const {data} = await axios.get(`${getters.getRoutes.confirm_citizen_claim_path(id)}.json`)
  if (forClaimsPage) {
    commit('setClaimForClaimsPage', data)
  } else {
    commit('setClaim', data)
  }
}

const requestToClaimsPage = async ({commit, getters}, params) => {
  const {data} = await axios.get(`${getters.getRoutes.citizen_claims_path()}.json`, {params})
  commit('setClaims', data)
}

const requestToClaimsAdminPage = async ({commit, getters}, params) => {
  const {data} = await axios.get(`${getters.getRoutes.claims_path()}.json`, {params})
  commit('setClaimsForAdmin', data)
}

const sendComments = async ({commit, getters}, {claimId, text, kind}) => {
  const {data} = await axios.post(`${getters.getRoutes.claim_comments_path(claimId)}.json`, {comment: {text: text, kind: kind}})
  if (data.success) {
    commit('setNewComment', data.comment)
  }
}

const addDepartmentForClaim = async ({commit, getters}, {claimId, departmentIds}) => {
  const {data} = await axios.post(`${getters.getRoutes.claim_departments_path(claimId)}.json`, {claim_department: {department_ids: departmentIds}})
  if (data.success) {
    commit('setNewDepartment', {claimId, departments: data.claim_department})
    commit('deleteDepartments', data.claim_department)
  }
}
const deleteDepartmentForClaim = async ({commit, getters}, {claimId, claimDepartment}) => {
  const {data} = await axios.delete(`${getters.getRoutes.claim_department_path(claimId, claimDepartment.id)}.json`)
  if (data.success) {
    commit('deleteDepartmentInClaim', {claimId, claimDepartmentId: claimDepartment.id})
    commit('addedDepartments', claimDepartment.department)
  }
}

const updateDeloNumInDepartment = async ({commit, getters}, {claimId, claimDepartment, params}) => {
  const {data} = await axios.patch(`${getters.getRoutes.claim_department_path(claimId, claimDepartment.id)}.json`, params)
  if (data.success) {
    commit('updateDepartment', {claimId, claimDepartment, params})
  }
}

const giveAnswerToClaim = async ({commit, getters}, {claimId, params}) => {
  const {data} = await axios.patch(`${getters.getRoutes.make_response_claim_path(claimId)}.json`, params)
  if (data.success) {
    commit('updateClaim', data.claim)
  }
}

const editAnswerToClaim = async ({commit, getters}, {claimId, params}) => {
  const {data} = await axios.patch(`${getters.getRoutes.update_response_claim_path(claimId)}.json`, params)
  if (data.success) {
    commit('updateClaim', data.claim)
  }
}

const requestToStatistics = async ({commit, getters}, {path, params}) => {
  const {data} = await axios.get(getters.getRoutes[`${path}_statistics_path`]() + '.json', {params})
  commit('setStatistics', {path, data})
}

const deleteClaimsAttach = async ({commit, getters}, preview) => {
  const {data} = await axios.delete(`/claims/${preview.owner_id}/attachments/${preview.id}.json`)
  if (data.success) {
    if (preview.owner_id) {
      commit('deleteAttachment', {claimId: preview.owner_id, attachmentId: preview.id})
    }
  }
}

const deleteNewAttachment = async ({commit, getters}, preview) => {
  const {data} = await axios.delete(`/attachments/${preview.id}.json`)
  if (data.success) {
    commit('deleteAttachmentIds', preview.id)
  }
}
const updateClaimsAttach = async ({commit, getters}, {preview, kind}) => {
  const {data} = await axios.patch(`/claims/${preview.owner_id}/attachments/${preview.id}.json`, {kind})
  if (data.success) {
    commit('updateAttachment', {claimId: data.attachment.owner_id, attachment: data.attachment})
  }
}

export {
  createNewClaim,
  cancelClaim,
  claimVisit,
  claimVisitDepartments,
  toWithdrawnClaim,
  claimConfirmParticipation,
  requestToClaimsPage,
  requestToClaimsAdminPage,
  sendComments,
  addDepartmentForClaim,
  deleteDepartmentForClaim,
  updateDeloNumInDepartment,
  giveAnswerToClaim,
  editAnswerToClaim,
  updateClaim,
  requestToStatistics,
  deleteClaimsAttach,
  updateClaimsAttach,
  deleteNewAttachment
}
