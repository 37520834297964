<template>
  <div class="trueconf">
    <iframe class="hidden" id="conference" allowfullscreen="allowfullscreen" allow="microphone; camera; autoplay; display-capture" :src="url"></iframe>
  </div>
</template>

<script>

export default {
  name: 'Trueconf',
  props: {
    url: {
      type: String,
      required: true
    },
    receptionId: {
      type: String,
      required: true
    }
  },
  methods: {
    receiveMessage(event) {
      if (event.data?.type == 'hangUp' || event.data?.type == 'remoteHangUp') this.leaveReception()
    },
    leaveReception() {
      window.location.href = `${window.location.origin}/schedule/${this.receptionId}`
    }
  },
  mounted() {
    window.addEventListener('message', this.receiveMessage)
  },
  beforeDestroy() {
    window.removeEventListener('message', this.receiveMessage)
  }
}
</script>

<style lang="scss">
  .trueconf {
    margin: 32px 0;
    position: relative;

    .quality-notification {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      top: 0; right: 0; bottom: 0; left: 0;
      background: rgba(#d2e5f9, .7);
      form {
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 0 10px;
      }
      .quality-box__body {
        max-width: 100%;
      }
    }
  }

  #conference {
    width: 100%;
    height: 657px;
  }
</style>
