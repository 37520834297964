<template>
  <table class="table-custom table-custom-employees" width="100%" cellspacing="0" cellpadding="0">
    <tr class="table-column-title">
      <th width="89.2%">
        <span class="content-in-th uk-flex uk-flex-middle">
          <slot name="title"></slot> 
          <a :id="datepickerId" href="#" @click.prevent uk-icon="icon: guber-added-icon; ratio: 0.58"></a>
        </span>
      </th>
      <th width="10.8%"></th>
    </tr>
    <tr 
      class="citizen-info" 
      v-for="item in dates" 
      :key="item.id"
    >
      <td>
        {{item.date | date('weekday')}}
      </td>
      <td>
        <a href="#" @click.prevent="$emit('delete-date', item)" class="table-icon">
          <span uk-icon="delete-urn"></span>
        </a>
      </td>
    </tr>
  </table>
</template>

<script>
import flatpickr from "flatpickr"
import {Russian} from "flatpickr/dist/l10n/ru.js"
import 'flatpickr/dist/flatpickr.min.css'


export default {
  name: 'TableDates',
  props: {
    holiday: {
      type: Boolean,
      default: false
    },
    dates: {
      type: Array,
      default: () => []
    },
    datepickerId: {
      type: String,
      required: true
    },
    disabledDates: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      flatpickr: null
    }
  },
  methods: {
    emitDate(date) {
      this.$emit('set-date', {date: date.toString(), holiday: this.holiday})
      this.flatpickr.clear()
    }
  },
  mounted() {
    const vm = this
    this.flatpickr = flatpickr('#' + this.datepickerId, {
      locale: Russian,
      position: 'auto center',
      disable: vm.disabledDates,
      onChange: function(selectedDates, dateStr) {
        if (selectedDates.length > 0) {
          vm.emitDate(selectedDates[0])
        }
      }
    })
  },
  watch: {
    disabledDates(val) {
      this.flatpickr.config.disable = val
      this.flatpickr.redraw()
    }
  }
}
</script>
