import UIkit from "uikit"

export default {
  name: 'Notification',
  props: {
    messages: {
      type: Array
    }
  },
  created() {
    UIkit.notification.closeAll()
    this.messages.forEach(msg => {
      UIkit.notification({
        message: msg[1],
        status: msg[0],
        pos: 'top-right',
        timeout: 5000
      });
    });
  },
  render() {}
}
