<template>
  <div class="departemnt-container">
    <div class="department-name" :title="department.department.name">
      <p>{{department.department.name}}</p>
    </div>
    <template v-if="['new', 'in_work'].indexOf(claim.state) !== -1 && showManageDepartment">
      <input 
        class="department-delo-num" 
        type="text"
        v-model="deloNum"
        placeholder="Номер"
        @keypress.enter="updateDeloNum"
        @blur="updateDeloNum"
        
      >
      <button 
        class="department-delete uk-flex uk-flex-middle"
        :class="{'disabled-btn': loadedDelete}"
        @click="deleteDepartment"
      >
        <span uk-icon="icon: guber-clean; ratio: 0.71"></span>
      </button>
    </template>

    <p class="department-delo-num-meaning" v-else-if="department.delo_num">{{department.delo_num || ''}}</p>
  </div>
</template>

<script>
import {mapActions} from 'vuex'
export default {
  name: 'DepartmentFieldForClaim',
  props: {
    department: {
      type: Object,
      required: true
    },
    claim: {
      type: Object,
      required: true
    },
    showManageDepartment: {
      type: Boolean
    }
  },
  data() {
    return {
      loadedDelete: false,
      deloNum: this.department.delo_num || ''
    }
  },
  methods: {
    ...mapActions(['deleteDepartmentForClaim', 'updateDeloNumInDepartment']),
    async deleteDepartment() {
      this.loadedDelete = true
      try {
        await this.deleteDepartmentForClaim({claimId: this.claim.id, claimDepartment: this.department})
      } catch(e) {
        console.log(e);
      } finally {
        this.loadedDelete = false
      }
    },
    async updateDeloNum() {
      if (this.deloNum !== '' && this.department.delo_num !== this.deloNum) {
        try {
          this.updateDeloNumInDepartment({claimId: this.claim.id, claimDepartment: this.department, params: {claim_department: {delo_num: this.deloNum}}})
        } catch(e) {
          console.log(e);
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/variables';

.departemnt-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
  &:not(:first-child) {
    margin-top: 8px;
  }
  .department-name {
    padding: 12px 20px;
    width: 100%;
    max-width: 609px;
    min-width: 0;
    margin-right: 5px;

    background: $color-background-input;
    color: $color-main-text;
    border-radius: 3px;
    p {
      @extend %fz-14;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .department-delo-num {
    @extend %fz-14;
    padding: 12px 20px;
    width: 100%;
    max-width: 88px;
    margin-right: 5px;
    
    outline: none;
    border: none;
    background: $color-background-input;
    caret-color: $color-primary;
    box-shadow: inset 1px 1px 3px rgba(99, 112, 136, 0.24);
    border-radius: 4px;

    &::placeholder {
      @extend %fz-14;
      color: $color-grey-description;
    }
    &:focus {
      background: $color-background-hover-input;
    }
    
    &-meaning {
      @extend %fz-14;
    }
  }
  .department-delete {
    width: 10px;
    height: 10px;
    padding: 0;
    background: none;
    outline: none;
    color: $color-more-dark-blue-60;
    cursor: pointer;
  }
}

</style>
