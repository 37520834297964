<template>
  <div class="claim-form-vizard" :class="additionalClass">
    <div class="claim-form-vizard-title">
      {{vizardTitle}}
    </div>
    <ul class="claim-form-vizard-items">
      <li class="claim-form-vizard-item" v-for="item in vizardData" :key="item.title">
        <div class="vizard-icon" uk-icon="check-mark" :class="{'vizard-icon-active': item.condition}"></div>
          <p class="vizard-text">
            {{item.title}}
          </p>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'GuberVizard',
  props: {
    vizardData: {
      type: Array,
      required: true
    },
    vizardTitle: {
      type: String,
      required: true
    },
    additionalClass: {
      type: String,
      default: () => ''
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/variables';

.claim-form-vizard {
  width: 332px;
  margin-left: 32px;

  &-title {
    @extend %fz-16;
    font-weight: 700;
    color: $color-text-black;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      @extend %fz-16;
      display: flex;
      align-items: top;
      margin-top: 12px;
      > .vizard-icon {
        margin-top: 2px;
      }
      .vizard-text {
        color: $color-text-black;
        margin: 0 0 0 12px;
        max-width: 290px;
      }
    }
  }
}

.vizard-icon {
  color: rgba(145, 159, 187, 1);
  width: 16px;
  min-width: 16px;
  height: 16px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(145, 159, 187, 1);
  border-radius: 50%;
  transition: $transition-buttons;

  &-active {
    color: #FFFFFF;
    background: #63C25A;
    border-color: #63C25A;
  }
}
</style>
