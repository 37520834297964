import isEqual from 'lodash/isEqual'

export default {
  data() {
    return {
      shadowFilterArea: {},
      defaultFilterArea: {},
      showClearButton: false,
      disabledSubmitButton: false,
      submitedFilterArea: false
    }
  },
  methods: {
    submitFilterArea() {
      this.changeFilters('filterArea', this.shadowFilterArea)
      this.showClearButton = this.submitedFilterArea = true

      if (isEqual(this.shadowFilterArea, this.defaultFilterArea)) {
        this.disabledSubmitButton = true
        this.showClearButton = false
      }
    },
    clearFilterArea() {
      this.changeFilters('filterArea', this.defaultFilterArea)
      Object.assign(this.shadowFilterArea, this.defaultFilterArea)
      this.showClearButton = this.submitedFilterArea = false
    }
  },
  created() {
    this.shadowFilterArea = Object.assign({}, this.filters.filterArea)
    this.defaultFilterArea = Object.assign({}, this.filters.filterArea)
  },
  beforeMount() {
    if (!isEqual(this.shadowFilterArea, this.filters.filterArea)) {
      this.shadowFilterArea = Object.assign({}, this.filters.filterArea)
      this.showClearButton = true
    }
    if (isEqual(this.shadowFilterArea, this.defaultFilterArea)) {
      this.disabledSubmitButton = true
    }
  },
  mounted() {
    this.$watch('shadowFilterArea', function() {
      this.disabledSubmitButton = isEqual(this.shadowFilterArea, this.filters.filterArea) && !this.submitedFilterArea
      this.showClearButton = isEqual(this.shadowFilterArea, this.filters.filterArea) && this.submitedFilterArea
    }, {deep: true})
  }
}
