<template>
  <div class="conference-claims" v-if="claims">
    <h3>
      {{claims[0].user_fullname}}
    </h3>
    <div class="claim-item" v-for="claim, index in claims" :key="claim.id" :class="{'claim-item-margin': index > 0}">
      <p class="claim-item-region">
        {{claim.region_name}}
      </p>
      <p class="claim-item-question">
        {{claim.fake_question}}
      </p>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ConferenceInformation',
  props: {
    receptionId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      claims: null
    }
  },
  channels: {
    ConferenceManageChannel: {
      received({event, claims}) {
        console.log(event)
        if (event === "set_claim") {
          this.claims = claims
        }
      }
    },
  },
  mounted() {
    this.$cable.subscribe({
      channel: "ConferenceManageChannel",
      reception_id: this.receptionId
    })
  },
}
</script>

<style lang="scss" scope>
@import '../../assets/styles/variables';
  .conference-claims {
    margin-top: 20px;

    h3 {
      margin: 0;
    }
    .claim-item {
      margin-top: 8px;
      
      &-margin {
        margin-top: 32px;
      }
      &-region {
        @extend %fz-16;
        color: $color-grey-description;
      }
      &-question {
        @extend %fz-16;
        margin-top: 24px;
      }
    }
  }
</style>
