<template>
  <div class="chart">
    <highcharts
      v-if="dataForChart.length > 0"
      class="hc" 
      :options="chartOptions" 
      ref="chart"
    >
    </highcharts>
    <div class="chart-empty" v-if="dataForChart.length === 0 && !loaded">
      <img class="chart-empty-image" :src="imageEmptyChart" alt="chart is empty">
      <h4 class="chart-empty-title">Ничего не найдено</h4>
      <p class="chart-empty-subtitle">Попробуйте другие параметры поиска</p>
    </div>
  </div>
</template>

<script>
import Highcharts from 'highcharts'
import exportingInit from 'highcharts/modules/exporting'
import isUndefined from 'lodash/isUndefined'
import imageEmptyChart from '../../assets/images/claims-not-find.png'
import qs from 'qs'

exportingInit(Highcharts)
Highcharts.setOptions({
  lang: {
    printChart: 'Распечатать',
    downloadJPEG: 'Сохранить JPEG',
    downloadPDF: 'Сохранить PDF',
    downloadPNG: 'Сохранить PNG',
    downloadSVG: 'Сохранить SVG'
  },
  exporting: {
    buttons: {
      contextButton: {
        menuItems: ["printChart", "separator", "downloadPNG", "downloadJPEG", "downloadPDF", "downloadSVG"]
      }
    }
  }
})

export default {
  name: 'CustomChart',
  data() {
    return{
      imageEmptyChart
    }
  },
  props: {
    dataForChart: {
      type: Array,
      default: () => []
    },
    chartType: {
      type: String,
      required: true
    },
    tab: {
      type: Object,
      required: true
    },
    dateForTitle: {
      type: String,
      default: () => ''
    },
    loaded: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    chartOptions() {
      const vm =  this
      return {
        chart: {
          height: 600,
          width: 1000,
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false
        },
        title: {
          text: `${vm.tab.titleChart} <br/> за период ${this.countClaims()} <b>${this.dateForTitle}</b>`,
          style: {
            fontSize:'26px',
            paddingBottom:'50px',
            fontWeight:'100'
          }
        },
        legend: {
          labelFormatter: function() {
              return `${this.name} (${this.y})`
          },
          enabled: vm.chartType === 'pie'
        }, 
        credits: {
          enabled: false
        },
        tooltip: {
          formatter: function() {
            if (vm.tab.id === 'regions') {
              return vm.$store.state.claims.allRegions[this.points[0].key]
            } else {
              return this.key || this.points[0].key
            }
          },
          shared: true
        },
        xAxis: {
          tickInterval: 1,
          labels: {
            formatter: function(context) {
              const el = vm.dataForChart[context.value]
              if (isUndefined(el)) return ''
              if (vm.tab.id === 'regions') {
                const allRegions = vm.$store.state.claims.allRegions
                const filters = {
                  tab: {id: 'categories', name: '', titleChart: `Динамика количества заявок граждан по ${allRegions[el[0]]}`},
                  filterArea: {
                    regions: [{id: el[0], name: allRegions[el[0]]}],
                    date_end: qs.parse(window.location.search.split('?')[1]).filter.filterArea.date_end,
                    date_start: qs.parse(window.location.search.split('?')[1]).filter.filterArea.date_start
                  }
                }
                return `<a href="/statistics?${qs.stringify({filter: filters})}#${el[0]}">${allRegions[el[0]]}</a>`
              }
              return el[0]
            },
            useHTML: true
          }
        },
        yAxis: {
          tickInterval:1,
          title: {
            text: "Количество заявок"
          }
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: true
            },
            showInLegend: true
          }
        },
        series: [{
          type: vm.chartType,
          name: 'Заявок',
          data: vm.dataForChart
        }]
      }
    }
  },
  methods: {
    countClaims() {
      let counter = 0;
      this.dataForChart.forEach(item => {
        counter += item[1];
      })
      if (counter > 0) return `(всего ${counter} ${this.$utils.pluralize(counter, ['заявка', 'заявки', 'заявок'])})`;
      return ''
    }
  }
}
</script>

<style lang="scss">
@import '../../assets/styles/variables';

.chart {
  width: 1000px;
  margin: 0 auto;
  
  &-empty {
    margin: 120px auto 72px;
    width: 100%;
    text-align: center;
    &-image {
      margin-bottom: 24px;
    }
    &-title {
      margin: 0;
      color: $color-text-black;
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
    }
    &-subtitle {
      @extend %fz-14;
      margin: 8px 0 0;
      color: $color-grey-description;
    }
  }
}

.highcharts-background {
  fill: $color-body !important;
}
</style>
