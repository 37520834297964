<template>
  <div class="video-player">
    <div ref="play" class="play" @click="playVideo" v-if="pause"></div>
    <div class="overlay-image" v-if="pause"></div>
    <div class="overlay" v-if="pause"></div>
    <video ref="video" width="718" height="400" controls="" :src="url">
    </video>
  </div>
</template>

<script>
export default {
  name: 'VideoPlayer',
  props: {
    url: {
      type: String
    }
  },
  data() {
    return {
      pause: true
    }
  },
  methods: {
    playVideo() {
      const vid = this.$refs.video
      vid.play();
      this.pause = false
    }
  }
}
</script>

<style lang="scss" scoped>
  .video-player {
    max-width: 784px;
    max-height: 454px;
    position: relative;

    video {
      max-width: 784px;
      max-height: 454px;
      margin: 0;
    }
    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color:rgba(58, 67, 85, 0.6);
      z-index: 12;
    }
    .overlay-image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url(../../assets/images/video-default.png);
      background-size: contain;
      background-repeat: round;
      z-index: 10;
    }
    .play {
      position: absolute;
      left: 50%;
      top: 50%;
      height: 40px;
      width: 40px;
      background-image: url(../../assets/images/video-play.png);
      background-size: cover;
      webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      -o-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      z-index: 15;

      cursor: pointer;
      border-radius: 50%;
    } 
  }
</style>
