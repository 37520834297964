import Vue from 'vue/dist/vue.esm'
import {upperFirst, camelCase, kebabCase} from 'lodash'
import VueTippy, { TippyComponent } from "vue-tippy";

Vue.use(VueTippy);
Vue.component("tippy", TippyComponent);

const requireComponent = require.context('../components', true, /\.(vue|js)$/)

const requireDirective = require.context('../directives', true, /\.(js)$/)

const requireFilter = require.context('../filters', true, /\.(js)$/)

const requirePlugin = require.context('../plugins', true, /\.(js)$/)

const requireUtils = require.context('../utils', true, /\.(js)$/)

requireComponent.keys().forEach(fileName => {
  const componentConfig = requireComponent(fileName)

  const componentName = camelCase(fileName.split('/').pop().replace(/\.\w+$/, ''))

  Vue.component(
    componentName,
    componentConfig.default || componentConfig
  )
})

requireDirective.keys().forEach(fileName => {
  const directiveConfig = requireDirective(fileName)

  const directiveName = kebabCase(fileName.split('/').pop().replace(/\.\w+$/, ''))

  Vue.directive(
    directiveName,
    directiveConfig.default || directiveConfig
  )
})

requireFilter.keys().forEach(fileName => {
  const filterConfig = requireFilter(fileName)

  const filterName = camelCase(fileName.split('/').pop().replace(/\.\w+$/, ''))

  Vue.filter(
    filterName,
    filterConfig.default || filterConfig
  )
})

requirePlugin.keys().forEach(fileName => {
  const plugin = requirePlugin(fileName)

  Vue.use(plugin.default || plugin)
})

requireUtils.keys().forEach(fileName => {
  const helper = requireUtils(fileName)
  const helperName = '$' + camelCase(fileName.split('/').pop().replace(/\.\w+$/, ''))

  Vue.prototype[helperName] = helper.default
})

