<template>
  <div class="guber-select-multiple">
    <guber-select
      :options="options"
      @input="setValue"
      :value="value"
      :multiple="true"
      :placeholder="placeholder"
      :disabled="disabled"
    />

    <div class="multiple-items">
      <div class="multiple-item-wrapper" v-for="item in selectedData" :key="item.id">
        <div class="multiple-item" v-if="item">
          <div class="multiple-item-icon" @click="deletedValue(item.id)">
            <span uk-icon="icon: guber-clean; ratio: 0.6"></span>
          </div>
          <p>{{item.name}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GuberSelect from './GuberSelect'

export default {
  name: 'GuberSelectMultiple',
  components: { GuberSelect },
  props: {
    options: {
      type: [String, Array],
      default: () => []
    },
    value: {
      type: Array
    },
    placeholder: String,
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedData: []
    }
  },
  methods: {
    setValue(value) {
      if (this.disabled) return
      this.selectedData = value 
    },
    deletedValue(id) {
      if (this.disabled) return
      this.selectedData = this.selectedData.filter(item => item.id !== id)
    }
  },
  mounted() {
    this.selectedData = [...this.value]

    this.$watch('selectedData', function() {
      this.$emit('input', this.selectedData)
    })
    this.$watch('value', function() {
      this.selectedData = this.value
    })
  }
}
</script>


<style lang="scss">
@import '../../assets/styles/variables';

  .multiple-items {
    display: flex;
    flex-wrap: wrap;

    .multiple-item-wrapper {
      .multiple-item {
        @extend %fz-14;
        font-weight: 500;
        margin: 12px 16px 0 0;
        display: flex;
        align-items: center;
        color: $color-more-dark-blue-80;

        padding: 8px 16px;
        background: $color-background-input;
        border-radius: 50px;

        &-icon {
          width: 16px;
          height: 16px;
          display: flex;
          align-items: center;
          justify-content: center;

          color: $color-more-dark-blue-80;
          cursor: pointer;
          transition: color .1s ease;

          &:hover {
            color: $color-main-text;
          }
        }
        p {
          margin: 0 0 0 8px;
        }
      }
    }
  }
</style>
