import Turbolinks from 'turbolinks'
import { validationMixin } from 'vuelidate'
import { required, sameAs } from 'vuelidate/lib/validators'


export default {
  name: 'ClaimEditPage',
  mixins: [validationMixin],
  validations: {
    claimDataNew: {
      category: {required},
      title: {required},
      description: {required}
    }
  },
  data() {
    return {
      claimDataNew: {
        category: null,
        title: '',
        description: ''
      },
      lengthTitle: 300,
      lengthDescription: 2000,
      load: false
    }
  },
  computed: {
    claim() {
      return this.$store.getters.getClaim
    },
    dataForWizard() {
      return [
        {title: 'Тема', condition: !this.$v.claimDataNew.category.$invalid},
        {title: 'Заголовок', condition: !this.$v.claimDataNew.title.$invalid},
        {title: 'Описание', condition: !this.$v.claimDataNew.description.$invalid},
      ]
    }
  },
  methods: {
    submitHandler() {
      if (this.$v.$invalid) {
        this.$v.$touch()
        this.$nextTick().then(() => {
          const element = document.querySelectorAll('.form-section.invalid')[0]
          const offset = element.getBoundingClientRect()
          if (element && offset.y < 0) element.scrollIntoView({behavior: "smooth"})
        })
      } else {
        this.onSubmit()
      }
    },
    async onSubmit() {
      this.load = true
      try {
        const data = await this.$store.dispatch('updateClaim', {
          claim: {
            category_id: this.claimDataNew.category.id,
            title: this.claimDataNew.title,
            fake_question: this.claimDataNew.description,
          },
          claim_id: this.claim.id
        })
        if (data.success) {
          Turbolinks.visit(this.$routes.claim_path(data.claim.id))
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.load = false
      }
    },
    dirtyValidation(name) {
      return this.$v.claimDataNew[name].$dirty
    },
    requiredValidation(name) {
      return !this.$v.claimDataNew[name].required
    },
  },
  async mounted() {
    await this.$store.dispatch('claimVisit', window.gon.claim)

    this.claimDataNew.category = this.claim.category
    this.claimDataNew.title = this.claim.title
    this.claimDataNew.description = this.claim.fake_question
  }
}
