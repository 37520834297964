import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'ThemeClaimForm',
  mixins: [validationMixin],
  data() {
    return {
      form: {
        name: '',
        description: '',
        duration: '',
        hide: false
      },
      load: false
    }
  },
  validations: {
    form: {
      name: {required},
      duration: {required}
    }
  },
  computed: {
    isEdit() {
      return window.location.pathname.includes('edit')
    }
  },
  methods: {
    submitHandler() {
      if (this.$v.$invalid) {
        this.$v.$touch()
        this.$nextTick().then(() => {
          const element = document.querySelectorAll('.form-section.invalid')[0]
          const offset = element.getBoundingClientRect()
          if (element && offset.y < 0) element.scrollIntoView({behavior: "smooth"})
        })
      } else {
        if (this.isEdit) {
          this.onSubmitEdit()
        } else {
          this.onSubmitNew()
        }
      }
    },
    async onSubmitNew() {
      this.load = true
      try {
        const {data} = await this.$axios.post(`${this.$routes.admin_claim_categories_path()}.json`, {
          name: this.form.name,
          description: this.form.description,
          duration: this.form.duration,
          hide: this.form.hide
        })
        if (data.success) {
          Turbolinks.visit(this.$routes.admin_claim_categories_path())
        }
      } catch(e) {
        console.log(e);
      } finally {
        this.load = false
      }
    },
    async onSubmitEdit() {
      this.load = true
      try {
        const {data} = await this.$axios.put(`${this.$routes.admin_claim_category_path(window.gon.claim_category.id)}.json`, {
          name: this.form.name,
          description: this.form.description,
          duration: this.form.duration,
          hide: this.form.hide
        })
        if (data.success) {
          Turbolinks.visit(this.$routes.admin_claim_categories_path())
        }
      } catch(e) {
        console.log(e);
      } finally {
        this.load = false
      }
    },
    requiredValidation(name) {
      return !this.$v.form[name].required
    },
    dirtyValidation(name) {
      return this.$v.form[name].$dirty
    }
  },
  beforeMount() {
    if (this.isEdit) {
      const category = window.gon.claim_category
      this.form = {
        name: category.name || '',
        description: category.description || '',
        duration: category.duration.toString(),
        hide: category.hide
      }
    }
  }
}
