import * as actions from './actions'
import * as mutations from './mutations'
import * as getters from './getters'


export default {
  scope: true,
  state: {
    forLogsPage: null,
    forBusinessDatesPage: null,
    forClaimsCategories: null,
    forResponseTemplates: null,
    forMessageTemplates: null,
    forDepartmentsPage: null,
    forRegionsPage: null
  },
  actions,
  mutations,
  getters
}
