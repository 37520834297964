import * as actions from './actions'
import * as mutations from './mutations'
import * as getters from './getters'


export default {
  scope: true,
  state: {
    forScheduleIndexPage: null,
    dataForReception: null,
    dataForSheduler: null,
    dataForReceptionMessages: null,
    receptionVideo: null
  },
  actions,
  mutations,
  getters
}
