import axios from 'axios'
import qs from 'qs'

axios.defaults.paramsSerializer = params => qs.stringify(params, {arrayFormat: 'brackets'})

document.addEventListener('turbolinks:load', () => {
  const csrfToken = document.querySelector("meta[name=csrf-token]")
  if (csrfToken) {
    axios.defaults.headers.common['X-CSRF-Token'] = csrfToken.content
  }
})

class GuberAxios {
  static install(Vue, options) {
    Vue.prototype.$axios = axios
  }
}

export default GuberAxios
