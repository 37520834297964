import IndexMixin from '../../mixins/IndexMixin'
import {mapActions, mapGetters} from 'vuex'
import UIkit from 'uikit'

export default {
  name: 'MessageTemplates',
  mixins: [IndexMixin],
  data() {
    return {
      filters: {
        search: '',
        page: 1,
        perPage: 10
      },
      deletedMessage: null,
      loadedBtnDelete: false
    }
  },
  computed: {
    messageTemplates() {
      return this.$store.state.admin.forMessageTemplates?.message_templates
    },
    totalCount() {
      return this.$store.state.admin.forMessageTemplates?.total
    }
  },
  methods: {
    ...mapActions(['requestToMessageTemplates']),
    async getDataForPage(filter) {
      this.changeLoaders(filter, true)
      try {
        await this.requestToMessageTemplates({
          params: {
            query: this.filters.search,
            page: this.filters.page,
            per_page: this.filters.perPage,
          }
        })
      } catch(e) {
        console.log(e);
      } finally {
        if (filter === 'page') this.$refs.scrollTarget.scrollIntoView({behavior: "smooth"})
        this.changeLoaders(filter)
      }
    },

    openModalDeleted(response) {
      if (this.deletedMessage) this.deletedMessage = null
      this.deletedMessage = response
      UIkit.modal('#modal-delete').show()
    },

    async deleteMessage(id) {
      this.loadedBtnDelete = true
      try {
        const {data} = await this.$axios.delete(`${this.$routes.admin_message_template_path(id)}.json`)
        if (data.success) {
          this.$store.commit('deleteMessageTemplate', id)
          UIkit.modal('#modal-delete').hide()
          this.deletedMessage = null

          if (this.messageTemplates.length === 0) {
            this.changeFilters('page', this.filters.page > 1 ? this.filters.page - 1 : 1)
          } else {
            this.getDataForPage()
          }
        }
      } catch(e) {
        console.log(e);
      } finally {
        this.loadedBtnDelete = false
      }
    }
  },
  created() {
    this.setLocation()
    this.getDataForPage()
  }
}
