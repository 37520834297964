const setAttachmentIds = (state, id) => {
  state.attachmentIds.push(id)
}

const deleteAttachmentIds = (state, id) => {
  state.attachmentIds = state.attachmentIds.filter(item => item !== id)
}

const deleteAttachments = (state) => {
  if (state.attachmentIds.length > 0) state.attachmentIds = []
}

const setClaim = (state, claim) => {
  state.claim = claim
}

const setDepartments = (state, departments) => {
  state.departments = departments
}

const deleteDepartments = (state, departments) => {
  const departmentsIds = departments.map(item => item.department.id)
  const filteredDepartments = state.departments.filter(item => !departmentsIds.includes(item.id))
  state.departments = filteredDepartments
}

const addedDepartments = (state, department) => {
  department.title = department.name
  delete department.name
  state.departments.push(department)
  state.departments = state.departments.sort((a, b) => a.id - b.id)
}

const setClaims = (state, claims) => {
  state.forClaimsPage = claims
}

const setClaimsForAdmin = (state, claims) => {
  state.forClaimsAdminPage = claims
}

const setClaimForClaimsPage = (state, claim) => {
  const indexReplacedItem = state.forClaimsPage.claims.findIndex(item => item.id === claim.id)
  state.forClaimsPage.claims.splice(indexReplacedItem, 1, claim)
}

const setNewComment = (state, comment) => {
  if (comment.claim_id === state.claim.id) state.claim.comments.push(comment)
}

const setNewDepartment = (state, {claimId, departments}) => {
  if (claimId === state.claim.id) {
    state.claim.departments.push(...departments)
  }
}
const deleteDepartmentInClaim = (state, {claimId, claimDepartmentId}) => {
  if (claimId === state.claim.id) {
    const deletedDepartments = state.claim.departments.filter(item => claimDepartmentId !== item.id)
    state.claim.departments = deletedDepartments
  }
}

const updateDepartment = (state, {claimId, claimDepartment, params}) => {
  if (claimId === state.claim.id) {
    state.claim.departments.forEach(item => {
      if (item.id === claimDepartment.id) {
        item.delo_num = params.claim_department.delo_num
      }
    })
  }
}

const updateClaim = (state, claim) => {
  if (claim.id === state.claim.id) {
    state.claim = claim
  }
}

const updateAttachment = (state, {claimId, attachment}) => {
  if (claimId === state.claim.id) {
    const index = state.claim.attachments.findIndex(item => item.id === attachment.id)
    if (index !== -1) {
      state.claim.attachments[index].kind = attachment.kind
    } else {
      state.claim.attachments.push(attachment)
    }
  }
}

const deleteAttachment = (state, {claimId, attachmentId}) => {
  if (claimId === state.claim.id) {
    state.claim.attachments = state.claim.attachments.filter(item => item.id !== attachmentId) 
  }
}

const setStatistics = (state, {path, data}) => {
  if (path === 'regions' && data.all_region_name && !state.allRegions) {
    state.allRegions = data.all_region_name
  }
  if (path === 'categories' && data.all_categories_name) {
    data[path] = data[path].map(item => [data.all_categories_name[item[0]], item[1]])
  }
  state.forStatisticPage = {}
  state.forStatisticPage[path] = data[path]
}


export {
  setAttachmentIds,
  deleteAttachmentIds,
  deleteAttachments,
  setClaim,
  setDepartments,
  setClaims,
  setClaimsForAdmin,
  setClaimForClaimsPage,
  setNewComment,
  setNewDepartment,
  deleteDepartmentInClaim,
  deleteDepartments,
  addedDepartments,
  updateDepartment,
  updateClaim,
  updateAttachment,
  deleteAttachment,
  setStatistics
}
