const getDepartmentsPage = (state) => {
  return state.forDepartmentsPage
}
const getRegionsPage = (state) => {
  return state.forRegionsPage
}

export {
  getDepartmentsPage,
  getRegionsPage
}
