export default function(str) {
  const translate = {
    new: 'Новая',
    in_work: 'В работе',
    withdrawn: 'Отозвана',
    for_reception: 'Подготовка к участию',
    invited: 'Приглашение на приём',
    invite_confirm: 'Приглашение подтверждено',
    on_reception: 'На приёме',
    after_reception: 'Приём прошел',
    answer: 'Дан ответ',
    solved: 'Решена',
    not_relevant: 'Неактуальна',

    // temporary states
    recommended: 'Рекомендована',
    reject_bad: 'Дан ответ',
    reject_ov: 'Дан ответ',
    reject_os: 'Дан ответ',
    reviewed: 'Рассмотрена',
    on_control: 'На контроле',
    not_solved: 'Не решена'
  }

  return translate[str]

}
