import {mapActions, mapGetters} from 'vuex'
import indexMixin from '../../mixins/IndexMixin'
import filterArea from '../../mixins/FilterArea'


export default {
  name: "ClaimsForEmployeePage",
  mixins: [indexMixin, filterArea],
  data() {
    return {
      filters: {
        search: '',
        filterArea: {
          category: {name: 'Все темы', id: 'all'},
          state: {id: 'all', name: 'Все статусы'},
          date_start: '',
          date_end: ''
        },
        perPage: 10,
        page: 1
      },
      statesClaim: [
        {id: 'all',name: 'Все статусы'},
        {id: 'new',name: 'Новая'},
        {id: 'in_work',name: 'В работе'},
        {id: 'recommended',name: 'Рекомендована'},
        {id: 'for_reception',name: 'Подготовка к участию'},
        {id: 'invited',name: 'Приглашение на приём'},
        {id: 'invite_confirm',name: 'Приглашение подтверждено'},
        {id: 'on_reception',name: 'На приёме'},
        {id: 'after_reception',name: 'Приём прошел'},
        {id: 'answer',name: 'Дан ответ'},
        {id: 'not_relevant',name: 'Неактуальна'},
        {id: 'withdrawn',name: 'Отозвана'}
      ]
    }
  },
  computed: {
    ...mapGetters(['getClaimsForAdmin']),
    defaultSearchData() {
      return this.filters.search === '' &&
             this.filters.filterArea.category.id === 'all' &&
             this.filters.filterArea.state.id === 'all' &&
             this.filters.filterArea.date_end === '' &&
             this.filters.filterArea.date_start === ''
    }
  },
  methods: {
    ...mapActions(['requestToClaimsAdminPage']),
    async getDataForPage(filter) {
      this.changeLoaders(filter, true)
      try {
        await this.requestToClaimsAdminPage({
          query: this.filters.search,
          category: this.filters.filterArea.category.id === 0 ? 'all' : this.filters.filterArea.category.id,
          status: this.filters.filterArea.state.id.toString(),
          date_start: this.filters.filterArea.date_start,
          date_end: this.filters.filterArea.date_end,
          per_page: this.filters.perPage,
          page: this.filters.page
        })
      } catch (e) {
        console.log(e);
      } finally {
        if (filter === 'page') this.$refs.scrollTarget.scrollIntoView({behavior: "smooth"})
        this.changeLoaders(filter)
      }
    }
  },
  created() {
    this.setLocation()
    this.getDataForPage()
  }
}
