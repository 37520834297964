<template>
  <form @submit.prevent="checkAuthForm" :class="{'error-request': error}">

    <div class="input-login">
      <input-auth-employee
        :invalid="$v.login.$dirty && !$v.login.required"
        v-model="login"
        @clear-input="login = ''"
        type="text"
        placeholder="Логин или адрес эл. почты"
      />
      
      <transition name="fade">
        <p class="small-invalid" v-if="$v.login.$dirty && !$v.login.required">Введите логин</p>
      </transition>
    </div>

    <div class="input-password">
      <input-auth-employee
        :invalid="$v.password.$dirty && !$v.password.required"
        v-model="password"
        @clear-input="password = ''"
        type="password"
        placeholder="Пароль"
      />

      <transition name="fade">
        <p class="small-invalid" v-if="$v.password.$dirty && !$v.password.required">Введите пароль</p>
        <p class="small-error" v-if="error">Профиль с такой электронной почтой или паролем не найден</p>
      </transition>
    </div>

    <div class="modal-form-submit">
      <button class="btn primary button-submit-main" :class="{'button-submit-main-loading': load}" type="submit">
        <div class="loader">
          <loaders icon-view="button" />
        </div>
        <span class="text">Войти</span>      
      </button>
    </div>

  </form>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from "vuelidate/lib/validators";
import InputAuthEmployee from "../form/InputAuthEmployee"
import Loaders from "./Loaders"

export default {
  name: 'FormLoginEmployee',
  components: {
    InputAuthEmployee,
    Loaders
  },
  mixins: [validationMixin],
  validations: {
    login: { required },
    password: { required }
  },
  data () {
    return {
      login: '',
      password: '',
      error: false,
      load: false
    }
  },
  methods: {
    checkAuthForm () {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      this.submitLogInEmployee()
    },
    async submitLogInEmployee () {
      this.load = true
      try {
        await this.$store.dispatch('authEmployee', {login: this.login, password: this.password})
        Turbolinks.visit(this.$routes.root_path())
      } catch(e) {
        if (e.data.error = "Профиль с такой электронной почтой или паролем не найден") {
          this.error = true
        }
      } finally {
        this.load = false
      }
    },
    checkError() {
      if (this.error) {
        this.error = false
      }
    }
  },
  watch: {
    login() {
      this.checkError()
    },
    password() {
      this.checkError()
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../../assets/styles/variables';

  .input-password {
    margin-top: 24px;
  }

  .modal-form-submit {
    margin-top: 20px;
  }
  .small-invalid, .small-error {
    @extend %fz-10;
    margin: 6px 0 0 0;
    color: $color-invalid;
  }
  .error-request {
    .modal-input {
      border: $color-invalid 1px solid;
    }
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity .1s;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
</style>
