import Vue from 'vue/dist/vue.esm'
import Vuex from 'vuex'

import user from './modules/user/index'
import claims from './modules/claims/index'
import schedule from './modules/schedule/index'
import messages from './modules/messages/index'
import notificaton from './modules/notificaton/index'
import citizens from './modules/citizens/index'
import admin from './modules/admin/index'



Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    vue: Vue.prototype
  },
  getters: {
    getRoutes: state => {return state.vue.$routes}
  },
  modules: {
    user,
    claims,
    schedule,
    messages,
    notificaton,
    citizens,
    admin
  }
})

export default store
