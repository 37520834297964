
const setCitizens = (state, data) => {
  state.forCitizensPage = data
}

const setDataForCitizenPage = (state, data) => {
  state.forCitizenPage = data
}

const setEmployees = (state, data) => {
  state.forEmployeesPage = data
}

const deleteEmployees = (state, id) => {
  state.forEmployeesPage.employees = state.forEmployeesPage.employees.filter(user => user.id !== id)
  state.forEmployeesPage.total -= 1
}

export {
  setCitizens,
  setDataForCitizenPage,
  setEmployees,
  deleteEmployees
}
