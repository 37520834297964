import IndexMixin from '../mixins/IndexMixin'
import FilterArea from '../mixins/FilterArea'
import {mapActions} from 'vuex'

import UIkit from 'uikit'

export default {
  name: 'StatisticPage',
  mixins: [IndexMixin, FilterArea],
  data() {
    return {
      filters: {
        tab: {id: 'statuses', name: 'Статусы заявок', titleChart: 'Соотношение статусов заявок'},
        filterArea: {
          regions: [''],
          date_start: '',
          date_end: ''
        },
      },
      loadedBtnDelete: false,
      rerenderChart: 0,
      categories: false
    }
  },
  computed: {
    chartType() {
      if (['regions', 'categories'].includes(this.filters.tab.id)) {
        return 'bar'
      } else if (this.filters.tab.id === 'count') {
        return 'area'
      } else {
        return 'pie'
      }
    },
    tabs() {
      return [
        {id: 'statuses', name: 'Статусы заявок', titleChart: 'Соотношение статусов заявок'},
        {id: 'regions', name: 'МО и категории', titleChart: 'Динамика количества заявок граждан по МО'},
        {id: 'count', name: 'Количество заявок', titleChart: 'Динамика общего количества заявок граждан'},
        {id: 'overdue', name: 'Просроченные заявки', titleChart: 'Соотношение просроченых заявок по ведомствам'}
      ]
    },
    dateForTitleChart() {
      if (this.filters.filterArea.date_start && this.filters.filterArea.date_end) {
        return `с ${this.filters.filterArea.date_start} по ${this.filters.filterArea.date_end}`
      } else if (this.filters.filterArea.date_start) {
        return `с ${this.filters.filterArea.date_start}`
      } else if (this.filters.filterArea.date_end) {
        return `по ${this.filters.filterArea.date_end}`
      } else {
        return ''
      }
    }
  },
  methods: {
    ...mapActions(['requestToStatistics']),
    async getDataForPage(filter) {
      this.changeLoaders(filter, true)
      try {
        await this.requestToStatistics({
          path: this.filters.tab.id, 
          params: {
            region_ids: this.filters.filterArea.regions.map(item => item.id),
            date_start: this.filters.filterArea.date_start,
            date_end: this.filters.filterArea.date_end
          }
        })
      } catch(e) {
        console.log(e);
      } finally {
        this.rerenderChart += 1
        this.changeLoaders(filter)
      }
    },
    backToTheFuture() {
      window.history.back()
    }
  },
  created() {
    if (window.location.hash.includes('#')) {
      const region = window.gon.regions.filter(region => region.id == window.location.hash.match(/\d+/)[0])
      if (region.length > 0) {
        this.categories = true
        this.defaultFilterArea.regions = this.shadowFilterArea.regions = this.filters.filterArea.regions = region
      }
    }
    this.setLocation()
    this.getDataForPage()
  }
}
