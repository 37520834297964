<template>
  <div>
    <svg class="button-loader" v-if="iconView === 'button'" :class="{'button-loader-primary': primary}" width="32" height="16" viewBox="0 0 32 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="dots">
        <circle id="dot1" cx="6" cy="8" r="3" />
        <circle id="dot2" opacity="0.5" cx="16" cy="8" r="3" />
        <circle id="dot3" opacity="0.2" cx="26" cy="8" r="3" />
      </g>
    </svg>

    <svg class="loader-spinner" width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="iconView === 'page'">
      <circle cx="19" cy="19" r="19" fill="#244B89"/>
      <path d="M13.0263 28.2366C11.6545 27.3494 10.4976 26.1678 9.63953 24.7777C8.78146 23.3875 8.24381 21.8237 8.06563 20.1998C7.88744 18.5759 8.0732 16.9327 8.60941 15.3895C9.14562 13.8464 10.0188 12.442 11.1656 11.2785C12.3123 10.115 13.7038 9.22148 15.239 8.66293C16.7742 8.10437 18.4146 7.89477 20.0409 8.04936C21.6672 8.20395 23.2387 8.71883 24.6412 9.55663C26.0436 10.3944 27.2419 11.5341 28.1489 12.8928" stroke="url(#paint0_linear)" stroke-width="2" stroke-linecap="round"/>
      <defs>
        <linearGradient id="paint0_linear" x1="19" y1="11.4348" x2="13.5217" y2="28.3913" gradientUnits="userSpaceOnUse">
          <stop stop-color="white"/>
          <stop offset="1" stop-color="white" stop-opacity="0"/>
        </linearGradient>
      </defs>
    </svg>
  </div>
</template>

<script>
export default {
  name: "Loaders",
  props: {
    iconView: {
      type: String
    },
    primary: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
  .button-loader {
    fill: #ffffff;

    &-primary {
      fill: #E94F4F;
    }
  }
  #dots #dot1{
    animation: load 1s infinite;
  }

  #dots #dot2{
    animation: load 1s infinite;
    animation-delay: 0.2s;
  }

  #dots #dot3{
    animation: load 1s infinite;
    animation-delay: 0.4s;
  }

  @keyframes load{
    0%{
      opacity: 1;
    }
    50%{
      opacity: .5;
    }
    100%{
      opacity: 0;
    }
  }



.loader-spinner {
  animation: rotate 1s linear infinite;
}
@keyframes rotate {
  from {
    transform: rotate(-360deg);
  }
}

</style>