const getClaim = (state) => {
  return state.claim
}

const getDepartments = (state) => {
  return state.departments
}

const getClaims = (state) => {
  return state.forClaimsPage
}

const getClaimsForAdmin = (state) => {
  return state.forClaimsAdminPage
}

export {
  getClaim,
  getDepartments,
  getClaims,
  getClaimsForAdmin
}