import { validationMixin } from 'vuelidate'
import { required, minLength, email, requiredUnless, helpers, sameAs } from 'vuelidate/lib/validators'


export default {
  name: 'UserFormPage',
  mixins: [validationMixin],
  data() {
    return {
      form: {
        surname: '',
        name: '',
        patronymic: '',
        birthday: '',
        phone: '',
        username: '',
        mail: '',
        position: '',
        department: ''
      },
      load: false
    }
  },
  validations: {
    form: {
      surname: {required},
      name: {required},
      phone: {
        required,
        minLength: minLength(14)
      },
      mail: {required, email},
      position: {required},
      password: {
        required: requiredUnless(function() {
          return true
        }),
        lengthPassword: function(value) {
          if (value.length > 0) {
            return value.length > 5
          }
          return true
        },
        validPassword: function(value) {
          if (value.length > 0) {
            const regex = '^[a-zA-Z0-9\{\}\!\#\$\%\&\'\*\+\-\/\=\?\^\_\~\|\.\`/]+$'
            return !!value.match(regex)
          }
          return true
        }
      },
      confirmPassword: {
        required: requiredUnless(function() {
          return true
        }),
        sameAsPassword: sameAs('password')
      }
    }
  },
  computed: {
    isEdit() {
      return window.location.pathname.includes('edit')
    },
    dataForWizard() {
      return [
        {title: 'Фамилия', condition: !this.$v.form.surname.$invalid},
        {title: 'Имя', condition: !this.$v.form.name.$invalid},
        {title: 'Телефон', condition: !this.$v.form.phone.$invalid},
        {title: 'Почта', condition: !this.$v.form.mail.$invalid},
        {title: 'Должность', condition: !this.$v.form.position.$invalid},
        {title: 'Пароль', condition: !this.$v.form.password.$invalid},
        {title: 'Подтверждение пароля', condition: !this.$v.form.confirmPassword.$invalid}
      ]
    }
  },
  methods: {
    submitHandler() {
      if (this.$v.$invalid) {
        this.$v.$touch()
        this.$nextTick().then(() => {
          const element = document.querySelectorAll('.form-section.invalid')[0]
          if (element) element.scrollIntoView({behavior: "smooth"})
        })
      } else {
        this.onSubmitEdit()
      }
    },
    async onSubmitEdit() {
      this.load = true
      try {
        const {data} = await this.$axios.patch(`${this.$routes.user_path(window.gon.employee.id)}.json`, {user: {
          surname: this.form.surname,
          name: this.form.name,
          patronymic: this.form.patronymic || null,
          birthday: this.form.birthday || null,
          phone: this.form.phone,
          username: this.form.username || null,
          email: this.form.mail,
          position: this.form.position || null,
          department: this.form.department?.id || null,
          password: this.form.password,
          password_confirmation: this.form.confirmPassword
        }})
        if (data.success) {
          Turbolinks.visit(this.$routes.profile_path())
        }
      } catch(e) {
        console.log(e);
      } finally {
        this.load = false
      }
    },
    dirtyValidation(name) {
      return this.$v.form[name].$dirty
    },
    requiredValidation(name) {
      return !this.$v.form[name].required
    }
  },
  beforeMount() {
    const employee = window.gon.employee
    this.form = {
      surname: employee.surname,
      name: employee.name,
      patronymic: employee.patronymic || '',
      birthday: employee.birthday || '',
      phone: employee.phone || '',
      username: employee.username || '',
      mail: employee.email || '',
      position: employee.position || '',
      department: employee.department ? {id: employee.department} : '',
      password: '',
      confirmPassword: ''
    }
  }
}
