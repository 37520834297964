import axios from 'axios'

const requestToCitizens = async ({commit, getters}, params) => {
  const {data} = await axios.get(`${getters.getRoutes.admin_citizens_path()}.json`, params)
  commit('setCitizens', data)
}

const requestToCitizen = async ({commit, getters}, {params, citizen_id}) => {
  const {data} = await axios.get(`${getters.getRoutes.admin_citizen_path(citizen_id)}.json`, {params})
  commit('setDataForCitizenPage', data)
}

const requestToEmployees = async ({commit, getters}, params) => {
  const {data} = await axios.get(`${getters.getRoutes.admin_employees_path()}.json`, params)
  commit('setEmployees', data)
}

export {
  requestToCitizens,
  requestToCitizen,
  requestToEmployees
}