import UIkit from 'uikit'

export default {
  data() {
    return {
      forCheckIp: {
        loaded: false,
        data: '',
        error: false
      }
    }
  },
  methods: {
    async checkIpAddress(ip) {
      UIkit.modal('#modal-check-ip').show()
      this.forCheckIp.loaded = true
      try {
        const {data} = await this.$axios.get(`${this.$routes.whois_info_path()}.json`, {params: {ip}})
        this.forCheckIp.data = data.parts[0].body
      } catch(e) {
        this.forCheckIp.error = true
      } finally {
        this.forCheckIp.loaded = false
      }
    }
  },
  mounted() {
    UIkit.util.on('#modal-check-ip', 'hidden', (e) => {
      if (this.forCheckIp.data) this.forCheckIp.data = ''
      if (this.forCheckIp.error) this.forCheckIp.error = false
    })
  }
}