
const setLogs = (state, data) => {
  state.forLogsPage = data
}

const setBusinessDates = (state, data) => {
  state.forBusinessDatesPage = data
}

const setClaimsCategories = (state, data) => {
  state.forClaimsCategories = data
}

const setResponseTemplates = (state, data) => {
  state.forResponseTemplates = data
}

const setMessageTemplates = (state, data) => {
  state.forMessageTemplates = data
}

const setBusinessDate = (state, businessDate) => {
  if (businessDate.holiday) {
    state.forBusinessDatesPage.holidays.push(businessDate)
    state.forBusinessDatesPage.holidays = state.forBusinessDatesPage.holidays.sort(sortDate);
  } else {
    state.forBusinessDatesPage.workdays.push(businessDate)
    state.forBusinessDatesPage.workdays = state.forBusinessDatesPage.workdays.sort(sortDate);
  }

  function sortDate(a, b) {
    a = new Date(a.date);
    b = new Date(b.date);
    return a > b ? -1 : a < b ? 1 : 0;
  }
}

const clearBusinessDate = (state, id) => {
  state.forBusinessDatesPage.holidays = state.forBusinessDatesPage.holidays.filter(item => item.id !== id)
  state.forBusinessDatesPage.workdays = state.forBusinessDatesPage.workdays.filter(item => item.id !== id)
}

const setDepartments = (state, data) => {
  state.forDepartmentsPage = data
}
const setRegions = (state, data) => {
  state.forRegionsPage = data
}

const deleteDepartment = (state, id) => {
  state.forDepartmentsPage.departments = state.forDepartmentsPage.departments.filter(item => item.id !== id)
  state.forDepartmentsPage.total -= 1
}

const deleteRegion = (state, id) => {
  state.forRegionsPage.regions = state.forRegionsPage.regions.filter(item => item.id !== id)
  state.forRegionsPage.total -= 1
}

const deleteTheme = (state, id) => {
  state.forClaimsCategories.claim_categories = state.forClaimsCategories.claim_categories.filter(item => item.id !== id)
  state.forClaimsCategories.total -= 1
}

const deleteTemplate = (state, id) => {
  state.forResponseTemplates.response_templates = state.forResponseTemplates.response_templates.filter(item => item.id !== id)
  state.forResponseTemplates.total -= 1
}

const deleteMessageTemplate = (state, id) => {
  state.forMessageTemplates.message_templates = state.forMessageTemplates.message_templates.filter(item => item.id !== id)
  state.forMessageTemplates.total -= 1
}

export {
  setLogs,
  setBusinessDates,
  setClaimsCategories,
  setResponseTemplates,
  setMessageTemplates,
  setBusinessDate,
  clearBusinessDate,
  setDepartments,
  deleteDepartment,
  setRegions,
  deleteRegion,
  deleteTheme,
  deleteTemplate,
  deleteMessageTemplate
}
