<template>
  <div class="media-wrapper uk-grid-small" uk-grid uk-lightbox="animation: slide; video-autoplay: true; finite: true">
    <div class="media" v-for="item in media" :key="item.id">

      <a :href="item.url" data-type="video" v-if="item.video">
        <img :src="imagePreview" :alt="item.title" >
      </a>

      <a :href="item.url" data-type="image" v-else>
        <img :src="item.url" :alt="item.title" >
      </a>

    </div>
  </div>
</template>

<script>
import image from '../../assets/images/video-preview.png'
export default {
  name: 'ImagesViewer',
  props: {
    media: Array
  },
  data() {
    return {
      imagePreview: image
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/variables';
  .media {
    width: 190px;
    height: 110px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
</style>
