<template>
  <div class="guber-checkbox" :class="{checked: value}">
    <input class="uk-checkbox checkbox" type="checkbox" :id="checkId" :checked="value" v-model="check">
    <label :for="checkId" class="checkbox-text">
      <slot></slot>
    </label>
  </div>
</template>

<script>
export default {
  name: 'GuberCheckbox',
  props: {
    value: Boolean,
    checkId: {
      type: String,
      default: () => 'check'
    }
  },
  computed: {
	  check: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
}
</script>

<style lang="scss" scoped>
  .guber-checkbox {
    display: flex;
    align-items: baseline;
    .checkbox {
      min-width: 16px;
      min-height: 16px;
      border: 1px solid #A1AAB8;
      box-sizing: border-box;
      border-radius: 3px;

      &:checked {
        background-image: url('../../assets/images/checkbox-checked.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-color: #152B4E;
        border-color: #152B4E !important;
      }

      &-text {
        font-size: 14px;
        line-height: 20px;
        margin-left: 13px;

        a {
          color: rgba(69, 132, 232, 1);
        }
      }
    } 
  }
</style>
