import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'MessageTemplateForm',
  mixins: [validationMixin],
  data() {
    return {
      form: {
        title: '',
        body: ''
      },
      load: false
    }
  },
  validations: {
    form: {
      title: {required},
      body: {required}
    }
  },
  computed: {
    isEdit() {
      return window.location.pathname.includes('edit')
    }
  },
  methods: {
    submitHandler() {
      if (this.$v.$invalid) {
        this.$v.$touch()
        this.$nextTick().then(() => {
          const element = document.querySelectorAll('.form-section.invalid')[0]
          const offset = element.getBoundingClientRect()
          if (element && offset.y < 0) element.scrollIntoView({behavior: "smooth"})
        })
      } else {
        if (this.isEdit) {
          this.onSubmitEdit()
        } else {
          this.onSubmitNew()
        }
      }
    },
    async onSubmitNew() {
      this.load = true
      try {
        const {data} = await this.$axios.post(`${this.$routes.admin_message_templates_path()}.json`, {message_template: {
          title: this.form.title,
          body: this.form.body
        }})
        if (data.success) {
          Turbolinks.visit(this.$routes.admin_message_templates_path())
        }
      } catch(e) {
        console.log(e);
      } finally {
        this.load = false
      }
    },
    async onSubmitEdit() {
      this.load = true
      try {
        const {data} = await this.$axios.put(`${this.$routes.admin_message_template_path(window.gon.message_template.id)}.json`, {message_template: {
          title: this.form.title,
          body: this.form.body
        }})
        if (data.success) {
          Turbolinks.visit(this.$routes.admin_message_templates_path())
        }
      } catch(e) {
        console.log(e);
      } finally {
        this.load = false
      }
    },
    requiredValidation(name) {
      return !this.$v.form[name].required
    },
    dirtyValidation(name) {
      return this.$v.form[name].$dirty
    }
  },
  beforeMount() {
    if (this.isEdit) {
      const template = window.gon.message_template
      this.form = {
        title: template.title,
        body: template.body || '',
      }
    }
  }
}
