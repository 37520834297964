import indexMixin from '../../mixins/IndexMixin'
import UIkit from 'uikit'

export default {
  name: 'ReceptionMessagesPage',
  mixins: [indexMixin],
  data() {
    return {
      filters: {
        perPage: 10,
        page: 1
      },
      deletedMessage: null,
      loadedBtnDelete: false
    }
  },
  computed: {
    messages() {
      return this.$store.state.schedule.dataForReceptionMessages?.messages
    },
    totalCount() {
      return this.$store.state.schedule.dataForReceptionMessages?.total
    }
  },
  methods: {
    async getDataForPage(filter) {
      this.changeLoaders(filter, true)
      try {
        await this.$store.dispatch('getReceptionMessages', {
          reception_id: window.gon.reception_id,
          params: {
            page: this.filters.page,
            per_page: this.filters.perPage
          }
        })
      } catch (e) {
        console.log(e);
      } finally {
        if (filter === 'page') this.$refs.scrollTarget.scrollIntoView({behavior: "smooth"})
        this.changeLoaders(filter)
      }
    },
    openModalDeleted(message) {
      if (this.deletedMessage) this.deletedMessage = null
      this.deletedMessage = message
      UIkit.modal('#modal-delete').show()
    },
    async deleteMessage(id) {
      this.loadedBtnDelete = true
      try {
        const { data } = await this.$axios.delete(`/receptions/${window.gon.reception_id}/messages/${id}.json`)

        if (data.success) {
          this.$store.commit('deleteMessage', id)
          UIkit.modal('#modal-delete').hide()
          this.deletedMessage = null

          if (this.messages.length === 0) {
            if (this.totalCount === 0) {
              window.location.href = this.$routes.schedule_path(window.gon.reception_id)
            } else {
              this.changeFilters('page', this.filters.page > 1 ? this.filters.page - 1 : 1)
            }
          } else {
            this.getDataForPage()
          }
        }
        
      } catch(e) {
        console.log(e);
      } finally {
        this.loadedBtnDelete = false
      }
    }
  },
  created() {
    this.setLocation()
    this.getDataForPage()
  }
}
