<template>
  <div class="tabs-container uk-flex uk-flex-middle">
    <div class="tab-wrapper" v-for="tab in tabs" :key="tab.id" :class="{selected: tab.id === selected}">
      <div class="tab-title" @click="$emit('change', tab)">{{tab.name}}</div>
      <div class="tab-border"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TabsFilter',
  props: {
    tabs: {
      type: Array,
      required: true
    },
    selected: {
      type: String
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../../assets/styles/variables';

  .tabs-container {
    @extend %fz-16;
    font-weight: 700;

    .tab-wrapper {
      &:not(:first-child) {
        margin-left: 40px;
      }
      .tab-title {
        color: $color-grey-description;
        cursor: pointer;
        user-select: none;
      }
      .tab-border {
        border-radius: 4px;
        background: transparent;
        margin-top: 5px;
        width: 100%;
        height: 3px;
      }
    }
    .selected {
      .tab-title {
        color: $color-text-black;
      }
      .tab-border {
        background: $color-primary;
      }
    }
  }
</style>
