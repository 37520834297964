<template>
  <span>
    <span :class="{disabled: disabledLink}">
      <slot name="link"></slot>
    </span>
    <span class="time-left" v-if="disabledLink">
      ({{countDown.minutes}}:{{countDown.seconds}})
    </span>
  </span>
</template>

<script>
import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat'
dayjs.extend(customParseFormat);

export default {
  name: 'LinkWithTimeout',
  props: {
    startTimeout: {
      type: String,
      required: true
    },
    defaultDate: {
      type: String,
      required: true
    },
    endTimeout: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      disabledLink: false,
      countDown: {
        days: '00',
        hours: '00',
        minutes: '00',
        seconds: '00'
      }
    }
  },
  methods: {
    timer(end) {
      const countDownDate = new Date(end).getTime();
      const vm = this
      const x = setInterval(() => {

        const now = new Date().getTime();

        const distance = countDownDate - now;

        this.countDown.days = this.pad(Math.floor(distance / (1000 * 60 * 60 * 24)))
        this.countDown.hours = this.pad(Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)))
        this.countDown.minutes = this.pad(Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)))
        this.countDown.seconds = this.pad(Math.floor((distance % (1000 * 60)) / 1000))
        
        if (distance <= 0) {
          this.disabledLink = false
          clearInterval(x);
        }
      }, 1000);
    },
    pad(num) {
      num = num.toString();
      if(num.length == 1) {
        return "0" + num;
      }
      return num;
    },
  },
  mounted() {
    if (!dayjs(this.startTimeout).isSame(dayjs(this.defaultDate)) && dayjs(new Date()).isBefore(dayjs(this.endTimeout))) {
      this.disabledLink = true
      this.timer(this.endTimeout)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/variables';
  .disabled {
    a {
      color: $color-grey-description !important;
      pointer-events: none;
    }
  }
  .time-left {
    @extend %fz-12;
  }
</style>
