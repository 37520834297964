<template>
  <div class="document-wrapper" >
    <div class="document uk-flex uk-flex-middle" v-for="document in documents" :key="document.id">
      
      <a class="document-view uk-flex uk-flex-middle" :href="document.url" target="_blank" :download="document.title">
        <div class="document-view-icon uk-flex uk-flex-middle uk-flex-center">
          <div uk-icon="guber-document"></div>
        </div>

        <div class="document-view-name">
          {{document.title | abbreviation}}
        </div>
      </a>

      <a class="document-download" :href="document.url" :download="document.title">
        <div uk-icon="icon: guber-download; ratio: 0.89"></div>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DocumentsViewer',
  props: {
    documents: Array,
    light: {
      type: Boolean,
      default: false
    }
  },
  filters: {
    abbreviation(str) {
      const type = '.' + str.split('.')[str.split('.').length - 1]
      const name = str.replace(type, '')
      if (name.length >= 49) {
        return name.slice(0, 40) + '...' + name.slice(name.length - 3, name.length) + type
      } 
      return str
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/variables';

  .document {
    &:not(:first-child) {
      margin-top: 12px;
    }
    &-view {
      &-icon {
        width: 28px;
        height: 28px;
        background: $color-background-input;
        border-radius: 4px;

        > div {
          color: $color-more-dark-blue;
          transition: all .1s ease;
        }
      }
      &-name {
        @extend %fz-14;
        margin: 3px 0 0 4px;
        font-weight: 500;
        color: $color-more-dark-blue-60;
        transition: all .1s ease;
      }

      &:hover {
        .document-view-icon {
          > div {
            color: $color-main-text-hover; 
          }
        }
        .document-view-name {
          color: $color-main-text-hover;
        }
      }
    }
    &-download {
      width: 28px;
      height: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;

      color: $color-more-dark-blue-60;
      margin-left: 8px;

      transition: all .1s ease;

      &:hover {
        box-shadow: 0px 4px 4px rgba(99, 112, 136, 0.12);
        color: $color-main-text-hover; 
      }
    }
  }

</style>
