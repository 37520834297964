import { validationMixin } from 'vuelidate'
import { required, requiredUnless, sameAs, minLength, helpers, email } from 'vuelidate/lib/validators'

export default {
  name: 'FormUserForAdminPage',
  mixins: [validationMixin],
  data() {
    return {
      form: {
        surname: '',
        name: '',
        patronymic: '',
        birthday: '',
        phone: '',
        username: '',
        mail: '',
        position: '',
        department: '',
        roles: [],
        password: '',
        confirmPassword: '',
        person_trusted: false
      },
      load: false,
      redirect: null
    }
  },
  validations: {
    form: {
      surname: {required},
      name: {required},
      phone: {
        required,
        minLength: minLength(18)
      },
      username: {
        validUsername(value) {
          if (value.length > 0) {
            return !!value.match('^[a-zA-Z0-9\{\}\!\#\$\%\&\'\*\+\-\/\=\?\^\_\~\|\.\`\@/]+$')
          }
          
          return true
        }
      },
      mail: {required, email},
      position: {required},
      roles: {required},
      password: {
        required: requiredUnless(function() {
          return this.isEdit
        }),
        lengthPassword: function(value) {
          if (value.length > 0) {
            return value.length > 5
          }
          return this.isEdit
        },
        validPassword: function(value) {
          if (value.length > 0) {
            const regex = '^[a-zA-Z0-9\{\}\!\#\$\%\&\'\*\+\-\/\=\?\^\_\~\|\.\`/]+$'
            return !!value.match(regex)
          }
          return this.isEdit
        }
      },
      confirmPassword: {
        required: requiredUnless(function() {
          return this.isEdit
        }),
        sameAsPassword: sameAs('password')
      }
    }
  },
  computed: {
    isEdit() {
      return window.location.pathname.includes('edit')
    },
    dataForWizard() {
      return [
        {title: 'Профиль подтвержден', condition: this.form.person_trusted},
        {title: 'Фамилия', condition: !this.$v.form.surname.$invalid},
        {title: 'Имя', condition: !this.$v.form.name.$invalid},
        {title: 'Телефон', condition: !this.$v.form.phone.$invalid},
        {title: 'Почта', condition: !this.$v.form.mail.$invalid},
        {title: 'Должность', condition: !this.$v.form.position.$invalid},
        {title: 'Роль', condition: !this.$v.form.roles.$invalid},
        {title: 'Пароль', condition: !this.$v.form.password.$invalid},
        {title: 'Подтверждение пароля', condition: !this.$v.form.confirmPassword.$invalid}
      ]
    }
  },
  methods: {
    submitHandler() {
      if (this.$v.$invalid) {
        this.$v.$touch()
        this.$nextTick().then(() => {
          const element = document.querySelectorAll('.form-section.invalid')[0]
          if (element) element.scrollIntoView({behavior: "smooth"})
        })
      } else {
        if (this.isEdit) {
          this.onSubmitEdit()
        } else {
          this.onSubmitNew()
        }
      }
    },
    async onSubmitNew() {
      this.load = true
      try {
        const {data} = await this.$axios.post(`${this.$routes.admin_employees_path()}.json`, {user: {
          person_trusted: this.form.person_trusted,
          surname: this.form.surname,
          name: this.form.name,
          patronymic: this.form.patronymic || null,
          birthday: this.form.birthday || null,
          phone: this.form.phone,
          username: this.form.username || null,
          email: this.form.mail,
          position: this.form.position || null,
          department: this.form.department?.id || null,
          password: this.form.password,
          password_confirmation: this.form.confirmPassword,
          role_ids: this.form.roles.map(item => item.id),
        }})
        if (data.success) {
          Turbolinks.visit(this.$routes.admin_employees_path())
        }
      } catch(e) {
        console.log(e);
      } finally {
        this.load = false
      }
    },
    async onSubmitEdit() {
      this.load = true
      try {
        const {data} = await this.$axios.put(`${this.$routes.admin_employee_path(window.gon.employee.id)}.json`, {user: {
          person_trusted: this.form.person_trusted,
          surname: this.form.surname,
          name: this.form.name,
          patronymic: this.form.patronymic || null,
          birthday: this.form.birthday || null,
          phone: this.form.phone,
          username: this.form.username || null,
          email: this.form.mail,
          position: this.form.position || null,
          department: this.form.department?.id || null,
          password: this.form.password,
          password_confirmation: this.form.confirmPassword,
          role_ids: this.form.roles.map(item => item.id),
        },
          redirect: this.redirect
        })
        if (data.success) {
          Turbolinks.visit(this.$routes[data.redirect]())
        }
      } catch(e) {
        console.log(e);
      } finally {
        this.load = false
      }
    },
    dirtyValidation(name) {
      return this.$v.form[name].$dirty
    },
    requiredValidation(name) {
      return !this.$v.form[name].required
    },
    deleteRole(id) {
      this.form.roles = this.form.roles.filter(role => role.id !== id)
    },
    showValidationErrors(name) {
      this.$v.form[name].$touch()
    },
    hideValidationErrors(name) {
      this.$v.form[name].$reset()
    }
  },
  beforeMount() {
    if (this.isEdit) {
      const employee = window.gon.employee
      this.form = {
        person_trusted: employee.person_trusted,
        surname: employee.surname,
        name: employee.name,
        patronymic: employee.patronymic || '',
        birthday: employee.birthday || '',
        phone: employee.phone || '',
        username: employee.username || '',
        mail: employee.email || '',
        position: employee.position || '',
        department: employee.department ? {id: employee.department} : '',
        roles: employee.roles.map(item => ({id: item.id, name: this.$utils.t(`role.${item.name}`)})),
        password: '',
        confirmPassword: ''
      }
    }
    if (window.location.search.includes('profile_path')) {
      this.redirect = 'profile_path'
    }
  }
}
