
const getCitizensData = state => {
  return state.forCitizensPage
}

const getCitizenData = state => {
  return state.forCitizenPage
}
const getEmployeesData = state => {
  return state.forEmployeesPage
}


export {
  getCitizensData,
  getCitizenData,
  getEmployeesData
}