import axios from 'axios'
import errorHandler from './error_handler'

const interceptor = axios.interceptors.response.use(function (response) {
  if (response.data.error) {
    console.log(error);
    errorHandler.handle(error)
    return Promise.reject(response);
  } else if (response.data.errors) {
    console.log(response.data.errors);
    errorHandler.handle({error: response.data.errors.join('. ')})
  }else {
    return response;
  }
}, function (error) {
  if (error.response) {
    errorHandler.handle(error.response.data)
    return Promise.reject(error.response);
  } else if (error.request) {
    console.error(error.request);
    errorHandler.handle(error)
  } else {
    console.error('Error', error.message);
  }
  return Promise.reject(error);
});

export default interceptor;
