<template>
  <a :href="$routes.claim_path(claim.id)" class="card-wrapper" :class="deadlineClaim">
    
    <div class="card-header uk-flex uk-flex-middle">
      <div class="card-header-state claim-state" :class="['claim-state-' + claim.state]">
        <span>{{claim.state | translateState}}</span>
      </div>

      <div 
        class="card-header-state claim-state claim-state-invalid"
        v-if="deadlineClaim === 'deadline'"
      >
        <span>Заявка просрочена</span>
      </div>
    </div>

    <div class="card-main">
      <h4 class="card-main-title">
        {{claim.title | trimString(150)}}
      </h4>

      <div class="card-main-category uk-flex">
        <span uk-icon="guber-message"></span>
        <p>
          {{claim.category.name}}
        </p>
      </div>

      <h5 class="card-main-subtitle">
        Описание заявки
      </h5>

      <p class="card-main-description">
        {{(claim.fake_question || claim.description) | trimString(300)}}
      </p>

      <p class="card-main-info">
        № {{claim.id}} <span>от {{claim.created_at | date('day')}}</span>
      </p>
    </div>

    <div class="card-footer" v-if="['invited', 'invite_confirm', 'on_reception'].indexOf(claim.state) !== -1 && this.$store.state.user.currentUser.id === claim.user.id">
      <div v-if="['invited', 'invite_confirm', 'on_reception'].indexOf(claim.state) !== -1">
        <div class="card-footer-hr"></div>

        <div class="card-footer-invitation"> 
          <p>
            {{ $utils.t('claim_layout.claim_statment_manage.title_invitation_reception') }} {{claim.reception.date_start | date('historyWithComma')}}
          </p>
        </div>

        <div class="card-footer-buttons uk-flex uk-flex-middle">
          <button class="btn primary button-submit" v-if="claim.state == 'invited'" :class="{'submit-loading': loadСonfirm}" @click.prevent="confirmParticipation">
            <loaders icon-view="button" v-if="loadСonfirm"/>
            <span v-else>Подтвердить участие</span>
          </button>

          <a class="btn primary" :href="'/schedule/' + claim.reception.id" v-if="claim.state == 'invite_confirm' || claim.state == 'on_reception'">Перейти к приёму</a>

          <button 
            class="btn outline-transparent-dark card-footer-buttons-cancel"
            :uk-toggle="'target: #modal-refuze-claim-' + claim.id"
            v-if="claim.state !== 'on_reception'"
            @click.prevent
          >
            Отказаться
          </button>
        </div>
      </div>
      <modal-cancel-claim modal-view='refuze' :claim="claim" for-claims-page/>
    </div>

  </a>
</template>

<script>
import Loaders from './Loaders.vue'
import ModalCancelClaim from './modals/ModalCancelClaim.vue'


export default {
  name: "ClaimCard",
  props: {
    claim: {
      type: Object,
      required: true
    }
  },
  components: {
    Loaders,
    ModalCancelClaim
  },
  data() {
    return {
      loadСonfirm: false
    }
  },
  computed: {
    deadlineClaim() {
      if (this.claim.deadline) {
        return new Date(this.claim.deadline) < new Date() ? "deadline" : ""
      } else if (this.claim.deadline_warning) {
        return "deadline"
      }
    }
  },
  methods: {
    async confirmParticipation() {
      this.loadСonfirm = true
      try {
        await this.$store.dispatch('claimConfirmParticipation', {id: this.claim.id, forClaimsPage: true})
      } catch(e) {
        console.log(e);
      } finally {
        this.loadСonfirm = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/variables';

.card-wrapper.deadline {
  .card-main-title {
    color: $color-primary;
  }
  &:hover {
    .card-main-title {
      color: $color-primary-hover;
    }
  }
}
.card-wrapper {
  display: block;
  width: 100%;
  padding: 24px;
  box-shadow: $card-box-shadow;
  background: $color-dark-blue-transparent;
  border-radius: 6px;
  color: $color-text-black;

  .card-main-title {
    color: $color-main-text;
  }

  &:hover {
    .card-main-title {
      color: $color-main-text-hover;
    }
  }

  &:not(:first-child) {
    margin-top: 20px;
  }

  .card-header {
    @extend %fz-12;

    &-state {
      color: $color-text-white;
      margin-right: 12px;
    }
  }

  .card-main {
    max-width: 871px;
    @extend %fz-14;
    &-title {
      transition: $transition-buttons;
      word-wrap: break-word;
      margin: 20px 0 0;
    }
    &-subtitle{
      @extend %fz-16;
      margin-top: 16px;
    }
    &-category {
      align-items: baseline;
      margin-top: 12px;
      @extend %fz-12;
      color: $color-grey-description;
      p {
        margin: 0 0 0 4px;
      }
    }
    &-description {
      word-wrap: break-word;
      margin: 12px 0 0;
    }
    &-info {
      margin: 20px 0 0;
      color: $color-grey-description;
    }
  }

  .card-footer {
    margin-top: 20px;
    &-hr {
      width: 100%;
      height: 1px;
      background: $color-more-dark-blue;
    }
    &-invitation {
      margin-top: 20px;

      p {
        margin: 0;
        @extend %fz-16;
        font-weight: 700;
      }
    }
    &-buttons {
       margin-top: 16px;

      .button-submit {
        div {
          width: 149.09px;
        }
      }
      button, a {
        margin-right: 12px;
      }
    }
  }
}
</style>
