<template>
  <div class="modal-messages-form" id="modal-messages-form" uk-modal>
    <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical modal-cancel-claim">
      <div class="uk-modal-close-default modal-close-default" type="button" uk-icon="guber-close"></div>
      <h3>
        <slot name="title"></slot>
      </h3>

      <div class="messages-group">

        <div class="members" v-if="members">
          <p class="members-subtitle">Получатели</p>
          <div class="members-wrapper uk-flex uk-flex-middle">
            <div class="member uk-flex uk-flex-bottom" v-for="member in members" :key="member.id">
              <input 
                class="uk-checkbox checkbox" 
                type="checkbox" 
                :id="'check' + member.id"
                :value="member.id"
                v-model="userIds"
              />
              <label :for="'check' + member.id" class="checkbox-text" :class="{'checkbox-text-empty': !userIds.includes(member.id)}">
              {{member.fullname}}
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="messages-body" v-if="dataMessages.users.length > 0">

        <p>Шаблон сообщения</p>
        <div class="messages-body-select">
          <guber-select 
            :options="messagesList"
            v-model="message"
            placeholder="Выберите шаблон"
            :json="false"
          />
        </div>
        <div class="messages-body-textarea">
          <guber-textarea
            name="message" 
            v-model="messageText" 
            placeholder="Текст сообщения... "
            height="141"
            :length="2000"
            counter=""
            :key="updateTextarea"
          />
        </div>

        <div class="buttons-box uk-flex uk-flex-middle">
          <button 
            class="btn primary button-submit-main" 
            :class="{'disabled-btn': userIds.length === 0 || messageText.trim() === '', 'button-submit-main-loading': loaded}"
            @click="sendMessage"
          >
            <div class="loader">
              <loaders icon-view="button" v-if="loaded" />
            </div>
            <span class="text">Отправить</span>
          </button>

          <button 
            class="btn outline-transparent-dark uk-modal-close" 
            type="button"
          >
            Отмена
          </button>
        </div>

      </div>

    </div>
  </div>
</template>

<script>
import UIkit from 'uikit'
import uniqWith from 'lodash/uniqWith'
import uniq from 'lodash/uniq'
import isEqual from 'lodash/isEqual'
import GuberSelect from '../../form/GuberSelect'
import GuberTextarea from '../../form/GuberTextarea'
import Loaders from '../Loaders'


export default {
  name: 'ModalMessagesForm',
  props: {
    dataMessages: {
      type: Object
    },
    messagesList: {
      type: Array,
      required: false
    }
  },
  components: {
    GuberSelect,
    GuberTextarea,
    Loaders
  },
  data() {
    return {
      userIds: [],
      message: '',
      messageText: '',
      loaded: false,
      updateTextarea: 0
    }
  },
  computed: {
    members() {
      return uniqWith(this.dataMessages.users, isEqual)
    },
    defaultId() {
      return [this.dataMessages.user?.id]
    }
  },
  methods: {
    async sendMessage() {
      this.loaded = true
      try {
        const {data} = await this.$axios.post(`${this.$routes.reception_messages_path(this.dataMessages.idForRequest)}.json`, {
          message: {
            user_ids: this.userIds,
            text: this.messageText.trim()
          }
        })
        if (data.success) {
          UIkit.modal('#modal-messages-form').hide()
          this.message = ''
          this.messageText = ''
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.loaded = false
      }
    }
  },
  watch: {
    defaultId(id) {
      if (this.dataMessages.user) this.userIds = id
    },
    message() {
      if (this.message) {
        this.messageText = this.message.fulltext
      } else {
        this.messageText = ''
      }
      this.updateTextarea += 1
    }
  },
  mounted() {
    UIkit.util.on('#modal-messages-form', 'hidden', (e) => {
      this.$emit('clear')
      this.message = ''
      this.messageText = ''
    })
  }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/styles/variables';

.modal-messages-form {
  .uk-modal-dialog {
    width: 794px !important;
    h3 {
      margin: 0;
    }
    h5 {
      margin: 0;
    }
  }
}
.messages-group {
  .members {
    margin-top: 24px;

    &-subtitle {
      @extend %fz-12;
      font-weight: 500;
    }
    .members-wrapper {
      flex-wrap: wrap;
      .member {
        padding: 12px 20px;
        background: $color-background-input;
        border-radius: 3px;

        margin: 8px 8px 0 0;

        .checkbox {
          min-width: 20px;
          min-height: 20px;
          margin-top: 1px;
          background: $color-body;
          border: 1px solid $color-more-dark-blue;
          box-sizing: border-box;
          border-radius: 4px;

          &:checked {
            background-image: url('../../../assets/images/checkbox-checked.png');
            background-repeat: no-repeat;
            background-position: center;
            background-color: $color-main-text;
            border-color: $color-main-text;
          }
          &-text {
            @extend %fz-14;
            margin-left: 8px;
            max-width: 520px;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.messages-body {
  margin-top: 24px;
  > p {
    @extend %fz-12;
    font-weight: 500;
  }
  &-select {
    margin-top: 8px;
  }
  &-textarea {
    margin-top: 12px;
  }
  .buttons-box {
    margin-top: 24px;
    button:not(:last-child) {
      margin-right: 16px;
    }
  }
}

</style>
