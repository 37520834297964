import remove from 'lodash/remove'

const setReceptionsForShedule = (state, data) => {
  state.forScheduleIndexPage = data
}
const setDataForReception = (state, data) => {
  state.dataForReception = data
}
const setDataForSheduler = (state, data) => {
  state.dataForSheduler = data
}
const setExtraMembers = (state, data) => {
  if (state.dataForReception) {
    state.dataForReception.extra_members.push(...data)

    const membersIds = data.map(item => item.id)
    state.dataForReception.allow_extra_members = state.dataForReception.allow_extra_members.filter(item => !membersIds.includes(item.id))
  }
}

const setDataForReceptionMessages = (state, data) => {
  state.dataForReceptionMessages = data
}

const setAdditionalMembersToClaim = (state, claim) => {
  state.dataForReception.claims.forEach(item => {
    if (item.id === claim.id) item.additional_members = claim.additional_members
  });
}

const deleteMessage = (state, id) => {
  state.dataForReceptionMessages.messages = state.dataForReceptionMessages.messages.filter(msg => msg.id !== id)
  state.dataForReceptionMessages.total -= 1
}

const deleteClaim = (state, id) => {
  state.dataForReception.claims = state.dataForReception.claims.filter(claim => claim.id !== id)
}

const deleteExtraMember = (state, member) => {
  state.dataForReception.extra_members = state.dataForReception.extra_members.filter(item => item.id !== member.id)
  state.dataForReception.allow_extra_members.push(member)
  state.dataForReception.allow_extra_members.sort(function(a, b){
    if(a.fullname < b.fullname) { return -1; }
    if(a.fullname > b.fullname) { return 1; }
    return 0;
  })

  state.dataForReception.claims.forEach(claim => {
    const index = claim.additional_members.findIndex(n => n.id === member.id)
    if (index !== -1) claim.additional_members.splice(index, 1)
  })
}

const setReceptionVideo = (state, reception) => {
  state.receptionVideo = reception
}

const deleteReceptionAttach = (state, {receptionId, attachmentId}) => {
  if (receptionId === state.receptionVideo.id) {
    state.receptionVideo.attachments = state.receptionVideo.attachments.filter(item => item.id !== attachmentId) 
  }
}


export {
  setReceptionsForShedule,
  setDataForReception,
  setDataForSheduler,
  setExtraMembers,
  setDataForReceptionMessages,
  setAdditionalMembersToClaim,
  deleteMessage,
  deleteClaim,
  deleteExtraMember,
  setReceptionVideo,
  deleteReceptionAttach
}
