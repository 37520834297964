<template>
  <div class="conference-manage">
    <h3>
      Авторы вопросов
    </h3>
    <guber-checkbox-multiple
      v-model="selectedCitizen"
      :options="JSON.parse(citizens)"
      single-value
      :key="updateCheckboxes"
      @set-select-all-citizen="setSelectedAllCitizens"
    ></guber-checkbox-multiple>
  </div>
</template>

<script>
import GuberCheckboxMultiple from '../form/GuberCheckboxMultiple.vue'

export default {
  name: 'ConferenceManage',
  components: {
    GuberCheckboxMultiple
  },
  props: {
    officialId: {
      type: String,
      required: true
    },
    citizens: {
      type: String,
      default: ''
    },
    receptionId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      selectedCitizen: [],
      updateCheckboxes: 0,
      interval: null,
      selectedAllCitizens: false
    }
  },
  methods: {
    setClaim(citizenId) {
      this.$cable.perform({
        channel: "ConferenceManageChannel",
        action: "set_claim",
        data: {
          user_id: this.officialId,
          citizen_id: citizenId
        }
      })
    },
    participantsToPodium(citizenAdd, citizenDelete) {
      this.$cable.perform({
        channel: "ConferenceManageChannel",
        action: "participants_to_podium",
        data: {
          user_id: this.officialId,
          citizen_add_id: citizenAdd === 'all' ? null : citizenAdd,
          citizen_delete_id: citizenDelete === 'all' ? null : citizenDelete,
          select_all: citizenAdd === 'all',
          deselect_all: citizenDelete === 'all',
        },
      })
    },
    setSelectedAllCitizens(value){
      this.selectedAllCitizens = value;
    }
  },
  watch: {
    selectedCitizen(newVal, oldVal) {
      this.setClaim(newVal[0])
      this.participantsToPodium(newVal[0], oldVal[0])
    }
  }
}
</script>

<style lang="scss">
  .conference-manage {
    h3 {
      margin: 0;
    }
  }
</style>
