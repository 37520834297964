const autoSizeHeight = (el, value) => {
  el.style.height = 'auto';
  el.style.height = `${Math.max(el.scrollHeight, value)}px`;
}
const handler = (e, value) => {
  autoSizeHeight(e.target, value)
}

export default {
  bind(el, data) {
    ['input', 'change'].forEach(event => {
      el.addEventListener(event, e => {
        handler(e, data.value)
      })
    })
  },

  inserted(el, data) {
    autoSizeHeight(el, data.value)
  },

  unbind(el, data) {
    ['input', 'change'].forEach(event => {
      el.removeEventListener(event, e => {
        handler(e, data.value)
      })
    })
  }
}
