import UIkit from 'uikit'
import Bus from "../services/bus"
let self = null

class ErrorHandler {
  constructor() {
    this._type = 'ErrorHandler';
    self = this

    Bus.$on('api:error', function(error) {
      if (self.cb) {
        self.cb(error)
      } else {
        self.defaultHandler(error)
      }
    })
  }

  setHandler(cb) {
    this.cb = cb
  }

  handle(error) {
    console.error(error)
    Bus.$emit('api:error', error)
  }

  static staticMethod() {
    return 'staticMethod';
  }

  i18nError(errorName) {
    if (errorName == "Network Error") {
      return 'Произошла ошибка: соединение прервано';
    } else {
      return errorName;
    }
  }

  defaultHandler(error) {
    const errorText = error.error || error.message
    UIkit.notification.closeAll()
    UIkit.notification(this.i18nError(errorText), {
      pos: 'top-right',
      status:'danger'
    })
  }
}


export default new ErrorHandler();