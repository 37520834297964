import { render, staticRenderFns } from "./ClaimCard.vue?vue&type=template&id=30f1b76b&scoped=true&"
import script from "./ClaimCard.vue?vue&type=script&lang=js&"
export * from "./ClaimCard.vue?vue&type=script&lang=js&"
import style0 from "./ClaimCard.vue?vue&type=style&index=0&id=30f1b76b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30f1b76b",
  null
  
)

export default component.exports