import { mapActions, mapGetters } from 'vuex'
import indexMixin from '../../mixins/IndexMixin'


export default {
  name: 'ScheduleIndexPage',
  mixins: [indexMixin],
  data() {
    return {
      filters: {
        tab: 'inFuture',
        page: 1
      },
      filterTabsData: [
        {name: 'Ближайшие', id: 'inFuture'},
        {name: 'Прошедшие', id: 'past'},
      ],
      loaded: false,
      textForReceptionsNull: {
        inFuture: {
          title: 'Пока нет приёмов',
          message: 'Здесь будут отображаться ближайшие онлайн-приёмы'
        },
        past: {
          title: 'Пока нет приёмов',
          message: 'Здесь будут отображаться прошедшие онлайн-приёмы'
        }
      }
    }
  },
  computed: {
    ...mapGetters(['getRecepitons']),
    receptions() {
      return this.getRecepitons?.receptions
    },
    totalReceptions() {
      return Number.parseInt(this.getRecepitons?.total)
    }
  },
  methods: {
    ...mapActions(['getReceptionsForSchedule']),
    async getDataForPage(filter) {
      this.changeLoaders(filter, true)
      try {
        await this.getReceptionsForSchedule({
          filter: this.filters.tab,
          page: this.filters.page
        })
      } catch(e) {
        console.log(e);
      } finally {
        if (filter === 'page') this.$refs.scrollTarget.scrollIntoView({behavior: "smooth"})
        this.changeLoaders(filter)
      }
    },
  },
  mounted() {
    this.setLocation()
    this.getDataForPage()
  }
}
