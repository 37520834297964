import IndexMixin from '../../mixins/IndexMixin'
import {mapActions, mapGetters} from 'vuex'
import UIkit from 'uikit'

export default {
  name: 'DepartmentsPage',
  mixins: [IndexMixin],
  data() {
    return {
      filters: {
        search: '',
        page: 1,
        perPage: 10
      },
      deletedDepartment: null,
      editDepartment: null,
      loadedBtnForm: false
    }
  },
  computed: {
    ...mapGetters(['getDepartmentsPage'])
  },
  methods: {
    ...mapActions(['requestToDepartments']),
    async getDataForPage(filter) {
      this.changeLoaders(filter, true)
      try {
        await this.requestToDepartments({
          params: {
            query: this.filters.search,
            page: this.filters.page,
            per_page: this.filters.perPage,
          }
        })
      } catch(e) {
        console.log(e);
      } finally {
        if (filter === 'page') this.$refs.scrollTarget.scrollIntoView({behavior: "smooth"})
        this.changeLoaders(filter)
      }
    },
    openModal(department, objectName, modal) {
      if (this[objectName]) this[objectName] = null
      this[objectName] = department
      UIkit.modal(modal).show()
    },
    async deleteDepartment(id) {
      this.loadedBtnForm = true
      try {
        const { data } = await this.$axios.delete(`${this.$routes.admin_department_path(id)}.json`)

        if (data.success) {
          this.$store.commit('deleteDepartment', id)
          UIkit.modal('#modal-delete').hide()
          this.deletedDepartment = null

          if (this.getDepartmentsPage?.departments.length === 0) {
            this.changeFilters('page', this.filters.page > 1 ? this.filters.page - 1 : 1)
          } else {
            this.getDataForPage()
          }
        }
        
      } catch(e) {
        console.log(e);
      } finally {
        this.loadedBtnForm = false
      }
    },
    async submitDepartment(department, edit = false) {
      this.loadedBtnForm = true
      try {
        const data = await this.$store.dispatch('submitDepartment', {department, edit})
        if (data.success) {
          if (edit) {
            UIkit.modal('#modal-form-edit-for-admin').hide()
          } else {
            UIkit.modal('#modal-form-create-for-admin').hide()
          }
          this.getDataForPage()
        }
      } catch(e) {
        console.log(e);
      } finally {
        this.loadedBtnForm = false
      }
    }
  },
  created() {
    this.setLocation()
    this.getDataForPage()
  }
}
