<template>
  <ul class="pagination">
    
    <li
      :class="{ 'pagination-prev-disabled': onFirstPage }"
      class="pagination-prev"
    >
      
      <a
        @click.prevent="prevPage"
        class="page-link"
      >
        <span uk-icon="guber-pagination-right"></span>
      </a>
      
    </li>
    
    <li
      v-for="(paginator, index) in paginators"
      :key="index"
      :class="{ active: paginator.value === currentPage, disabled: !paginator.enable}"
      class="page-item"
      >
      
      <a
        @click.prevent="setPage(paginator.value)"
        class="page-link"
        :disabled="!paginator.enable"
      >
        <span>{{ paginator.value }}</span>
      </a>
      
    </li>
  
    <li
      :class="{ 'pagination-next-disabled': onLastPage }"
      class="pagination-next"
    >
      
      <a
        @click.prevent="nextPage"
        class="page-link"
      >
        <span uk-icon="guber-pagination-right"></span>
      </a>
      
    </li>
    
  </ul>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      default: 1,
      validator: val => val > 0
    },
    total: {
      type: Number,
      required: true,
      validator: val => val > 0,
    },
    eachSide: {
      type: Number,
      default: 1,
      validator: val => val >= 0,
    },
  },
  computed: {
    firstPage () {
      return 1
    },
    lastPage () {
      return this.total
    },
    onFirstPage () {
      return this.currentPage === this.firstPage
    },
    onLastPage () {
      return this.currentPage === this.lastPage
    },
    currentPage () {
      return this.value
    },
    paginators () {
      let paginators = []
      if (this.lastPage < this.eachSide * 2 + 4) {
        for (let i = this.firstPage; i < this.lastPage + 1; ++i) {
          paginators.push({
            value: i,
            enable: true,
          })
        }
      } else {
        if (this.currentPage - this.firstPage < this.eachSide + 2) {
          for (let i = this.firstPage; i < Math.max(this.eachSide * 2 + 1, this.currentPage + this.eachSide + 1); ++i) {
            paginators.push({
              value: i,
              enable: true,
            })
          }
          paginators.push({
            value: '...',
            enable: false,
          })
          paginators.push({
            value: this.lastPage,
            enable: true,
          })
        } else if (this.lastPage - this.currentPage < this.eachSide + 2) { 
          paginators.push({
            value: this.firstPage,
            enable: true,
          })
          paginators.push({
            value: '...',
            enable: false,
          })
          for (let i = Math.min(this.lastPage - this.eachSide * 2 + 1, this.currentPage - this.eachSide); i < this.lastPage + 1; ++i) {
            paginators.push({
              value: i,
              enable: true,
            })
          }
        } else {
          paginators.push({
            value: this.firstPage,
            enable: true,
          })
          paginators.push({
            value: '...',
            enable: false,
          })
          for (let i = this.currentPage - this.eachSide; i < this.currentPage + this.eachSide + 1; ++i) {
            paginators.push({
              value: i,
              enable: true,
            })
          }
          paginators.push({
            value: '...',
            enable: false,
          })
          paginators.push({
            value: this.lastPage,
            enable: true,
          })
        }
      }
      return paginators
    },
  },
  methods: {
    nextPage () {
      this.setPage(this.currentPage + 1)
    },
    prevPage () {
      this.setPage(this.currentPage - 1)
    },
    setPage (targetPage) {
      if (targetPage <= this.lastPage && targetPage >= this.firstPage && targetPage !== this.value) {
        this.$emit('input', targetPage)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.pagination {
  display: flex;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;

  .page-item {
    font-family: 'GothamPro';
    font-size: 14px;
    line-height: 20px;
    margin-right: 4px;
    font-weight: 500;
    a {
      width: 28px;
      height: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      color: #152B4E;
      transition: all .2s ease;

      span {
        display: inline-block;
      }

      &:hover {
        transition: all .2s ease;
        color: #244780;
        box-shadow: 0px 4px 4px rgba(99, 112, 136, 0.12);
      }
    }
  }
  &-prev {
    margin-right: 4px;
    a {
      span {
        transform: rotate(180deg);
      }
    }
  }
  &-next, &-prev {
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    a {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #152B4E;
      border-radius: 4px;
      transition: all .2s ease;

      &:hover {
        transition: all .2s ease;
        color: #244780;
        box-shadow: 0px 4px 4px rgba(99, 112, 136, 0.12);
      }
    }
    &-disabled {
      display: none;
    }
  }
  .active {
      a {
        cursor: default;
        background: #EFF0F2;
        &:hover {
          color: #152B4E;
          box-shadow: none;
        }
      }
    }
  .disabled {
    pointer-events: none;
    user-select: none;
  }
}

</style>
