import { validationMixin } from 'vuelidate'
import { required, sameAs, minLength, helpers, email } from 'vuelidate/lib/validators'

export default {
  name: 'SignUpEmployeePage',
  mixins: [validationMixin],
  data() {
    return {
      form: {
        surname: '',
        name: '',
        patronymic: '',
        birthday: '',
        phone: '',
        username: '',
        mail: '',
        position: '',
        department: '',
        password: '',
        confirmPassword: ''
      },
      load: false
    }
  },
  validations: {
    form: {
      surname: {required},
      name: {required},
      phone: {
        required,
        minLength: minLength(18)
      },
      username: {
        validUsername(value) {
          if (value.length > 0) {
            return !!value.match('^[a-zA-Z0-9\{\}\!\#\$\%\&\'\*\+\-\/\=\?\^\_\~\|\.\`\@/]+$')
          }
          
          return true
        }
      },
      mail: {required, email},
      position: {required},
      password: {
        required,
        lengthPassword: function(value) {
          if (value.length > 0) {
            return value.length > 5
          } 
          return false
        },
        validPassword: function(value) {
          if (value.length > 0) {
            const regex = '^[a-zA-Z0-9\{\}\!\#\$\%\&\'\*\+\-\/\=\?\^\_\~\|\.\`/]+$'
            return !!value.match(regex)
          }
          return false
        }
      },
      confirmPassword: {
        required,
        sameAsPassword: sameAs('password')
      }
    }
  },
  computed: {
    dataForWizard() {
      return [
        {title: 'Фамилия', condition: !this.$v.form.surname.$invalid},
        {title: 'Имя', condition: !this.$v.form.name.$invalid},
        {title: 'Телефон', condition: !this.$v.form.phone.$invalid},
        {title: 'Почта', condition: !this.$v.form.mail.$invalid},
        {title: 'Должность', condition: !this.$v.form.position.$invalid},
        {title: 'Пароль', condition: !this.$v.form.password.$invalid},
        {title: 'Подтверждение пароля', condition: !this.$v.form.confirmPassword.$invalid}
      ]
    }
  },
  methods: {
    showValidationErrors(name) {
      this.$v.form[name].$touch()
    },
    hideValidationErrors(name) {
      this.$v.form[name].$reset()
    },
    submitHandler() {
      if (this.$v.$invalid) {
        this.$v.$touch()
        this.$nextTick().then(() => {
          const element = document.querySelectorAll('.form-section.invalid')[0]
          if (element) element.scrollIntoView({behavior: "smooth"})
        })
      } else {
        this.onSubmitNew()
      }
    },
    async onSubmitNew() {
      this.load = true
      try {
        const {data} = await this.$axios.post(`${this.$routes.employees_path()}.json`, {user: {
          surname: this.form.surname,
          name: this.form.name,
          patronymic: this.form.patronymic || null,
          birthday: this.form.birthday || null,
          phone: this.form.phone,
          username: this.form.username || null,
          email: this.form.mail,
          position: this.form.position || null,
          department: this.form.department?.id || null,
          password: this.form.password,
          password_confirmation: this.form.confirmPassword,
        }})
        if (data.success) {
          Turbolinks.visit(this.$routes.root_path())
        }
      } catch(e) {
        console.log(e);
      } finally {
        this.load = false
      }
    },
    dirtyValidation(name) {
      return this.$v.form[name].$dirty
    },
    requiredValidation(name) {
      return !this.$v.form[name].required
    }
  }
}
