import IndexMixin from '../../mixins/IndexMixin'
import {mapActions, mapGetters} from 'vuex'
import UIkit from 'uikit'

export default {
  name: 'ThemesClaims',
  mixins: [IndexMixin],
  data() {
    return {
      filters: {
        search: '',
        page: 1,
        perPage: 10
      },
      deletedTheme: null,
      loadedBtnDelete: false
    }
  },
  computed: {
    claimCategories() {
      return this.$store.state.admin.forClaimsCategories?.claim_categories
    },
    totalCount() {
      return this.$store.state.admin.forClaimsCategories?.total
    }
  },
  methods: {
    ...mapActions(['requestToClaimsCategories']),
    async getDataForPage(filter) {
      this.changeLoaders(filter, true)
      try {
        await this.requestToClaimsCategories({
          params: {
            query: this.filters.search,
            page: this.filters.page,
            per_page: this.filters.perPage,
          }
        })
      } catch(e) {
        console.log(e);
      } finally {
        if (filter === 'page') this.$refs.scrollTarget.scrollIntoView({behavior: "smooth"})
        this.changeLoaders(filter)
      }
    },

    openModalDeleted(theme) {
      if (this.deletedTheme) this.deletedTheme = null
      this.deletedTheme = theme
      UIkit.modal('#modal-delete').show()
    },

    async deleteTheme(id) {
      this.loadedBtnDelete = true
      try {
        const {data} = await this.$axios.delete(`${this.$routes.admin_claim_category_path(id)}.json`)
        if (data.success) {
          this.$store.commit('deleteTheme', id)
          UIkit.modal('#modal-delete').hide()
          this.deletedTheme = null

          if (this.claimCategories.length === 0) {
            this.changeFilters('page', this.filters.page > 1 ? this.filters.page - 1 : 1)
          } else {
            this.getDataForPage()
          }
        }
      } catch(e) {
        console.log(e);
      } finally {
        this.loadedBtnDelete = false
      }
    }
  },
  created() {
    this.setLocation()
    this.getDataForPage()
  }
}
