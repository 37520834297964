<template>
  <div class="modal-business-date" id="modal-business-date" uk-modal>
    <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical modal-cancel-claim">
      <div class="uk-modal-close-default modal-close-default" type="button" uk-icon="guber-close"></div>
      
      <h3>
        Добавить расписание
      </h3>

      <form @submit.prevent="submitHandler">

        <div class="form-section" :class="{invalid: (dirtyValidation('date') && requiredValidation('date'))}">
          <div class="form-section-title">
            Дата
          </div>
          <div class="form-section-item">
            <custom-datapicker
              v-model="form.date"
              datepicker-id="date-employee" 
              placeholder="Выберите дату"
              for-form=""
              :datepicker-close="updateDatapicker"
            />
            <small 
              class="form-small-invalid-block"
              v-if="dirtyValidation('date') && requiredValidation('date')"
            >
              Не заполнено обязательное поле
            </small>
          </div>
        </div>

        <div class="form-section" :class="{invalid: (dirtyValidation('type') && requiredValidation('type'))}">
          <div class="form-section-title">
            Тип
          </div>
          <div class="form-section-item">
            <guber-select 
              :options="typeList"
              v-model="form.type"
              placeholder="Выберите тип"
              :json="false"
            />
            <small 
              class="form-small-invalid-block"
              v-if="dirtyValidation('type') && requiredValidation('type')"
            >
              Не заполнено обязательное поле
            </small>
          </div>
        </div>

        <div class="modal-buttons-box uk-flex uk-flex-middle">
          <button class="btn primary button-submit-main" :class="{'button-submit-main-loading': loading}" type="submit">
            <div class="loader">
              <loaders icon-view="button" />
            </div>
            <span class="text">Добавить</span>      
          </button>
          <button class="btn outline-transparent-dark uk-modal-close">Отмена</button>
        </div>

      </form>

    </div>
  </div>
</template>

<script>
import UIkit from 'uikit'
import CustomDatapicker from '../CustomDatapicker.vue'
import GuberSelect from '../../form/GuberSelect.vue'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'ModalBusinessDate',
  mixins: [validationMixin],
  components: {
    CustomDatapicker,
    GuberSelect
  },
  validations: {
    form: {
      date: {required},
      type: {required}
    }
  },
  props: {
    loading: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      form: {
        date: '',
        type: ''
      },
      typeList: [
        {name: 'Праздничные дни', id: 'holidays'},
        {name: 'Рабочие дни', id: 'workdays'}
      ],
      updateDatapicker: 0
    }
  },
  methods: {
    submitHandler() {
      if (this.$v.$invalid) {
        this.$v.$touch()
      } else {
        this.$emit('set-date', {
          date: this.form.date.toString(), 
          holiday: this.form.type.id === 'holidays' ? true : false
        })
      }
    },
    dirtyValidation(name) {
      return this.$v.form[name].$dirty
    },
    requiredValidation(name) {
      return !this.$v.form[name].required
    }
  },
  mounted() {
    UIkit.util.on('#modal-business-date', 'beforehide', () => {
      this.updateDatapicker += 1
      this.form.date = ''
      this.form.type = ''
      this.$v.$reset()
    })
  }
}
</script>

<style lang="scss" scoped>
.modal-business-date {
  .uk-modal-dialog {
    width: 794px !important;
    padding: 40px !important;
  }
  h3 {
    margin: 0;
  }
  form {
    margin-top: 24px;

    .form-section {
      &:not(:first-child) {
        margin-top: 12px;
      }
    }
  }
  .modal-buttons-box {
    margin-top: 24px;
    button:first-child {
      margin-right: 16px;
    }
  }
}
</style>
