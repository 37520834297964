import Vue from 'vue/dist/vue.esm'
import ActionCableVue from "actioncable-vue";
import store from '../store'
import UIkit from 'uikit'

Vue.use(ActionCableVue, {
  debug: true,
  debugLevel: "error",
  connectImmediately: true,
});


import 'normalize.css';

import "boot/assets";
import "boot/highcharts";
import "boot/turbolinksUpdater";


window.ErrorHandler = require('../services/error_handler').default
window.AnswerHandler = require('../services/answer_handler').default
import interceptor from '../services/axios_interceptor'

require('../helpers/vueRegister')

import VueTheMask from 'vue-the-mask'
Vue.use(VueTheMask);

let scrollPosition
document.addEventListener('turbolinks:load', () => {
  const app = new Vue({
    el: "#app",
    store,
    turbolinksDestroyEvent: 'turbolinks:request-end',
    mounted() {
      // Отображение уведомлений
      const notify = this.$store.state.notificaton.text
      if (notify) {
        UIkit.notification.closeAll()
        UIkit.notification(notify, {
          pos: 'top-right',
          status:'success'
        })
        this.$store.dispatch('createNewNotification', null);
      }

      if (!this.$store.state.user.currentUser) {
        this.$store.dispatch('getCurrentUser')
      }
    }
  })
  if (scrollPosition) {
    window.scrollTo.apply(window, scrollPosition)
    scrollPosition = null
  }
})

Turbolinks.reload = function () {
  scrollPosition = [window.scrollX, window.scrollY]
  Turbolinks.visit(window.location, { action: 'replace' })
}
