<template>
  <div class="pagination-count-page" uk-inline >

    <div class="wrapper uk-flex uk-flex-middle">
      <p class="text-info">{{ $utils.t('claims.index.show_by') }}</p>
      <div class="text-value">
        <span>{{ value }}</span>
      </div>
      <span class="text-icon" uk-icon="arrow-menu-button"></span>
    </div>

    <div id="dropdown" class="nav-menu-dropdown" uk-dropdown="mode: click; boundary: .wrapper">
      <div 
        class="dropdown-item"
        v-for="count in countsArray"
        :key="count"
        :class="{'dropdown-item-active': count == value}"
        @click="selectCount(count)"
      >
        {{ count }}
      </div>
    </div>
  </div>
</template>

<script>
import UIkit from 'uikit'

export default {
  name: 'CountItemsInPage',
  props: {
    value: {
      type: Number,
      required: true
    },
    countsArray: {
      type: Array,
      required: true
    }
  },
  methods: {
    selectCount(count) {
      if (count == this.value) return
      UIkit.dropdown('#dropdown').hide(false)
      this.$emit('input', count)
    }
  }
}
</script>

<style lang="scss" scoped>
  .pagination-count-page {
    font-family: 'GothamPro';
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;

    .wrapper {
      color: #152B4E;
      padding: 7px 16px;
      box-sizing: border-box;
      border: 1px solid #BBC7DE;
      border-radius: 4px;
      cursor: pointer;

      transition: all .2s ease;

      &:hover {
        transition: all .2s ease;

        border-color: #244780;
        color: #244780;
      }

      .text-info {
        margin: 0;
      }

      .text-value {
        margin: 0 8px;
        width: 20px;
        span {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .text-icon {
        transition: all .2s ease;
      }
    }

    .wrapper[aria-expanded="true"] {
      border-color: #244780;
      color: #244780;
      .text-icon {
        transform: rotate(180deg);
      }
    }

    [class*='uk-dropdown-bottom'] {
      margin: 4px 0 0 121px !important;
    }
    [class*='uk-dropdown-top'] {
      margin: -4px 0 0 121px !important;
    }
    .nav-menu-dropdown {
      padding: 12px;
      min-width: 0px !important;
      box-shadow: 0px 4px 4px rgba(99, 112, 136, 0.12) !important;
      border-radius: 4px !important;
      border: none !important;

      .dropdown-item {
        cursor: pointer;
        color: #25253D;
        text-align: center;
        transition: all .2s ease;

        &:not(:first-child) {
          margin-top: 10px;
        }

        &:hover {
          transition: all .2s ease;
          color: #244780;
        }

        &-active {
          color: #E94F4F;
          cursor: default;

          &:hover {
            transition: all .2s ease;
            color: #E94F4F;
          }
        }
      }
    }
  }
</style>
